import React, { useState, useEffect } from "react";
import "./style.css";
import { ArrowDropDown } from "@material-ui/icons/";
import CircularProgress from "@material-ui/core/CircularProgress";
export default function MultiSelect2({
  id,
  options,
  value,
  selecteds,
  cantidad,
  loading,
  onOpen,
  onSet,
  setCantidad,
  condicionOnline,
  fromUser,
  fromReport,
  seleccionPrevia,
}) {
  const [opciones, setOpciones] = useState(options);
  const [seleccionados, setSeleccionados] = useState([]);
  const [searchIcon, setSearchIcon] = useState(true);
  const [openOptions, setOpenOptions] = useState(false);
  const [previa, setPrevia] = useState(seleccionPrevia != undefined);

  useEffect(() => {
    setSeleccionados([]);
    if (selecteds.length > 0) {
      setSeleccionados(selecteds.split(","));
    } else {
      if (cantidad > 0) {
        options.forEach((option) => {
          selectOption(true, 0);
        });
      }
    }
    setOpciones(options);
  }, [loading, options]);

  function setValues() {
    let stringValues = "";
    let stringCompleto = false;
    if (previa) {
      if (seleccionPrevia > 0) {
        stringCompleto = true;
      }
    }
    if (seleccionados.includes("0") && !stringCompleto) {
      stringValues = "";
      if (seleccionados.length  === opciones.length) {
        for (let i = 0; i < seleccionados.length; i++) {
          if (seleccionados[i] != "0" && seleccionados[i] != ["53"]) {
            stringValues = stringValues + seleccionados[i];
            if (i != seleccionados.length - 1) {
              stringValues = stringValues + ",";
            }
          }
        }
      }
    } else {
      for (let i = 0; i < seleccionados.length; i++) {
        if (seleccionados[i] != "0") {
          stringValues = stringValues + seleccionados[i];
          if (i != seleccionados.length - 1) {
            stringValues = stringValues + ",";
          }
        }
      }
    }
    onSet(stringValues);
    setCantidad(
      seleccionados.length === options.length
        ? options.length - 1
        : seleccionados.length
    );
  }

  function selectOption(e, value) {
    if (value == 0) {
      let mySeleccionados = [];
      if (e) {
        opciones.forEach((option) => {
          if (option.label == "ONLINE" && condicionOnline) {
          } else {
            mySeleccionados.push(option.value);
          }
        });
      }
      setSeleccionados(mySeleccionados);
    } else {
      let mySeleccionados = [];
      if (e) {
        seleccionados.forEach((selected) => {
          mySeleccionados.push(selected);
        });
        mySeleccionados.push(value);
        if (mySeleccionados.length + 1 === opciones.length) {
          mySeleccionados.unshift("0");
        }
      } else {
        seleccionados.forEach((selected) => {
          if (selected != value && selected != "0") {
            mySeleccionados.push(selected);
          }
        });
        if (mySeleccionados.length + 1 === opciones.length) {
          mySeleccionados.unshift("0");
        }
      }
      setSeleccionados(mySeleccionados);
    }
  }

  function searchOption(e) {
    
    let text = e.target.value;
    let myOptions = [];
    options.forEach((option) => {
      if (
        option.label !== "Todos" &&
        option.label
          .toString()
          .toLowerCase()
          .includes(text.toString().toLowerCase())
      ) {
        myOptions.push(option);
      } else if (option.label === "Todos" && text === "") {
        
        myOptions.push(option);
      }
    });
    setOpciones(myOptions);
  }

  return (
    <div class="css-b8ldur-Input">
      <div class="contenedor-multiselect2">
        <input
          value={
            "Total:" +
            String(
              seleccionados.includes("0")
                ? seleccionados.length - 1
                : seleccionados.length
            )
          }
          onFocus={() => {
            setOpenOptions(true);
            if (onOpen) {
              onOpen();
            }
          }}
          type="text"
          name=""
          id={id}
          className="principal-input-multiselect2"
          placeholder="Seleccione..."
        />
        <div
          className="arrow-down-select2"
          onClick={() => {
            setOpenOptions(true);
            if (onOpen) {
              onOpen();
            }
          }}
        >
          <div class="css-1hb7zxy-IndicatorsContainer">
            <span class="select-modal__indicator-separator css-1okebmr-indicatorSeparator"></span>
            <div
              aria-hidden="true"
              class="select-modal__indicator select-modal__dropdown-indicator css-tlfecz-indicatorContainer"
            >
              <svg
                height="20"
                width="20"
                // viewBox="0 0 20 20"
                // aria-hidden="true"
                focusable="true"
                class="css-6q0nyr-Svg"
              >
                <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
              </svg>
            </div>
          </div>
        </div>
        <div
          style={!openOptions ? { display: "none" } : {}}
          className={
            fromUser
              ? "contenedor-aceptar-opciones2"
              : fromReport
              ? "contenedor-aceptar-opciones3"
              : "contenedor-aceptar-opciones"
          }
        >
          {loading ? (
            <div style={{ textAlign: "center", paddingTop: 30 }}>
              <CircularProgress size={50} />
            </div>
          ) : (
            <>
              <input
                type="text"
                placeholder="Busca..."
                onChange={searchOption}
              />
              <div className="div-contenedor-opciones">
                {opciones.map((option) => (
                  <p
                    onClick={(e) => {
                      if (seleccionados.includes(option.value)) {
                        selectOption(false, option.value);
                      } else {
                        selectOption(true, option.value);
                      }
                    }}
                  >
                    {option.label}
                    <input
                      disabled={
                        condicionOnline &&
                        ((option.label == "ONLINE" &&
                          !seleccionados.includes(option.value) &&
                          seleccionados.length > 0) ||
                          (option.label != "ONLINE" &&
                            seleccionados.includes("53")))
                      }
                      checked={seleccionados.includes(option.value)}
                      onChange={(e) => {
                        selectOption(e.target.checked, option.value);
                      }}
                      type="checkbox"
                    />
                  </p>
                ))}
              </div>
              <button
                className="button-aceptar-opciones"
                onClick={() => {
                  setValues();
                  setOpenOptions(false);
                }}
              >
                Aceptar
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
