import React, { useState, useEffect, useContext } from 'react'
import Contenedor from '../../Utils/Visuales/Contenedor'
import SearchInput from '../../Utils/Visuales/SearchInput'
import MasivaButton from '../../Utils/Botones/MasivaButton'
import DataTable from '../../Utils/DataTables'
import { getRequest, postRequest } from '../../Utils/Funciones/requester'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import moment from 'moment'
import Select from 'react-select'
import { useSnackbar } from 'notistack'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import esLocale from 'date-fns/locale/es'
import DateFnsUtils from '@date-io/date-fns'
import CursoContext from '../../Utils/Providers/CursoProvider'
import NivelesContext from '../../Utils/Providers/NivelesProvider'
import BlackButton from '../../Utils/Botones/BlackButton'
import { setOptionSelect } from '../../Utils/Funciones/variables'
import { STYLES } from '../../Utils/variables'

function ReportesAsignacion(props) {
  const context = useContext(CursoContext)

  const headers = [
    { col: 0, name: "Clave del Criterio" },
    { col: 1, name: "Descripción del Criterio" },
    { col: 2, name: "Cumplimiento" },
  ]
  const [dataFinal, setDataFinal] = useState([])
  const [dataReal, setDataReal] = useState([])
  const [loading, setLoading] = useState(true)
  const [levels, setLevels] = useState(useContext(NivelesContext).state.levels)
  const [level, setLevel] = useState("")
  const [weeks, setWeeks] = useState([])
  const [week, setWeek] = useState("")
  const [year, setYear] = useState(
    context.state.year ? context.state.year : new Date()
  )
  const [cycle, setCycle] = useState()
  const [opcionesCycles, setOpcionesCycles] = useState([])
  const loadOpcionesCycle = async () => {
    if (opcionesCycles.length > 0) return
    try {
      let url = '/calendario/ciclo'
      const res = await getRequest(url)
      if (res.s !== 'OK') return enqueueSnackbar(res.m, STYLES.failure)
      let cyclesArray = []
      res.d.sort((x, y) => {
        if (x < y) return -1
        if (x > y) return 1
        return 0
      })
      res.d.forEach(e => cyclesArray.push(setOptionSelect(e)))
      setOpcionesCycles(cyclesArray)
      setCycle(setOptionSelect(context.state?.cycle || cyclesArray[0].value))
    } catch (error) {
      console.error(error)
      enqueueSnackbar('Ha Ocurrido un error consultando los ciclos.', STYLES.failure)
    }
  }
  const [docentes, setDocentes] = useState([])
  const [docente, setDocente] = useState("")
  const [cursos, setCursos] = useState([])
  const [curso, setCurso] = useState("")
  const [firstTime, setFirstTime] = useState(0)
  const [btnEnable, setBtnEnable] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  
  useEffect(() => {
    loadOpcionesCycle()

    let levelAux = levels[0]
    if(context.state.level) {
      let index = levels.findIndex(({value}) => value === context.state.level)
      levelAux = levels[index]
    }
    setLevel(levelAux)
    if(context.state.year) setYear(new Date(`01-01-${context.state.year}`))
    // cycle cuando se ejecuta loadOpcionesCycle() 
    // week cuando cambia level
    // docente cuando cambia level y cycle
    // curso cambia docente
  }, [])

  useEffect(() => {
    if (level !== '') {
      let weeksArr = [];
      for (let i = 1; i <= level.semanas; i++) {
        weeksArr.push(setOptionSelect(i))
      }
      setWeeks(weeksArr)
      setWeek(setOptionSelect(context.state?.week || 1))
    }
  }, [level])

  useEffect(() => {
    if(level === '' || cycle === undefined) return

    const pageController = new AbortController()
    const pageSignal = pageController.signal
    async function fetchData() {
      try{
        let json = {
          year: parseInt(moment(year).format('YYYY')),
          ciclo: cycle.value,
          nivel: level.value,
        }
        setDocentes([])
        setDocente('')
        setLoading(true)
        setBtnEnable(true)

        let url = '/reporte/lista/verificacion' 
        let res = await postRequest(url,json,pageSignal)
        if (res.s !== 'OK') return
        let docentesArray = []
        if (!res.d) enqueueSnackbar(res.m, STYLES.failure)
        else {
          for (let i = 0; i < res.d.length; i++) {
            let index = docentesArray.findIndex(
              (elem) => elem.value === res.d[i].clave_docente
            )
            if (index === -1) {
              docentesArray.push({
                value: res.d[i].clave_docente,
                label: res.d[i].nombre_docente,
                cursos: [
                  {
                    value: res.d[i].clave_curso,
                    label: `${res.d[i].clave_curso} ${res.d[i].nombre_curso}`,
                  },
                ],
              })
            } else {
              docentesArray[index].cursos.push({
                value: res.d[i].clave_curso,
                label: `${res.d[i].clave_curso} ${res.d[i].nombre_curso}`,
              })
            }
          }
          setDocentes(docentesArray)
        }

        if (context.state.docente) {
          let indexDocente = docentesArray.findIndex(
            (e) => e.value === context.state.docente
          )
          setDocente(docentesArray[indexDocente])
        } else {
          setDocente(docentesArray[0])
        }

        setBtnEnable(docentesArray.length === 0 ?true :false)
      }catch(err){ 
        console.log({err})
        enqueueSnackbar('Ha ocurrido un error realizando la consulta', STYLES.failure)
      }finally{ setLoading(false) }
    }

    fetchData()
  }, [year, cycle, level])

  useEffect(() => {
    if (docentes.length > 0) {
      if (context.state.docente) {
        setCursos(docente.cursos)
        let indexCurso = docente.cursos.findIndex(
          (e) => e.value === context.state.curso
        )
        setCurso(docente.cursos[indexCurso])
      } else {
        setCursos(docente.cursos)
        setCurso(docente.cursos[0])
      }
    } else {
      setCursos([])
      setCurso('')
    }
  }, [docente])

  useEffect(() => {
    if (curso !== '') {
      if (firstTime === 0) {
        fetchData()
        setFirstTime(1)
        context.actions.setState(null, null, null, null, null, null)
      }
    }
  }, [curso])

  function fetchData() {
    const pageController = new AbortController()
    const pageSignal = pageController.signal
    async function fetchData2() {
      try {
        setLoading(true)
        let json = {}
        if (context.state.cycle) {
          json = {
            ciclo: cycle.value,
            year: parseInt(moment(year).format('YYYY')),
            nivel: context.state.level,
            semana: context.state.week,
            clave: context.state.curso,
          }
        } else {
          json = {
            ciclo: cycle.value,
            year: parseInt(moment(year).format('YYYY')),
            nivel: level.value,
            semana: week.value,
            clave: curso.value,
          }
        }
        let res = await postRequest('/reporte/criterio', json, pageSignal)
        if (res.s !== 'OK') enqueueSnackbar(res.m, STYLES.failure)
        let data = []
        if (!res.d) enqueueSnackbar(res.m, STYLES.failure)
        else {
          res.d.forEach((row) => {
            data.push([
              row.clave,
              row.descripcion,
              row.porcentaje_alcance === '100.00'
                ? 'Cumplió'
                : row.porcentaje_alcance === '0.00'
                ? 'No cumplió'
                : 'NA',
            ])
          })
        }
        setDataFinal(data)
        setDataReal(data)
      } catch (err) { 
        console.log({err})
        enqueueSnackbar('Ha Ocurrido un error realizando la consulta.', STYLES.failure)
      }finally{ setLoading(false) }
    }
    fetchData2()
    return function cleanup() {
      pageController.abort()
    }
  }

  function search(text) {
    text = text.toLowerCase();
    let result = dataReal.filter((row) => {
      if (row[0].toLowerCase().includes(text)) return true;
      else if (row[1].toLowerCase().includes(text)) return true;
      else if (row[2].toLowerCase().includes(text)) return true;
      return false;
    });
    setDataFinal(result);
  }

  function downloadReport() {
    let dataDownload = [];
    dataDownload.push({
      DOCENTE: "CURSO",
      [docente.label]: curso.label,
      "": "",
    });
    dataDownload.push({
      DOCENTE: "SEMANA",
      [docente.label]: week.value,
      "": "",
    });
    dataDownload.push({
      DOCENTE: "Clave",
      [docente.label]: "Descripcion",
      "": "Alcance",
    });
    console.log(curso);
    console.log(week);
    dataReal.forEach((row) => {
      dataDownload.push({
        DOCENTE: row[0],
        [docente.label]: row[1],
        "": row[2],
      });
    });
    const fileType =
      "application/vnd.openxmlformats-officedocuments.spreadsheetml.sheet;charset=UTF-8";
    const ws = XLSX.utils.json_to_sheet(dataDownload);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    let today = moment().format("YYYY/MM/DD");
    FileSaver.saveAs(data, `Reporte de Cursos ${today}.xlsx`);
  }

  return (
    <Contenedor title="Reporte de Grupos">
      <div className="opciones-bar opciones-bar-reporte">
        <div className="tab-filtros" style={{ display: "block" }}>
          <div>
            <MasivaButton
              hideIcon
              style={{ padding: "5px 15px" }}
              onClick={downloadReport}
            >
              Descargar
            </MasivaButton>
          </div>
          <div>
            <p>Año:</p>
            <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
              <DatePicker
                views={["year"]}
                value={year}
                onChange={setYear}
                className="date-year"
              />
            </MuiPickersUtilsProvider>
          </div>
          <div>
            <p>Ciclo:</p>
            <Select
              options={opcionesCycles}
              className="select-search"
              classNamePrefix="select-search"
              value={cycle}
              onChange={setCycle}
            />
          </div>
          <div>
            <p>Nivel:</p>
            <Select
              options={levels}
              className="select-search"
              classNamePrefix="select-search"
              value={level}
              onChange={setLevel}
            />
          </div>
          <div>
            <p>Semana:</p>
            <Select
              options={weeks}
              className="select-weeks"
              classNamePrefix="select-search"
              value={week}
              onChange={setWeek}
            />
          </div>
          <div>
            <p>Docente:</p>
            <Select
              options={docentes}
              className="select-docente-largo"
              classNamePrefix="select-search"
              value={docente}
              onChange={setDocente}
              isDisabled={docentes.length === 0}
            />
          </div>
          <div>
            <p>Curso:</p>
            <Select
              options={cursos}
              className="select-docente-largo"
              classNamePrefix="select-search"
              value={curso}
              onChange={setCurso}
              isDisabled={docentes.length === 0}
            />
          </div>
          <div>
            <BlackButton
              style={{ marginLeft: "20px" }}
              onClick={() => fetchData()}
              disabled={btnEnable}
            >
              Buscar
            </BlackButton>
          </div>
          <div
            style={{
              float: "right",
              paddingTop: "15px",
              marginLeft: 5,
              position: "relative",
            }}
          >
            <SearchInput hideIcon search={search} />
          </div>
        </div>
      </div>
      <div style={{ height: "calc(100% - 120px)" }}>
        <DataTable
          headers={headers}
          data={dataFinal}
          loading={loading}
          paginate
        />
      </div>
    </Contenedor>
  )
}

export default React.memo(ReportesAsignacion);
