
const timeOut = minutes => { // per minute
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      // console.log({time})
      resolve()
    }, minutes * 1000 * 60)
  })
}

module.exports = {
  timeOut,
}