import React from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import "./style.css";

export default function NewButton({ onClick, noIcon, style }) {
  return (
    <button className='NewButton' onClick={onClick} style={style}>
      {!noIcon && <AddCircleIcon />}
      <span>Nuevo</span>
    </button>
  );
}
