import React from 'react'
import { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import Logo from "../../images/unitec-en-linea.jpeg"

// import { useStateValue } from '@context'

// import Tooltip from '@mui/material/Tooltip'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

import './styles.css'
import { MenuContainer, Menu, MenuToogle, OptionDiv, OptionLi, Title } from './styles.js'

const MenuGuide = ({ optionMenu }) => {
  // const [{ user }] = useStateValue()
  const [showMenu, setShowMenu] = useState(false)

  // const isBeneficiarie = user.co_tipo === 1
  useEffect(() => { hideAll() }, [])

  const refs = {
    introduccion: useRef(null),
    criterio1: useRef(null), criterio2: useRef(null), criterio3: useRef(null),
    criterio4: useRef(null), criterio5: useRef(null), criterio6: useRef(null),
    criterio7: useRef(null), criterio8: useRef(null), criterio9: useRef(null),
    criterio10: useRef(null), criterio11: useRef(null), criterio12: useRef(null),
    criterio13: useRef(null), criterio14: useRef(null), criterio15: useRef(null),
    criterio16: useRef(null), criterio17: useRef(null), criterio18: useRef(null),
    criterio19: useRef(null)
  }

  const hideAll = () => {
    Object.keys(refs).forEach(e => {
      if(e === 'introduccion') return
      refs[e].current.style.display = 'none'
    })
  }

  const handleToogle = (id) => () => {
    const displayCurrent = refs[id].current.style.display
    refs[id].current.style.display = displayCurrent === 'none' ? '' : 'none'
  }

  const handleShowMenu = () => {
    setShowMenu(show => !show)
  }

  const handleHideMenu = () => {
    setShowMenu(show => !show)
  }

  const handleNavigation = (url) => () => {
    // navigate(`/doc/${url}`)
  }

  const isSelected = path => {
    return optionMenu === path
  }

  const Option = ({ label, path }) => (
    <Link to={`/docs/${path}`}>
      <OptionLi selected={isSelected(path)} 
          // onClick={handleNavigation(path)}
      >
            {label}
      </OptionLi>
    </Link>
  )
  return (
    <>
      <MenuContainer show={showMenu} >
        <Menu>
          {/* <img src={Logo} alt='Logo' /> */}
          <ul>
            <OptionDiv>
              <Title onClick={handleToogle('introduccion')}><h4>Criterios</h4><span> {'>'} </span></Title>
              <ul ref={refs.introduccion}>
                <Option label='Introducción' path='general#intro' />
                <Option label='Búsqueda de Anuncio' path='general#anuncio' />
                <Option label='Búsqueda de Archivo' path='general#archivo' />
                {/* <Option label='Documentos' path='general#documentos' /> */}
              </ul>
            </OptionDiv>

            {[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19].map(e => 
              <OptionDiv>
                <Title onClick={handleToogle(`criterio${e}`)}><h4>Criterio {e}</h4><span> {'>'} </span></Title>
                <ul ref={refs[`criterio${e}`]}>
                  <Option label='Descripcion' path={`criterio${e}#descripcion`} />
                  {/* <Option label='Listado' path=`criterio${e}#listado` /> */}
                  <Option label='Version Ultra' path={`criterio${e}#version-ultra`} />
                  <Option label='Versiones Tradicional' path={`criterio${e}#version-tradicional`} />
                  <Option label='Observacion' path={`criterio${e}#observacion`} />
                  {/* {!isBeneficiarie && <Option label='Revisión' path=`criterio${e}#revision' />} */}
                  {/* <Option label='Detalle' path=`criterio${e}#detalle' /> */}
                </ul>
              </OptionDiv>
            )}
          </ul>
        </Menu>
      </MenuContainer>

      {/* <Tooltip title='Ver Indice de la Guía' aria-label='Ver Indice de la Guía'> */}
        {/* <MenuToogle role='button' onClick={showMenu ? handleHideMenu : handleShowMenu}>
          <FontAwesomeIcon icon={showMenu ? faTimes : faBars} />
        </MenuToogle> */}

      {/* </Tooltip> */}
    </>
  )
}

export default MenuGuide
