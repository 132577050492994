import React, { useState, useEffect, useRef } from 'react'
import Contenedor from '../../Utils/Visuales/Contenedor'
import SearchInput from '../../Utils/Visuales/SearchInput'
import MasivaButton from '../../Utils/Botones/MasivaButton'
import DataTable from '../../Utils/DataTables'
import { getRequest, postRequest } from '../../Utils/Funciones/requester'
import EditButton from '../../Utils/Botones/EditButton'
import MasivaModal from '../../Utils/Visuales/MasivaModal'
import NewModal from '../../Utils/Visuales/NewModal'
import Grid from '@material-ui/core/Grid'
import Snackbar from '@material-ui/core/Snackbar'
import Select from 'react-select'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import BlackButton from '../../Utils/Botones/BlackButton'
import MuiAlert from '@material-ui/lab/Alert'
import './style.css'
import { useSnackbar } from 'notistack'
import { STYLES } from '../../Utils/variables'

export default function Docentes() {
  const headers = [
    { col: 0, name: 'Clave' },
    { col: 1, name: 'Nombre' },
    { col: 2, name: 'Acción' },
  ]
  const docenteHeaders = [
    { col: 0, name: 'Clave' },
    { col: 1, name: 'Nombre' },
    { col: 2, name: 'Ap. Paterno' },
    { col: 3, name: 'C. Institucional' },
    { col: 4, name: 'Estado' },
  ]
  const [dataFinal, setDataFinal] = useState([])
  const [dataReal, setDataReal] = useState([])
  const [dataDownload, setDataDownload] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalEditarOpen, setModalEditarOpen] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [snackError, setSnackError] = useState(false)
  const [snackMsg, setSnackMsg] = useState('')
  const [snackType, setSnackType] = useState('')
  const generos = [
    { value: 'Masculino', label: 'Masculino' },
    { value: 'Femenino', label: 'Femenino' },
    { value: 'Prefiero no decirlo', label: 'Prefiero no decirlo' },
    { value: 'Otro', label: 'Otro' },
  ]
  const newFile = useRef(null)
  const [uploaded, setUploaded] = useState(false)
  const [newDocentes, setNewDocentes] = useState([])
  const [newDocentesView, setNewDocentesView] = useState([])
  const [modalMasivoLoading, setModalMasivoLoading] = useState(false)

  // Datos para Editar
  const [id, setId] = useState('')
  const [clave, setClave] = useState('')
  const [nombre, setNombre] = useState('')
  const [aPaterno, setAPaterno] = useState('')
  const [aMaterno, setAMaterno] = useState('')
  const [correoInstitucional, setCorreoInstitucional] = useState('')
  const [genero, setGenero] = useState('')
  const [correoPersonal, setCorreoPersonal] = useState('')
  const [telefono, setTelefono] = useState('')

  //Datos para Crear
  const [modalCrearDocente, setModalCrearDocente] = useState(false)

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const re = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  useEffect(() => {
    const pageController = new AbortController()
    const pageSignal = pageController.signal
    async function fetchData() {
      try {
        setLoading(true)
        let response = await getRequest('/docente', pageSignal)
        if (response.s === 'OK') {
          let data = []
          if (response.d) {
            response.d.forEach((docente) => {
              data.push([
                docente.clave,
                docente.nombre,
                <EditButton onClick={() => openEditModal(docente.id)}>
                  Detalle
                </EditButton>,
              ])
            })
          } else {
            enqueueSnackbar(response.m, STYLES.failure)
          }
          setDataFinal(data)
          setDataReal(data)
          setDataDownload(response.d)
        } else {
          enqueueSnackbar(response.m, STYLES.failure)
        }
        setLoading(false)
      } catch (error) {}
    }
    fetchData()
    return function cleanup() {
      pageController.abort()
    }
  }, [refresh])

  useEffect(() => {
    setClave('')
    setNombre('')
    setAPaterno('')
    setAMaterno('')
    setCorreoInstitucional('')
    setGenero('')
    setCorreoPersonal('')
    setTelefono('')
  }, [modalCrearDocente])

  function search(text) {
    text = text.toLowerCase()
    let result = dataReal.filter((row) => {
      if (row[0].toString().toLowerCase().includes(text)) return true
      else if (row[1].toLowerCase().includes(text)) return true
      return false
    })
    setDataFinal(result)
  }

  function downloadFormat() {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const ws = XLSX.utils.json_to_sheet([
      {
        clave_docente: '',
        nombre: '',
        apellido_paterno: '',
        apellido_materno: '',
        correo_institucional: '',
        correo_personal: '',
        genero: '',
        telefono: '',
      },
    ])
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, `Formato Carga Masiva de Docentes.xlsx`)
  }

  function uploadFile(e) {
    var reader = new FileReader()
    reader.readAsArrayBuffer(e.target.files[0])

    reader.onload = (e) => {
      let data = new Uint8Array(reader.result)
      let wb = XLSX.read(data, { type: 'array' })
      let dataJSON = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]])

      setNewDocentes(dataJSON)
      setNewDocentesView(
        dataJSON.map((docente) => [
          docente.clave_docente,
          docente.nombre,
          docente.apellido_paterno,
          docente.correo_institucional,
          '',
        ])
      )

      setUploaded(true)
    }
  }

  async function createDocentesMasivo() {
    for (let i = 0; i < newDocentes.length; i++) {
      let json = {
        nss: newDocentes[i].clave_docente,
        nombre: newDocentes[i].nombre,
        apaterno: newDocentes[i].apellido_paterno,
        amaterno: newDocentes[i].apellido_materno,
        email: newDocentes[i].correo_institucional,
        email2: newDocentes[i].correo_personal,
        movil: newDocentes[i].telefono,
        sexo: newDocentes[i].genero,
      }
      let response = await postRequest('/docente/nuevo', json)
      if (response.s === 'OK') {
        let aux = newDocentesView
        aux[i][4] = <span className='enviado'>Creado</span>
        setNewDocentesView(aux)
      } else {
        let aux = newDocentesView
        aux[i][4] = <span className='no-enviado'>{response.m}</span>
        setNewDocentesView(aux)
      }
      setModalMasivoLoading(true)
      setModalMasivoLoading(false)
    }
  }

  async function openEditModal(id) {
    setModalEditarOpen(true)
    setModalLoading(true)
    let response = await getRequest(`/docente/detalle?id=${id}`)
    if (response.s === 'OK') {
      setModalLoading(false)
      setId(response.d.id)
      setClave(response.d.clave)
      setNombre(response.d.nombre)
      setAPaterno(response.d.apellido_paterno)
      setAMaterno(response.d.apellido_materno)
      setCorreoInstitucional(response.d.email)
      setGenero({
        value: response.d.genero,
        label: response.d.genero,
      })
      setCorreoPersonal(response.d.email2)
    } else {
      enqueueSnackbar(response.m,  STYLES.failure)
    }
  }

  async function sendEditDocente() {
    try{

      let valido = true

      if (clave === '') {
        valido = false
        document
          .getElementsByClassName('input-clave')[0]
          .classList.add('error-input')
      } else {
        document
          .getElementsByClassName('input-clave')[0]
          .classList.remove('error-input')
      }

      if (nombre === '') {
        valido = false
        document
          .getElementsByClassName('input-nombre')[0]
          .classList.add('error-input')
      } else {
        document
          .getElementsByClassName('input-nombre')[0]
          .classList.remove('error-input')
      }

      if (genero === '') {
        valido = false
        document
          .getElementsByClassName('input-genero')[0]
          .classList.add('error-input')
      } else {
        document
          .getElementsByClassName('input-genero')[0]
          .classList.remove('error-input')
      }

      if (!re.test(correoInstitucional)) {
        valido = false
        document
          .getElementsByClassName('input-correoInstitucional')[0]
          .classList.add('error-input')
      } else {
        document
          .getElementsByClassName('input-correoInstitucional')[0]
          .classList.remove('error-input')
      }

      if (!re.test(correoPersonal)) {
        valido = false
        document
          .getElementsByClassName('input-correoPersonal')[0]
          .classList.add('error-input')
      } else {
        document
          .getElementsByClassName('input-correoPersonal')[0]
          .classList.remove('error-input')
      }
      

      if (valido) {
        let json = {
          id_docente: id,
          clave: clave,
          nombre: nombre,
          apaterno: aPaterno,
          amaterno: aMaterno,
          sexo: genero.value,
          email: correoInstitucional,
          email2: correoPersonal,
          movil: telefono,
        }

        setModalLoading(true)
        let res = await postRequest('/docente/editar', json)
        if (res.s === 'OK') {
          setModalEditarOpen(false)
          setSnackMsg('Docente editado correctamente.')
          setSnackType('success')
          setSnackError(true)
          setRefresh(refresh + 1)
        } else {
          setSnackMsg(res.m)
          setSnackType('error')
          setSnackError(true)
        }
      } else {
        setSnackMsg('Debe rellenar los campos correctamente.')
        setSnackType('error')
        setSnackError(true)
      }
    }catch(err){
      console.log({err})
      setSnackMsg('Ha Ocurrido un error.')
      setSnackType('error')
      setSnackError(true)
    }finally{ setModalLoading(false) }
    
  }

  async function sendNewDocente() {
    let valido = true

    if (clave === '') {
      valido = false
      document
        .getElementsByClassName('input-clave')[0]
        .classList.add('error-input')
    } else {
      document
        .getElementsByClassName('input-clave')[0]
        .classList.remove('error-input')
    }

    if (nombre === '') {
      valido = false
      document
        .getElementsByClassName('input-nombre')[0]
        .classList.add('error-input')
    } else {
      document
        .getElementsByClassName('input-nombre')[0]
        .classList.remove('error-input')
    }

    if (aPaterno === '') {
      valido = false
      document
        .getElementsByClassName('input-paterno')[0]
        .classList.add('error-input')
    } else {
      document
        .getElementsByClassName('input-paterno')[0]
        .classList.remove('error-input')
    }

    if (aMaterno === '') {
      valido = false
      document
        .getElementsByClassName('input-materno')[0]
        .classList.add('error-input')
    } else {
      document
        .getElementsByClassName('input-materno')[0]
        .classList.remove('error-input')
    }

    if (genero === '') {
      valido = false
      document
        .getElementsByClassName('input-genero')[0]
        .classList.add('error-input')
    } else {
      document
        .getElementsByClassName('input-genero')[0]
        .classList.remove('error-input')
    }

    if (!re.test(correoInstitucional)) {
      valido = false
      document
        .getElementsByClassName('input-correoInstitucional')[0]
        .classList.add('error-input')
    } else {
      document
        .getElementsByClassName('input-correoInstitucional')[0]
        .classList.remove('error-input')
    }

    if (!re.test(correoPersonal)) {
      valido = false
      document
        .getElementsByClassName('input-correoPersonal')[0]
        .classList.add('error-input')
    } else {
      document
        .getElementsByClassName('input-correoPersonal')[0]
        .classList.remove('error-input')
    }

    if (valido) {
      let json = {
        nss: clave,
        nombre: nombre,
        apaterno: aPaterno,
        amaterno: aMaterno,
        email: correoInstitucional,
        email2: correoPersonal,
        movil: telefono,
        sexo: genero.value,
      }
      setModalLoading(true)
      let response = await postRequest('/docente/nuevo', json)
      setModalLoading(false)
      if (response.s === 'OK') {
        setModalCrearDocente(false)
        setSnackMsg('Docente creado correctamente')
        setSnackType('success')
        setSnackError(true)
        setRefresh(refresh + 1)
      } else {
        setSnackMsg(response.m)
        setSnackType('error')
        setSnackError(true)
      }
    } else {
      setSnackMsg('Debe rellenar los campos correctamente')
      setSnackType('error')
      setSnackError(true)
    }
  }

  async function downloadReport() {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const ws = XLSX.utils.json_to_sheet(
      dataDownload.map((row) => ({
        clave: row.clave,
        nombre: row.nombre,
        correo_personal: row.correo_personal,
        correo_institucional: row.correo_institucional,
        movil: row.movil,
        genero: row.genero,
      }))
    )
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, `Reporte de Docentes.xlsx`)
  }

  return (
    <Contenedor title='Docentes'>
      <div className='opciones-bar'>
        <div style={{ float: 'left', display: 'flex' }}>
          <MasivaButton
            hideIcon
            style={{ padding: '5px 15px' }}
            onClick={downloadReport}
          >
            Descargar
          </MasivaButton>
        </div>
        <div style={{ float: 'right' }}>
          <SearchInput hideIcon search={search} />
        </div>
      </div>
      <div style={{ height: 'calc(100% - 65px)' }}>
        <DataTable
          headers={headers}
          data={dataFinal}
          loading={loading}
          paginate
          centrar={[3]}
        />
      </div>
      <MasivaModal
        handleSubmit={createDocentesMasivo}
        disabled={!uploaded}
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        height={320}
        title='Captura Masiva'
      >
        {uploaded ? (
          <div style={{ height: 270 }}>
            <DataTable
              headers={docenteHeaders}
              data={newDocentesView}
              loading={modalMasivoLoading}
              centrar={[1]}
            />
          </div>
        ) : (
          <div style={{ height: 270 }}>
            <h4 className='masiva-titulo'>Instrucciones:</h4>
            <div className='masiva-descripcion'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla et
              feugiat orci. Aliquam posuere, libero nec finibus sollicitudin,
              est felis dapibus sem, id viverra ligula sapien eu velit. Maecenas
              nunc turpis, iaculis a egestas eget, porta eget enim. Cras porta
              mauris sed mi ultrices, non cursus nisl fermentum. Cras vel sem
              tortor. Vivamus et pellentesque nisl, quis scelerisque arcu.
              Nullam tempor massa vitae arcu luctus aliquam. Pellentesque sit
              amet rutrum lorem. Vestibulum auctor sapien libero, in blandit mi
              eleifend nec. Phasellus vehicula posuere ante, eget faucibus dui
              tincidunt eu. Vivamus a diam libero. Vestibulum sit amet purus
              vitae lectus efficitur pretium vitae ac tortor. Ut iaculis massa
              nec turpis egestas aliquam. Vestibulum libero orci, maximus eu
              vehicula vel, tempor sit amet eros. Aenean vitae magna vestibulum,
              lacinia lectus et, ultricies felis. Aliquam eget velit sed mi
              rhoncus sagittis quis quis purus. Sed sollicitudin efficitur
              accumsan. Suspendisse laoreet nibh sed pulvinar eleifend. Mauris
              facilisis ante non ultrices posuere. Orci varius natoque penatibus
              et magnis dis parturient montes, nascetur ridiculus mus. Curabitur
              porttitor magna nec tincidunt maximus. Donec aliquet a tellus
              iaculis fringilla. Morbi eget magna sit amet massa vulputate
              varius ut ut augue. Integer lobortis, elit ut vestibulum accumsan,
              libero nulla laoreet ligula, sit amet consectetur massa eros vitae
              neque. Etiam vel varius erat, at pharetra risus.
            </div>
            <div className='masiva-botones'>
              <BlackButton onClick={downloadFormat}>
                Descargar Formato
              </BlackButton>
              <BlackButton onClick={() => newFile.current.click()}>
                Importar Formato
              </BlackButton>
              <input
                ref={newFile}
                type='file'
                style={{ display: 'none' }}
                onChange={(e) => uploadFile(e)}
              />
            </div>
          </div>
        )}
      </MasivaModal>
      <NewModal
        canDelete={false}
        title='Detalle Docente'
        open={modalEditarOpen}
        handleClose={() => setModalEditarOpen(false)}
        height={320}
        loading={modalLoading}
        handleSubmit={sendEditDocente}
      >
        <div className='nuevoUsuarioForm'>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <label>Clave Docente (*):</label>
              <input
                className='input-clave'
                value={clave}
                onChange={(e) => setClave(e.target.value)}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}></Grid>
            <Grid item xs={12}>
              <label>Nombre Completo (*):</label>
              <input
                className='input-nombre'
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <label>Correo Institucional (*):</label>
              <input
                className='input-correoInstitucional'
                value={correoInstitucional}
                onChange={(e) => setCorreoInstitucional(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <label>Genero (*):</label>
              <Select
                options={generos}
                placeholder='Seleccione genero...'
                className='input-genero'
                classNamePrefix='select-modal'
                value={genero}
                onChange={setGenero}
                isDisabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <label>Correo Personal (*):</label>
              <input
                className='input-correoPersonal'
                value={correoPersonal}
                onChange={(e) => setCorreoPersonal(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <label>Teléfono Móvil:</label>
              <input
                value={telefono}
                onChange={(e) => setTelefono(e.target.value)}
                disabled
              />
            </Grid>
          </Grid>
        </div>
      </NewModal>
      <NewModal
        title='Nuevo Docente'
        open={modalCrearDocente}
        handleClose={() => setModalCrearDocente(false)}
        height={320}
        loading={modalLoading}
        handleSubmit={sendNewDocente}
      >
        <div className='nuevoUsuarioForm'>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <label>Clave Docente (*):</label>
              <input
                className='input-clave'
                value={clave}
                onChange={(e) => setClave(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <label>Nombre (*):</label>
              <input
                className='input-nombre'
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <label>Apellido Paterno (*):</label>
              <input
                className='input-paterno'
                value={aPaterno}
                onChange={(e) => setAPaterno(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <label>Apellido Materno (*):</label>
              <input
                className='input-materno'
                value={aMaterno}
                onChange={(e) => setAMaterno(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <label>Correo Institucional (*):</label>
              <input
                className='input-correoInstitucional'
                value={correoInstitucional}
                onChange={(e) => setCorreoInstitucional(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <label>Genero (*):</label>
              <Select
                options={generos}
                placeholder='Seleccione genero...'
                className='select-modal input-genero'
                classNamePrefix='select-modal'
                value={genero}
                onChange={setGenero}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <label>Correo Personal (*):</label>
              <input
                className='input-correoPersonal'
                value={correoPersonal}
                onChange={(e) => setCorreoPersonal(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <label>Teléfono Móvil:</label>
              <input
                value={telefono}
                onChange={(e) => setTelefono(e.target.value)}
              />
            </Grid>
          </Grid>
        </div>
      </NewModal>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        key='top,right'
        open={snackError}
        onClose={() => setSnackError(false)}
        autoHideDuration={2000}
      >
        <MuiAlert severity={snackType}>{snackMsg}</MuiAlert>
      </Snackbar>
    </Contenedor>
  )
}
