import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { MemoryRouter as Router, Route } from 'react-router-dom'
import { SnackbarProvider } from 'notistack';
import {NivelesProvider} from './Utils/Providers/NivelesProvider';

import Documentacion from './Vistas/Documentacion'
// import { BrowserRouter, Routes, Route } from 'react-router-dom'


ReactDOM.render(<SnackbarProvider maxSnack={5} preventDuplicate autoHideDuration={2000}><NivelesProvider>
    {/* <Router> 
      <Route path='/' exact > <Documentacion id={0} /> </Route> 
      <Route path='/c'> <Documentacion id={1} /> </Route> 
      <Route path='/criterio3'> <Documentacion id={2} /> </Route> 
    </Router> */}
    <Router><App /></Router>
</NivelesProvider></SnackbarProvider>, document.getElementById('root'));