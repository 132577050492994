import React from 'react'
import { useEffect } from 'react'
// import { useAlert } from '@hooks/useAlert'
import Highlight from 'react-highlight'
import LayoutGuide from './Layout'
import { criterios } from './data'
import './styles.css'
import { useLocation } from 'react-router-dom'
import { DataGrid } from '@material-ui/data-grid';
import { Link } from 'react-router-dom'


const columns = [
  {
    field: 'alias',
    headerName: 'ALIAS DEL CAMPO',
    width: 250,
  },
  {
    field: 'table',
    headerName: 'TABLA',
    width: 150,
  },
  {
    field: 'name',
    headerName: 'CAMPO',
    width: 150,
  }
]

// import LayoutGuide from '@components/portal/guide/LayoutGuide'
// import Player from '@components/Player'
// import PhotoFrameDocument from '@components/PhotoFrameDocument'

// import { videos } from '@utils/static/guide'
// import config from '@utils/config'
// import { useStateValue } from '@context'

const CriteriosDoc = ({id}) => {
  let location = useLocation();
  // const [{ user }] = useStateValue()
  // const [AlertMaintenance] = useAlert({
    //   status: true, severity: 'info', isClose: false,
    //   msg: `<b> Módulo en mantenimiento: </b> Disculpe, se está realizando pruebas de seguridad para mantener 
    //   sus datos seguros al momento de pagar la cita, en un par de días estará disponible.`})
    
    
  useEffect(() => {
    console.log('[CriteriosDoc]', id, criterios)
    window.location.hash = ''
    window.location.hash = location.hash
  }, [location])

  const getOptionMenuOfPath = () => {
    const pathname = location.pathname.split('/')
    return `${pathname[pathname.length - 1]}${location.hash}`
  }

  // const isBeneficiarie = user.co_tipo === 1

  /* return(
    <Layout title='Citas | Manual de Usuario' active='guia'>
      <LayoutGuide optionMenu={getOptionMenuOfPath()}>
        <div id='general' />
        <h1>CITAS</h1>
        <AlertMaintenance />
      </LayoutGuide>
    </Layout>
  ) */


  return (
    // <Layout title='Citas | Manual de Usuario' active='guia'>
      <LayoutGuide optionMenu={getOptionMenuOfPath()}>
        <div id='descripcion' />
        <h1>Criterio {criterios[id].key}</h1>
        <p id='name'> <b>Nombre del Criterio:</b> {criterios[id].name} </p>
        <p dangerouslySetInnerHTML={{ __html: criterios[id].description}} />

        {!criterios[id]?.versions ?null :criterios[id].versions.map(e => (
          <section class='version'>
            <h2>Version {`${e.name}`}</h2>
            <div style={{ height: '17rem', width: '100%' }}>
              <DataGrid
                rows={e.fields}
                columns={columns}
                pageSize={3}
                />
            </div>

            <div>
              <p class='subtitle'> Query:</p>
              <Highlight language="javascript" >
                {e.query({})}
              </Highlight>
            </div>

            <div>
              <p class='subtitle'> Observacion:</p>         
              <p dangerouslySetInnerHTML={{ __html: e.observation}} />
            </div>
          </section>
        ))}

        <section id='observacion'>
          <h2>Observación</h2>
          <p dangerouslySetInnerHTML={{ __html: criterios[id].observation}} />

          {criterios[id]?.isAnnouncement 
            ?<Link to={`/docs/general#anuncio`}>
              <button className='btn'> Ir a Búsqueda de Anuncio</button>
            </Link>
            :null}

          {criterios[id]?.isArchive 
            ?<Link to={`/docs/general#archivo`}>
              <button className='btn'> Ir a Búsqueda de Archivo</button>
            </Link>
            :null}
        </section>



      </LayoutGuide>
    // </Layout>
  )
}

export default CriteriosDoc
