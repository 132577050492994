import React, { useState, useEffect, useRef } from "react";
import Contenedor from "../../Utils/Visuales/Contenedor";
import SearchInput from "../../Utils/Visuales/SearchInput";
import DataTable from "../../Utils/DataTables";
import { getRequest, postRequest } from "../../Utils/Funciones/requester";
import EditButton from "../../Utils/Botones/EditButton";
import NewModal from "../../Utils/Visuales/NewModal";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import "./style.css";
import { useSnackbar } from "notistack";

export default function Criterios() {
  const headers = [
    {
      name: "Clave del Criterio",
      col: 0,
    },
    {
      name: "Descripción del Criterio",
      col: 1,
    },
    {
      name: "Acción",
      col: 2,
    },
  ];
  const [dataFinal, setDataFinal] = useState([]);
  const [dataReal, setDataReal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalCriterio, setModalCriterio] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);

  //Valores para editar
  const [id, setId] = useState("");
  const [valor, setValor] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [status, setStatus] = useState("");
  const [tipo, setTipo] = useState("");

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    const pageController = new AbortController();
    const pageSignal = pageController.signal;
    async function fetchData() {
      try {
        setLoading(true);
        let response = await getRequest("/criterio", pageSignal);
        if (response.s === "OK") {
          let data = [];
          response.d.forEach((docente) => {
            data.push([
              docente.clave,
              docente.descripcion,
              <EditButton onClick={() => openModalCriterio(docente.id)}>
                Modificar
              </EditButton>,
            ]);
          });
          setDataReal(data);
          setDataFinal(data);
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
        setLoading(false);
      } catch (error) {}
    }
    fetchData();
    return function cleanup() {
      pageController.abort();
    };
  }, [refresh]);

  function search(text) {
    text = text.toLowerCase();
    let result = dataReal.filter((row) => {
      if (
        row[0]
          .toString()
          .toLowerCase()
          .includes(text)
      )
        return true;
      else if (row[1].toLowerCase().includes(text)) return true;
      return false;
    });
    setDataFinal(result);
  }

  async function openModalCriterio(id) {
    setModalCriterio(true);
    setModalLoading(true);
    let response = await getRequest(`/criterio/detalle?id=${id}`);
    if (response.s === "OK") {
      setModalLoading(false);
      setId(id);
      setDescripcion(response.d.descripcion);
      setTipo(response.d.tipo);
      setStatus(response.d.status === "inactivo" ? true : false);
      setValor(response.d.valor);
    } else {
      enqueueSnackbar(response.m, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  }

  async function sendEditCriterio() {
    let json = {
      id: id,
      valor: valor,
      descripcion: descripcion,
      status: status ? "inactivo" : "activo",
    };
    setModalLoading(true);
    let response = await postRequest("/criterio/editar", json);
    if (response.s === "OK") {
      setModalCriterio(false);
      setRefresh(refresh + 1);
    } else {
      enqueueSnackbar(response.m, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
    setModalLoading(false);
  }

  return (
    <Contenedor title='Criterios'>
      <div className='opciones-bar'>
        <div style={{ float: "right" }}>
          <SearchInput hideIcon search={search} />
        </div>
      </div>
      <div style={{ height: "calc(100% - 75px)" }}>
        <DataTable
          headers={headers}
          data={dataFinal}
          loading={loading}
          centrar={[1, 3]}
          criterios
        />
      </div>
      <NewModal
        title='Modificar Criterio'
        open={modalCriterio}
        handleClose={() => setModalCriterio(false)}
        height={260}
        loading={modalLoading}
        handleSubmit={sendEditCriterio}
      >
        <div className='nuevoUsuarioForm'>
          <Grid container spacing={1}>
            <Grid item xs={12} md={3}>
              <label>Código:</label>
            </Grid>
            <Grid item xs={12} md={9}>
              <input placeholder='Inserte código...' value={id} disabled />
            </Grid>
            <Grid item xs={12} md={3}>
              <label>Descripción:</label>
            </Grid>
            <Grid item xs={12} md={9}>
              <input
                placeholder='Inserte descripción...'
                value={descripcion}
                onChange={(e) => setDescripcion(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <label>Tipo:</label>
            </Grid>
            <Grid item xs={12} md={9}>
              <input value={tipo} disabled />
            </Grid>
            <Grid item xs={12} md={3}>
              <label>Valor:</label>
            </Grid>
            <Grid item xs={12} md={9}>
              <input
                placeholder='Inserte valor...'
                value={valor}
                onChange={(e) => setValor(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={3}></Grid>
            <Grid item xs={12} md={9}>
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    style={{ padding: 0 }}
                    checked={status}
                  />
                }
                label='Desactivar'
                onChange={(event) => setStatus(event.target.checked)}
              />
            </Grid>
          </Grid>
        </div>
      </NewModal>
    </Contenedor>
  );
}
