import React from 'react'
import Menu from './Menu'
import { Container, Content } from './styles'

const LayoutGuide = ({ children, optionMenu }) => {
  return (
    <Container id='layout-documentacion'>
      <Menu optionMenu={optionMenu} />
      <Content> {children} </Content>
    </Container>
  )
}

export default LayoutGuide
