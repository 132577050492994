import React, { useState, useEffect } from 'react';
import Contenedor from '../../Utils/Visuales/Contenedor';
import Infobox from '../../Utils/Visuales/Infobox';
import './index.css';
import DocentesDashIcon from '../../images/DocentesDashIcon';
import AsignacionesIcon from '../../images/AsignacionesIcon';
import CumplimientoIcon from '../../images/CumplimientoIcon';
import TimelineIcon from '@material-ui/icons/Timeline';
import Tabs from '../../Utils/Tabs';
import Select from 'react-select'
import { getRequest } from '../../Utils/Funciones/requester';
import AlcanceDocentes from './AlcanceDocentes'
import AlcanceAsignaciones from './AlcanceAsignaciones'
import AlcanceCriterios from './AlcanceCriterios'
import { useSnackbar } from 'notistack';

export default function Dashboard(){
    const [tab, setTab] = useState(1)
    const [totalDocentesAsig, setTotalDocentesAsig] = useState(0)
    const [totalAsignacionesAsig, setTotalAsignacionesAsig] = useState(0)
    const [cumplimientoAsig, setCumplimientoAsig] = useState(0)

    const [totalDocentesCri, setTotalDocentesCri] = useState(0)
    const [totalAsignacionesCri, setTotalAsignacionesCri] = useState(0)
    const [cumplimientoCri, setCumplimientoCri] = useState(0)

    const [totalDocentesDoc, setTotalDocentesDoc] = useState(0)
    const [totalAsignacionesDoc, setTotalAsignacionesDoc] = useState(0)
    const [cumplimientoDoc, setCumplimientoDoc] = useState(0)

    const [loadingIndicadores, setLoadingIndicadores] = useState(false)
    const [docentes, setDocentes] = useState([])

    const [comparadoresQuantity,setComparadoresQuantity] = useState(0)
    const [comparadoresGrupoQuantity,setComparadoresGrupoQuantity] = useState(0)
    const [comparadoresCriteriosQuantity,setComparadoresCriteriosQuantity] = useState(0)
    const [comparadorBox,setComparadorBox] =  useState([])
    const [indicadoresAsigBox,setIndicadoresAsigBox] = useState([{}])
    const [indicadoresGrupobox,setIndicadoresGrupoBox] = useState([{}])
    const [indicadoresCriteriosbox,setIndicadoresCriteriosBox] = useState([{}])
    const [weeksFiltro, setWeeksFiltro] = useState([])


    const [weekFiltro, setWeekFiltro] = useState({
        value: 1,
        label: 1
    })
    //Alcance Docente
    const [docenteCiclo, setDocenteCiclo] = useState({
        value: '01',
        label: '01'
    })
    const [docenteYear, setDocenteYear] = useState({
        label: String(new Date().getFullYear()),
        value: new Date().getFullYear()
    })
    const [docenteNivel, setDocenteNivel] = useState('')
    const [docenteSemana, setDocenteSemana] = useState({
        value: 1,
        label: 1
    })
    const [docenteDocente, setDocenteDocente] = useState({
        value: 'todos',
        label: 'Todos'
    })
    const [yearsFiltro,setYearsFiltro] = useState([{}])
    const [cycle, setCycle] = useState()
    const [opcionesCycles, setOpcionesCycles] = useState([])
    const loadOpcionesCycle = async () => {
        if (opcionesCycles.length > 0) return
        try {
          let url = '/calendario/ciclo'
          const response = await getRequest(url)
          if (response.s === 'OK') {
              let cyclesArray = []
              response.d.sort((x, y) => {
                if (x < y) return -1
                if (x > y) return 1
                return 0
              })
              response.d.forEach((mycycle) => {
                cyclesArray.push({
                  label: mycycle,
                  value: mycycle,
                })
              })
              // console.log(cyclesArray)
              setOpcionesCycles(cyclesArray)
              setCycle(cyclesArray[0])
          }
        } catch (error) {
            console.error(error);
        }
    }
    
    useEffect(() => {
        loadOpcionesCycle();
        let nuevasSemanas = []
        for(let i = 0; i < docenteNivel.semanas; i++){
            nuevasSemanas.push({
                value: i+1,
                label: i+1
            })
        }
        setWeeksFiltro(nuevasSemanas);
    }, [docenteNivel])

    const [docenteCumplimiento, setDocenteCumplimiento] = useState([
        {
            label: 'Seleccionar todos',
            value: 'todos'
        },
        {
            label: '0% - 10%',
            value: '0-10'
        },
        {
            label: '11% - 20%',
            value: '11-20'
        },
        {
            label: '21% - 30%',
            value: '21-30'
        },
        {
            label: '31% - 40%',
            value: '31-40'
        },
        {
            label: '41% - 50%',
            value: '41-50'
        },
        {
            label: '51% - 60%',
            value: '51-60'
        },
        {
            label: '61% - 70%',
            value: '61-70'
        },
        {
            label: '71% - 80%',
            value: '71-80'
        },
        {
            label: '81% - 90%',
            value: '81-90'
        },
        {
            label: '91% - 100%',
            value: '91-100'
        }
    ])
    const [dataDocente, setDataDocente] = useState([])
    const [docenteFirstTime, setDocenteFirstTime] = useState(true)
    const [docenteDocentes, setDocenteDocentes] = useState([])

    //Alcance Asignaciones
    const [asigCiclo, setAsigCiclo] = useState({
        value: '01',
        label: '01'
    })
    const [asigYear,setAsigYear] = useState({
        label: String(new Date().getFullYear()),
        value: new Date().getFullYear(),
    })
    const [asigNivel, setAsigNivel] = useState('')
    const [asigSemana, setAsigSemana] = useState({
        value: 1,
        label: 1
    })
    const [asigDocente, setAsigDocente] = useState({
        value: 'todos',
        label: 'Todos'
    })
    const [asigCumplimiento, setAsigCumplimiento] = useState([
        {
            label: 'Seleccionar todos',
            value: 'todos'
        },
        {
            label: '0% - 10%',
            value: '0-10'
        },
        {
            label: '11% - 20%',
            value: '11-20'
        },
        {
            label: '21% - 30%',
            value: '21-30'
        },
        {
            label: '31% - 40%',
            value: '31-40'
        },
        {
            label: '41% - 50%',
            value: '41-50'
        },
        {
            label: '51% - 60%',
            value: '51-60'
        },
        {
            label: '61% - 70%',
            value: '61-70'
        },
        {
            label: '71% - 80%',
            value: '71-80'
        },
        {
            label: '81% - 90%',
            value: '81-90'
        },
        {
            label: '91% - 100%',
            value: '91-100'
        }
    ])
    const [asigFirstTime, setAsigFirstTime] = useState(true)
    const [asigData, setAsigData] = useState([])

    //Alcance Criterios
    const [criCiclo, setCriCiclo] = useState({
        value: '01',
        label: '01'
    })
    const [criYear,setCriYear] =  useState({
        label: String(new Date().getFullYear()),
        value: new Date().getFullYear()
    })
    const [criNivel, setCriNivel] = useState('')
    const [criSemana, setCriSemana] = useState({
        value: 1,
        label: 1
    })
    const [criDocente, setCriDocente] = useState({
        value: 'todos',
        label: 'Todos'
    })
    const [criData, setCriData] = useState([])
    const [criFirstTime, setCriFirstTime] = useState(true)

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    function setIndicadoresAsig(docentes, asignaciones, cumplimiento,boxYear,boxCiclo,boxWeek,boxNivel,countComparadores){
        let misIndicadoresAsigBox = [...indicadoresAsigBox]
        misIndicadoresAsigBox[countComparadores] = {
            docentes,
            asignaciones,
            cumplimiento,
            boxYear,
            boxCiclo,
            boxWeek,
            boxNivel
        }
        setIndicadoresAsigBox(misIndicadoresAsigBox)
        setTotalDocentesAsig(docentes)
        setTotalAsignacionesAsig(asignaciones)
        setCumplimientoAsig(cumplimiento)
    }

    function setIndicadoresCri(docentes, asignaciones, cumplimiento,boxYear,boxCiclo,boxWeek,boxNivel,countComparadores){
        let misIndicadoresGrupoBox = [...indicadoresGrupobox]
        misIndicadoresGrupoBox[countComparadores] = {
            docentes,
            asignaciones,
            cumplimiento,
            boxYear,
            boxCiclo,
            boxWeek,
            boxNivel
        }
        setIndicadoresGrupoBox(misIndicadoresGrupoBox)
        setTotalDocentesCri(docentes)
        setTotalAsignacionesCri(asignaciones)
        setCumplimientoCri(cumplimiento)
    }

    function setIndicadoresDoc(docentes, asignaciones, cumplimiento,boxYear,boxCiclo,boxWeek,boxNivel,countComparadores){
        let misIndicadoresCriteriosBox = [...indicadoresCriteriosbox]
        misIndicadoresCriteriosBox[countComparadores] = {
            docentes,
            asignaciones,
            cumplimiento,
            boxYear,
            boxCiclo,
            boxWeek,
            boxNivel
        }
        setIndicadoresCriteriosBox(misIndicadoresCriteriosBox)
        setTotalDocentesDoc(docentes)
        setTotalAsignacionesDoc(asignaciones)
        setCumplimientoDoc(cumplimiento)
    }

    useEffect(() => {
        const myOpcionesYears = []
        const actualYear = new Date().getFullYear()
        const yearSlim = new Date().getYear() - 100
        for (let i = actualYear - 2; i <= actualYear; i++) {
          myOpcionesYears.push({
            label: String(i),
            value: i
          })
        }
        setYearsFiltro(myOpcionesYears)
        const pageController = new AbortController()
        const pageSignal = pageController.signal

        async function fetchDocentes(){
            try {
                let response = await getRequest('/docente', pageSignal)
                if(response.s === 'OK'){
                    let data = [{
                        value: 'todos',
                        label: 'Todos'
                    }];
                    response.d.forEach(docente => {
                        data.push({
                            value: docente.id,
                            label: docente.nombre
                        })
                    })
                    setDocentes(data)
                } else {
                    enqueueSnackbar(response.m, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                }
            } catch(error){

            }
        }

        fetchDocentes()
        return function cleanup(){
            pageController.abort()
        }
        loadOpcionesCycle();
    }, [])

    function agregarComparador(){
        if(loadingIndicadores){
            enqueueSnackbar('Espere a que termine la carga antes de agregar otro comparador', {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            return
        }
        if(comparadoresQuantity > 0 && tab === 1){
            setIndicadoresAsigBox([...indicadoresAsigBox,{
                boxYear:0,
                boxCiclo:0,
                boxNivel:0,
                boxWeek:0
            }])
        }else if(comparadoresGrupoQuantity > 0 && tab === 2){
            setIndicadoresGrupoBox([...indicadoresGrupobox,{
                boxYear:0,
                boxCiclo:0,
                boxNivel:0,
                boxWeek:0
            }])
        }else if( comparadoresCriteriosQuantity > 0 && tab === 3 ){
            setIndicadoresCriteriosBox([...indicadoresCriteriosbox,{
                boxYear:0,
                boxCiclo:0,
                boxNivel:0,
                boxWeek:0
            }])
        }
        if(tab === 1){
            setComparadoresQuantity(comparadoresQuantity + 1)
        }else if(tab === 2){
            setComparadoresGrupoQuantity(comparadoresGrupoQuantity + 1)
        }else if(tab === 3){
            setComparadoresCriteriosQuantity(comparadoresCriteriosQuantity + 1)
        }
    }

    function eliminarComparador(countComparadores){
        if(comparadoresQuantity > 0 && tab === 1){
            let filtrado = indicadoresAsigBox.filter((_,i) => i != countComparadores)
            setIndicadoresAsigBox(filtrado)
        }else if(comparadoresGrupoQuantity > 0 && tab === 2){
            let filtrado = indicadoresGrupobox.filter((_,i) => i != countComparadores)
            setIndicadoresGrupoBox(filtrado)
        }else if( comparadoresCriteriosQuantity > 0 && tab === 3 ){
            let filtrado = indicadoresCriteriosbox.filter((_,i) => i != countComparadores)
            setIndicadoresCriteriosBox(filtrado)
        }
    }

    function FiltrosComparadores(){
        return(
            <div className="filtros-comparadores">
                <p>Año:</p>
                <Select 
                    isDisabled={(comparadoresQuantity === 0 && tab === 1) || 
                                (comparadoresGrupoQuantity === 0 && tab ===2) || 
                                (comparadoresCriteriosQuantity === 0 && tab === 3)}
                    options={yearsFiltro}
                    className='select-search mas-ancho'
                    classNamePrefix='select-search'
                    value={tab === 1 ? docenteYear: tab === 2 ? asigYear : criYear}
                    onChange={x => {
                        if(tab === 1){
                            setDocenteYear(x)
                        }else if(tab === 2){
                            setAsigYear(x)
                        }else{
                            setCriYear(x)
                        }
                    }}
                />  
                <p>Ciclo:</p>
                <Select 
                    isDisabled={(comparadoresQuantity === 0 && tab === 1) || 
                                (comparadoresGrupoQuantity === 0 && tab ===2) || 
                                (comparadoresCriteriosQuantity === 0 && tab === 3)}
                    options={opcionesCycles}
                    className='select-search'
                    classNamePrefix='select-search'
                    value={tab === 1 ? docenteCiclo: tab === 2 ? asigCiclo : criCiclo}
                    onChange={x => {
                        if(tab === 1){
                            setDocenteCiclo(x)
                        }else if(tab === 2){
                            setAsigCiclo(x)
                        }else{
                            setCriCiclo(x)
                        }
                    }}
                />                

                <p>Semana:</p>
                <Select 
                    isDisabled={(comparadoresQuantity === 0 && tab === 1) || 
                                (comparadoresGrupoQuantity === 0 && tab ===2) || 
                                (comparadoresCriteriosQuantity === 0 && tab === 3)}
                    options={weeksFiltro}
                    className='select-weeks'
                    classNamePrefix='select-search'
                    value={weekFiltro}
                    onChange={(x) => {
                        setWeekFiltro(x)
                    }}
                />
            </div>
        )
    }

    function clearDashboard (){
        if(comparadoresQuantity>1 && tab === 1){
            setIndicadoresAsigBox([{}])
            setComparadoresQuantity(0)
        }else if(comparadoresGrupoQuantity > 1 && tab === 2){
            setIndicadoresGrupoBox([{}])
            setComparadoresGrupoQuantity(0)
        }else if(comparadoresCriteriosQuantity > 1 && tab === 3){
            setIndicadoresCriteriosBox([{}])
            setComparadoresCriteriosQuantity(0)
        }
    }

    return(
        <Contenedor 
            title='Dashboard' 
            FiltrosComparadores={FiltrosComparadores} 
            action={agregarComparador}
            clearAll = {clearDashboard}
        >
            <div className='info-boxes'>
                {
                  tab === 1 ?
                  <>
                  {
                    indicadoresAsigBox.map(reg=>(
                      <div>
                        {
                          indicadoresAsigBox.length > 1 &&  <p>Año:{reg.boxYear} Ciclo:{reg.boxCiclo} Nivel:{reg.boxNivel} Semana:{reg.boxWeek}</p>
                        }
                        <Infobox src='' title='Total de Docentes Activos' total={reg.docentes} icon={<DocentesDashIcon />} loading={loadingIndicadores} />
                        <Infobox src='' title='Total de Grupos' total={reg.asignaciones} icon={<AsignacionesIcon />} loading={loadingIndicadores} />
                        <Infobox src='' title='% Cumplimiento Promedio' total={reg.cumplimiento} icon={<CumplimientoIcon />} loading={loadingIndicadores} />
                      </div>
                    ))
                  }
                  </> : null
                }
                {
                    tab === 2 ?
                    <>
                    {
                        indicadoresGrupobox.map((reg,index)=>(
                            <div>
                                {
                                 indicadoresGrupobox.length > 1 &&  <p>Año:{reg.boxYear} Ciclo:{reg.boxCiclo} Nivel:{reg.boxNivel} Semana:{reg.boxWeek}</p>
                                }
                                <Infobox src='' title='Total de Grupos' total={reg.docentes} icon={<DocentesDashIcon />} loading={loadingIndicadores} />
                                <Infobox src='' title='Comparativa' total={reg.asignaciones} icon={<TimelineIcon />} loading={loadingIndicadores} />
                                <Infobox src='' title='% Cumplimiento' total={reg.cumplimiento} icon={<CumplimientoIcon />} loading={loadingIndicadores} />
                            </div> 
                        ))
                    }

                    </>: null
                }
                {
                    tab === 3 ?
                    <>
                    {
                        indicadoresCriteriosbox.map(reg=>(
                            <div>
                                {
                                    indicadoresCriteriosbox.length > 1 && <p>Año:{reg.boxYear} Ciclo:{reg.boxCiclo} Nivel:{reg.boxNivel} Semana:{reg.boxWeek}</p>
                                }
                                <Infobox src='' title='Total de Criterios' total={reg.docentes} icon={<DocentesDashIcon />} loading={loadingIndicadores} />
                                <Infobox src='' title='Comparativa' total={reg.asignaciones} icon={<TimelineIcon />} loading={loadingIndicadores} />
                                <Infobox src='' title='% Cumplimiento' total={reg.cumplimiento} icon={<CumplimientoIcon />} loading={loadingIndicadores} />
                            </div> 
                        ))
                    }

                    </>: null
                }
            </div>
            <div style={{marginTop: '10px', height: indicadoresAsigBox.length > 1 || indicadoresGrupobox.length > 1 || indicadoresCriteriosbox.length > 1 ?'calc(100% - 250px)' :'calc(100% - 120px)'}}>
                <Tabs
                    firstTab={<AlcanceDocentes 
                        comparadoresQuantity={comparadoresQuantity}
                        indicadoresAsigBox={indicadoresAsigBox}
                        yearsFiltro={yearsFiltro}
                        changeIndicadores={setIndicadoresAsig} 
                        loadingIndicadores={setLoadingIndicadores} 
                        docentes={docentes} setDocentes={setDocentes}
                        ciclo={docenteCiclo} setCiclo={setDocenteCiclo} 
                        year = {docenteYear} setYear= {setDocenteYear}
                        nivel={docenteNivel} setNivel={setDocenteNivel} 
                        semana={docenteSemana} setSemana={setDocenteSemana} 
                        docente={docenteDocente} setDocente={setDocenteDocente} 
                        cumplimiento={docenteCumplimiento} setCumplimiento={setDocenteCumplimiento} 
                        data={dataDocente} setData={setDataDocente} 
                        firstTime={docenteFirstTime} setFirstTime={setDocenteFirstTime}
                        filtrosComparacion = {
                             {
                                year: docenteYear.value,
                                semana: weekFiltro.value,
                                nivel: docenteNivel.value,
                                ciclo: docenteCiclo.value
                             }
                         }
                         eliminarComparador={eliminarComparador}
                         />}
                    secondTab={<AlcanceAsignaciones 
                        comparadoresQuantity={comparadoresGrupoQuantity} 
                        indicadoresAsigBox={indicadoresAsigBox}
                        yearsFiltro={yearsFiltro}
                        changeIndicadores={setIndicadoresCri} 
                        loadingIndicadores={setLoadingIndicadores} 
                        docentes={docentes} setDocentes={setDocentes}
                        ciclo={asigCiclo} setCiclo={setAsigCiclo} 
                        year = {asigYear} setYear= {setAsigYear}
                        nivel={asigNivel} setNivel={setAsigNivel} 
                        semana={asigSemana} setSemana={setAsigSemana} 
                        docente={asigDocente} setDocente={setAsigDocente} 
                        cumplimiento={asigCumplimiento} setCumplimiento={setAsigCumplimiento} 
                        data={asigData} setData={setAsigData} 
                        firstTime={asigFirstTime} setFirstTime={setAsigFirstTime} 
                        filtrosComparacion = {
                            {
                               year: asigYear.value,
                               semana: weekFiltro.value,
                               nivel: asigNivel.value,
                               ciclo: asigCiclo.value
                            }
                        }
                        eliminarComparador={eliminarComparador}
                        />}
                    thirdTab={<AlcanceCriterios 
                        comparadoresQuantity={comparadoresCriteriosQuantity} 
                        indicadoresAsigBox={indicadoresCriteriosbox}
                        yearsFiltro={yearsFiltro}
                        changeIndicadores={setIndicadoresDoc} 
                        loadingIndicadores={setLoadingIndicadores} 
                        docentes={docentes} setDocentes={setDocentes}
                        ciclo={criCiclo} setCiclo={setCriCiclo} 
                        year = {criYear} setYear= {setCriYear}
                        nivel={criNivel} setNivel={setCriNivel} 
                        semana={criSemana} setSemana={setCriSemana} 
                        docente={criDocente} setDocente={setCriDocente} 
                        data={criData} setData={setCriData} 
                        firstTime={criFirstTime} setFirstTime={setCriFirstTime} 
                        filtrosComparacion = {
                            {
                               year: criYear.value,
                               semana: weekFiltro.value,
                               nivel: criNivel.value,
                               ciclo: criCiclo.value
                            }
                        }
                        eliminarComparador={eliminarComparador}
                        />}
                    changeBox={setTab}
                />
            </div>
        </Contenedor>
    )
}