import React, { useState, useRef } from 'react'
import DateRange from '@material-ui/icons/DateRange'
import { useSnackbar } from 'notistack'
import './style.css'

export default function Calendar(props){
  const [isSelectedRow, setIsSelectedRow] = useState(false)
  const criteriosScroll = useRef(null)
  const weeksScroll = useRef(null)
  const { enqueueSnackbar } = useSnackbar()

  const NO_OP = () => {
    enqueueSnackbar('Accion Inválida, Criterio Inactivo', {
      variant: 'error',
      anchorOrigin: { vertical: 'top', horizontal: 'right' }
    })
  }

  const handleEdit = (i, criterio) => () => props.handleOpenModificarCriterio(i, criterio)
  
  function handleDragStart(e){
    if(e.target.classList.contains('selected')) setIsSelectedRow(true)
    else setIsSelectedRow(false)

    let img = document.createElement('img')
    e.dataTransfer.setDragImage(img, 0, 0)
    e.dataTransfer.effectAllowed = 'all'
  }

  function handleDragEnter(e){
    if(isSelectedRow) e.target.classList.add('selected')
    else e.target.classList.remove('selected')

    if(document.getElementsByClassName('focus')[0]){
      document.getElementsByClassName('focus')[0].classList.remove('focus')
    }
    e.target.classList.add('focus')
    e.dataTransfer.dropEffect = 'move'
  }

  function handleClick(e){
    if(e.target.classList.contains('selected')) e.target.classList.remove('selected')
    else e.target.classList.add('selected')

    if(document.getElementsByClassName('focus')[0]){
      document.getElementsByClassName('focus')[0].classList.remove('focus')
    }
    e.target.classList.add('focus')
  }

  function getWeeksHeaders(){
    let headers = []
    for(let i = 0; i < props.weeks.length; i++){
      let incompleta = false
      let myFechaInicio = ''
      let myFechaFin = ''
      for (let j=0; j<props.detalleCalendario.length; j++){
        if(props.detalleCalendario[j].semana === i+1){
          myFechaInicio = props.detalleCalendario[j].fecha_inicio
          myFechaFin = props.detalleCalendario[j].fecha_fin
          j = props.detalleCalendario.length + 1
        }
      }
      if(myFechaInicio === '') incompleta =true
      if(myFechaFin === '') incompleta =true
      headers.push(
        <div className='Calendar-Week Cell-Min-Width'>
          {props.weeks[i]} 
          <span onClick={()=>{props.handleOpenModificarSemana(i)}} 
            className='date-ranger-container' style={incompleta ? {color:'red'}:{}} 
          > <DateRange/>
          </span>
        </div>
      )
    }
    return headers
  }

  function onScroll(e){
    let x = e.target.scrollLeft
    let y = e.target.scrollTop
    criteriosScroll.current.scrollTop = y
    weeksScroll.current.scrollLeft = x
  }

  return(
    <>
      <div className='Calendar-Headers' style={{gridTemplateColumns: `50px 200px`, width: '250px', float: 'left'}}>
        <div className='Calendar-Week'> Clave </div>
        <div className='Calendar-Week'> Descripción </div>
      </div>
      <div ref={weeksScroll} className='Calendar-Headers' style={{gridTemplateColumns: `repeat(${props.weeks.length}, 1fr)`, marginRight: '16px'}}>
        {getWeeksHeaders()}
      </div>
      <div className='Calendar-Container-Full'>
        <div ref={criteriosScroll} className='Calendar-Criterios'>
          {props.criterios.map(row => ([
            <div className='Calendar-Criterio'>{row.clave}</div>,
            <div className='Calendar-Criterio'>{row.name}</div>
          ]))}
        </div>
        <div onScroll={onScroll} className='Calendar-Cells' style={{gridTemplateColumns: `repeat(${props.weeks.length}, 1fr)`, gridTemplateRows: `repeat(${props.criterios.length}, 55px)`}}>
          {props.criterios.map(criterio => {
            return criterio.weeks.map((value, i) => {
              let incompleta = false
              let myFechaInicio = ''
              let myFechaFin = ''
              for (let k=0; k<props.detalleCalendario.length; k++){
                for (let j=0; j<props.detalleCalendario[i].criterios.length; j++){
                  if(props.detalleCalendario[k].criterios[j].clave_criterio === criterio.clave 
                    && i+1 === props.detalleCalendario[k].semana
                  ){
                    myFechaInicio = props.detalleCalendario[k].criterios[j].fecha_inicio
                    myFechaFin = props.detalleCalendario[k].criterios[j].fecha_fin
                    break
                  }
                }
              }
              if(myFechaInicio === '') incompleta = true
              if(myFechaFin === '') incompleta = true
              return (
                <div 
                  clave={criterio.clave} 
                  draggable='true' 
                  onDragStart={props.active ?handleDragStart :NO_OP} 
                  onDragOver={props.active ?handleDragEnter :NO_OP} 
                  onClick={props.active ?handleClick :NO_OP} 
                  className={value ? 'Calendar-Cell selected Cell-Min-Width' : 'Calendar-Cell Cell-Min-Width'}
                >
                  <span 
                    onClick={handleEdit(i, criterio)} 
                    className='date-ranger-container-criterio' 
                    style={incompleta ? {color:'red'}:{}}
                  > <DateRange/>
                  </span>
                </div>
              )
            })
          })}
        </div>
      </div>
    </>
  )
}