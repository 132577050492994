import React from 'react'
import { useEffect } from 'react'
// import { useAlert } from '@hooks/useAlert'
import Highlight from 'react-highlight'
import LayoutGuide from './Layout'
import { general } from './data'
import './styles.css'
import { useLocation } from 'react-router-dom'
import { DataGrid } from '@material-ui/data-grid';


const columns = [
  {
    field: 'alias',
    headerName: 'ALIAS DEL CAMPO',
    width: 250,
  },
  {
    field: 'table',
    headerName: 'TABLA',
    width: 150,
  },
  {
    field: 'name',
    headerName: 'CAMPO',
    width: 150,
  }
]

// import LayoutGuide from '@components/portal/guide/LayoutGuide'
// import Player from '@components/Player'
// import PhotoFrameDocument from '@components/PhotoFrameDocument'

// import { videos } from '@utils/static/guide'
// import config from '@utils/config'
// import { useStateValue } from '@context'

const CriteriosDoc = () => {
  let location = useLocation();
  // const [{ user }] = useStateValue()
  // const [AlertMaintenance] = useAlert({
    //   status: true, severity: 'info', isClose: false,
    //   msg: `<b> Módulo en mantenimiento: </b> Disculpe, se está realizando pruebas de seguridad para mantener 
    //   sus datos seguros al momento de pagar la cita, en un par de días estará disponible.`})
    
    
  useEffect(() => {
    window.location.hash = ''
    window.location.hash = location.hash
  }, [location])

  const getOptionMenuOfPath = () => {
    const pathname = location.pathname.split('/')
    return `${pathname[pathname.length - 1]}${location.hash}`
  }

  // const isBeneficiarie = user.co_tipo === 1

  /* return(
    <Layout title='Citas | Manual de Usuario' active='guia'>
      <LayoutGuide optionMenu={getOptionMenuOfPath()}>
        <div id='general' />
        <h1>CITAS</h1>
        <AlertMaintenance />
      </LayoutGuide>
    </Layout>
  ) */

  return (
    // <Layout title='Citas | Manual de Usuario' active='guia'>
      <LayoutGuide optionMenu={getOptionMenuOfPath()}>
        <div id='intro' />
        <h1>Criterios</h1>
        <p dangerouslySetInnerHTML={{ __html: general.description}} />
    
        <div id='anuncio' style={{marginTop: 80}} >
        <h1>Búsqueda de Anuncio</h1>
        <p> {general.busquedaAnuncio.description} </p>
        {general.busquedaAnuncio.versions.map(e => (
          <section class='version'>
            <h2>Version {`${e.name}`}</h2>
            <div style={{ height: '17rem', width: '100%' }}>
              <DataGrid
                rows={e.fields}
                columns={columns}
                pageSize={3}
                />
            </div>

            <div>
              <p class='subtitle'> Query:</p>
              <Highlight language="javascript" width={50}>
                {e.query({})}
              </Highlight>
            </div>

            <div>
              <p class='subtitle'> Observacion:</p>     
              <p dangerouslySetInnerHTML={{ __html: e.observation}} />
            </div>
          </section>
        ))}
        </div>

        <div id='archivo' style={{marginTop: 80}} >
        <h1>Búsqueda de Archivo</h1>
        <p> {general.busquedaArchivo.description} </p>
        {general.busquedaArchivo.versions.map(e => (
          <section class='version'>
            <h2>Version {`${e.name}`}</h2>
            <div style={{ height: '17rem', width: '100%' }}>
              <DataGrid
                rows={e.fields}
                columns={columns}
                pageSize={3}
                />
            </div>

            <div>
              <p class='subtitle'> Query:</p>
              <Highlight language="javascript" width={50}>
                {e.query({})}
              </Highlight>
            </div>

            <div>
              <p class='subtitle'> Observacion:</p>         
                {e.observation}
            </div>
          </section>
        ))}
        </div>

      </LayoutGuide>
    // </Layout>
  )
}

export default CriteriosDoc
