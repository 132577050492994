import React from "react";
import "./style.css";
import Logo from "../../images/ICONOS-19.png";
import Safekey from "../../images/ICONOS-20.png";
import CircularProgress from "@material-ui/core/CircularProgress";
import Footer from "../../Utils/Visuales/Footer";

export default function LoginPage(props) {
  const handleClick = () => {
    props.login();
  };
  return (
    <div className='LoginPage'>
      <div>
        <div className='LoginBox'>
          <img src={Logo} alt='Logo' />
          {props.loading ? (
            <div style={{ marginTop: "30px" }}>
              <CircularProgress size={60} />
            </div>
          ) : (
            <form className='form' id='login'>
              <div className='button-container'>
                <button
                  id='submit-btn'
                  type='submit'
                  form='login'
                  className='button'
                  onClick={handleClick}
                >
                  <p>
                    <nobr>
                      Inicia sesión con <b>SAFE KEY UNITEC</b>
                    </nobr>
                  </p>
                  <img src={Safekey} alt='boton_safekey' />
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
      <Footer version='0.0.1' />
    </div>
  );
}
