import React, { useState, useEffect, useContext } from 'react'
import { Route, useHistory } from 'react-router-dom'
import LoginPage from './Vistas/LoginPage'
import Header from './Utils/Visuales/Header'
import Menu from './Utils/Menu'
import Dashboard from './Vistas/Dashboard'
import Usuarios from './Vistas/Usuarios'
import Docentes from './Vistas/Docentes'
import Documentacion from './Vistas/Documentacion'
import DocGeneral from './Vistas/Documentacion/General'
import Criterios from './Vistas/Criterios'
import Envio from './Vistas/Envio'
import Coordinadores from './Vistas/Coordinadores'
import Calendario from './Vistas/Calendario'
import './App.css'
import { getRequest } from './Utils/Funciones/requester'
import { ability, setPermisos } from './Utils/Funciones/ability'
import Historial from './Vistas/Historial'
import ReportesLista from './Vistas/Reportes/ReportesLista'
import ReportesConcentrado from './Vistas/Reportes/ReportesConcentrado'
import ReportesCriterios from './Vistas/Reportes/ReportesCriterios'
import ReportesAsignacion from './Vistas/Reportes/ReportesAsignacion'
import { ListaVerificacionProvider } from './Utils/Providers/ListaVerificacionProvider'
import { ConcentradoProvider } from './Utils/Providers/ConcentradoProvider'
import { CursoProvider } from './Utils/Providers/CursoProvider'
import NivelesContext from './Utils/Providers/NivelesProvider'
import { useSnackbar } from 'notistack'
import config from './config'
import { STYLES } from './Utils/variables'

export default function App() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [usernameError, setUsernameError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [logged, setLogged] = useState(false)
  const [logging, setLogging] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [nombre, setNombre] = useState('Usuario')
  const [userRol, setUserRol] = useState('Rol')
  const [image, setImage] = useState('')
  let history = useHistory()

  const context = useContext(NivelesContext)
  const { enqueueSnackbar } = useSnackbar()

  async function login() {
    setErrorMsg('')
    setLogging(true)
    try {
      if (config.isDev) {
        localStorage.setItem('token_tv', '2024072322581903874305')
        return window.location.reload()
      }

      const data = await getRequest(
        '?plt=silver&token=&rol=administrador',
        null,
        false,
        true
      )
      if (data.s === 'OK') {
        if (data['d']['data']['token']) localStorage.setItem('token_tv', data['d']['data']['token'])
        window.location.href = data['d']['data']['url_login']
      } else if (data.s === 'Error') {
        enqueueSnackbar(data.m, STYLES.failure)
      } else {
        setErrorMsg(data.m)
      }
    } catch (error) {
      console.error(error)
    } finally { setLogging(false) }
  }

  useEffect(() => {
    checkLogin()
  }, [])

  async function checkLogin() {
    let token = localStorage.getItem('token_tv')
    if (token) {
      let myUrl = window.location.href
      let inicioParametros = myUrl.indexOf('?')
      let newUrl = myUrl.substr(0, inicioParametros)

      window.history.pushState('obj', 'newtitle', newUrl)
      setLogging(true)
      let response = await getRequest('/login/detalle')

      if (response && response.s === 'OK') {
        let responseNiveles = await getRequest('/nivel')
        if (responseNiveles.s === 'OK') {
          if (responseNiveles.d.length > 0) {
            let data = []
            responseNiveles.d.forEach((nivel) => {
              let totalSemanas = 1
              nivel.calendarios.forEach((modulo) => {
                if (modulo.total_semanas > totalSemanas) {
                  totalSemanas = modulo.total_semanas
                }
              })
              data.push({
                label: nivel.nombre,
                value: nivel.nombre,
                semanas: totalSemanas,
              })
            })
            context.actions.setNiveles(data)
          } else {
            enqueueSnackbar(responseNiveles.m, STYLES.failure)
            context.actions.setNiveles([])
          }
        } else {
          enqueueSnackbar(response.m, STYLES.failure)
        }

        ability.update(setPermisos(response.d.permisos))
        setLogging(false)
        setLogged(true)
        setNombre(response.d.nombre)
        setUserRol(response.d.rol)
        setImage(response.d.url_img)
        history.push('/dashboard')
      } else {
        setLogging(false)
      }
    } else {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      const code = urlParams.get('code')
      // const mode =urlParams.get('mode')
      // const mail =urlParams.get('mail')

      if (code !== null) {
        setLogging(true)
        try {
          // let url = `?plt=silver&code=${code}&rol=administrador`
          // if(mode) url += `&mode=${mode}`
          // if(mail) url += `&mail=${mail}`
          // const data = await getRequest(url,null,false,true)

          const data = await getRequest(
            `?plt=silver&code=${code}&rol=administrador&mode=dev&mail=test@test.com`,
            null,
            false,
            true
          )
          console.log({ data })
          if (data.s === 'OK') {
            window.localStorage.setItem('token_tv', data['d']['data']['token'])
            window.location.reload()
          } else if (data.s === 'Error') {
            enqueueSnackbar(data.m, STYLES.failure)
          } else {
            enqueueSnackbar(data.m, STYLES.failure)
            window.localStorage.removeItem('token_tv')
            let myUrl = window.location.href
            let inicioParametros = myUrl.indexOf('?')
            let newUrl = myUrl.substr(0, inicioParametros)
            window.history.pushState('obj', 'newtitle', newUrl)
            setLogging(false)
          }
        } catch (error) {
          console.log(error)
          let myUrl = window.location.href
          let inicioParametros = myUrl.indexOf('?')
          let newUrl = myUrl.substr(0, inicioParametros)

          window.history.pushState('obj', 'newtitle', newUrl)
          setTimeout(() => {
            window.location.reload()
          }, 2000)

          window.localStorage.removeItem('token_tv')
          setLogging(false)
        }
      }
    }
  }

  return (
    <>
      {logged ? (
        <ListaVerificacionProvider>
          <ConcentradoProvider>
            <CursoProvider>
              <Header username={nombre} rol={userRol} image={image} />
              <div className='container-main'>
                <Menu />
                <Route path='/dashboard'> <Dashboard /> </Route>
                <Route path='/usuarios'> <Usuarios /> </Route>
                <Route path='/docentes'> <Docentes /> </Route>
                <Route path='/coordinadores'> <Coordinadores /> </Route>
                <Route path='/calendario'> <Calendario /> </Route>
                <Route path='/criterios'> <Criterios /> </Route>
                <Route path='/historial'> <Historial /> </Route>
                <Route path='/envio'> <Envio /> </Route>
                <Route path='/reportes/lista'> <ReportesLista /> </Route>
                <Route path='/reportes/concentrado'> <ReportesConcentrado /> </Route>
                <Route path='/reportes/criterios'> <ReportesCriterios /> </Route>
                <Route path='/reportes/curso'> <ReportesAsignacion /> </Route>
                <Route path='/docs/general'> <DocGeneral /> </Route>
                <Route path='/docs/criterio1'> <Documentacion id={1} /> </Route>
                <Route path='/docs/criterio2'> <Documentacion id={2} /> </Route>
                <Route path='/docs/criterio3'> <Documentacion id={3} /> </Route>
                <Route path='/docs/criterio4'> <Documentacion id={4} /> </Route>
                <Route path='/docs/criterio5'> <Documentacion id={5} /> </Route>
                <Route path='/docs/criterio6'> <Documentacion id={6} /> </Route>
                <Route path='/docs/criterio7'> <Documentacion id={7} /> </Route>
                <Route path='/docs/criterio8'> <Documentacion id={8} /> </Route>
                <Route path='/docs/criterio9'> <Documentacion id={9} /> </Route>
                <Route path='/docs/criterio10'> <Documentacion id={10} /> </Route>
                <Route path='/docs/criterio11'> <Documentacion id={11} /> </Route>
                <Route path='/docs/criterio12'> <Documentacion id={12} /> </Route>
                <Route path='/docs/criterio13'> <Documentacion id={13} /> </Route>
                <Route path='/docs/criterio14'> <Documentacion id={14} /> </Route>
                <Route path='/docs/criterio15'> <Documentacion id={15} /> </Route>
                <Route path='/docs/criterio16'> <Documentacion id={16} /> </Route>
                <Route path='/docs/criterio17'> <Documentacion id={17} /> </Route>
                <Route path='/docs/criterio18'> <Documentacion id={18} /> </Route>
                <Route path='/docs/criterio19'> <Documentacion id={19} /> </Route>

              </div>
            </CursoProvider>
          </ConcentradoProvider>
        </ListaVerificacionProvider>
      ) : (
        <LoginPage
          login={login}
          user={username}
          error={errorMsg}
          userError={usernameError}
          passError={passwordError}
          setUser={setUsername}
          setPass={setPassword}
          loading={logging}
        />
      )}
    </>
  )
}
