import React, { useState } from "react";
import "./index.css";

export default function Tabs(props) {
  const [activeTab, setActiveTab] = useState(1);
  let tabColor, tabComponent;

  if (activeTab === 1) {
    tabColor = "#27215f";
    tabComponent = props.firstTab;
  } else if (activeTab === 2) {
    tabColor = "#27215f";
    tabComponent = props.secondTab;
  } else {
    tabColor = "#27215f";
    tabComponent = props.thirdTab;
  }

  return (
    <>
      <div className='tabs-list'>
        <div
          className={activeTab === 1 ? "tab active" : "tab"}
          onClick={() => {
            setActiveTab(1);
            props.changeBox(1);
          }}
        >
          Desempeño por Docente
        </div>
        <div
          className={activeTab === 2 ? "tab active" : "tab"}
          onClick={() => {
            setActiveTab(2);
            props.changeBox(2);
          }}
        >
          Desempeño por Grupo
        </div>
        {
          <div
            className={activeTab === 3 ? "tab active" : "tab"}
            onClick={() => {
              setActiveTab(3);
              props.changeBox(3);
            }}
          >
            Desempeño por Criterio
          </div>
        }
      </div>
      <div className='tab-content'>
        <div className='tab-header' style={{ backgroundColor: tabColor }}></div>
        <div
          style={
            activeTab === 1
              ? { display: "block", height: "99%" }
              : { display: "none" }
          }
        >
          {props.firstTab}
        </div>
        <div
          style={
            activeTab === 2
              ? { display: "block", height: "99%" }
              : { display: "none" }
          }
        >
          {props.secondTab}
        </div>
        <div
          style={
            activeTab === 3
              ? { display: "block", height: "99%" }
              : { display: "none" }
          }
        >
          {props.thirdTab}
        </div>
      </div>
    </>
  );
}
