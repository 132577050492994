import React from 'react'
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress'
import CloseIcon from '@material-ui/icons/Close'

export default function MailModal(props){
  return(
    <Modal 
      open={props.open} 
      onClose={props.handleClose} 
      style={{zIndex: 9999999}} 
      disableBackdropClick
    >
      <div className='NewModal' style={props.log ? {width: '80%', maxWidth: 'none'} : {}}>
        <CloseIcon onClick={props.handleClose} />
        <h3> {props.title} </h3>
        <div className='NewModal-container' style={{height: props.height, position: 'relative'}}>
          {props.loading 
            ?<div style={{textAlign: 'center', paddingTop: 30}}>
              <CircularProgress size={50} />
            </div> 
            
            :<>
              {props.children}
              <div className='NewModal-footer'>
                <button 
                  className='gray-btn' 
                  onClick={props.handleClose}
                > {props.regresarBtn ? 'Regresar' : 'Cerrar'}
                </button>
                
                {props.log ?'' 
                  :<button 
                    className='black-btn' 
                    onClick={props.handleSave}
                  > {props.enviar ? 'Enviar' : 'Guardar'}
                  </button>
                }
              </div>
            </>
          }
        </div>
      </div>
    </Modal>
  )
}