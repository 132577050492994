import React, { useState, useEffect, useRef } from 'react'
import Contenedor from '../../Utils/Visuales/Contenedor'
import { MuiPickersUtilsProvider, TimePicker, DatePicker } from '@material-ui/pickers'
import esLocale from "date-fns/locale/es";
import BlackButton from '../../Utils/Botones/BlackButton'
import Can from '../../Utils/Funciones/can'
import DateFnsUtils from '@date-io/date-fns'
import SearchInput from '../../Utils/Visuales/SearchInput'
import './style.css'
import { getRequest, postRequest } from '../../Utils/Funciones/requester'
import DataTable from '../../Utils/DataTables'
import EditButton from '../../Utils/Botones/EditButton'
import Select from 'react-select'
import NewModal from '../../Utils/Visuales/NewModal'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import moment from 'moment'
import { useSnackbar } from 'notistack';

export default function Envio(){
    const headers = [
        {
            name: 'Próxima Ejecución',
            col: 0
        },
        {
            name: 'Semana',
            col: 1
        },
        {
            name: 'Estatus',
            col: 2
        },
        {
            name: 'Acción',
            col: 3
        }
    ];
    const [loading, setLoading] = useState(false)
    const [dataFinal, setDataFinal] = useState([])
    const [dataReal, setDataReal] = useState([])
    const days = [
        {
            value: 'lunes',
            label: 'Lunes'
        },
        {
            value: 'martes',
            label: 'Martes'
        },
        {
            value: 'miercoles',
            label: 'Miercoles'
        },
        {
            value: 'jueves',
            label: 'Jueves'
        },
        {
            value: 'viernes',
            label: 'Viernes'
        },
        {
            value: 'sabado',
            label: 'Sabado'
        },
        {
            value: 'domingo',
            label: 'Domingo'
        }
    ]
    const [day, setDay] = useState({})
    const [modalModificar, setModalModificar] = useState(false)
    const [modalLoading, setModalLoading] = useState(false)
    const envioEdit = {
        idRef: useRef(null),
        semanaRef: useRef(null),
        justificacionRef: useRef(null)
    }
    const [editStatus,setEditStatus]=useState('E')

    const [fechaNueva,setFechaNueva]=useState(new Date())

    const [fecha, setFecha] = useState('')
    const [hora, setHora] = useState('')
    const [modificar, setModificar] = useState(true)
    const [refresh, setRefresh] = useState(0)
    const [snackOpen, setSnackOpen] = useState(false)
    const [snackMsg, setSnackMsg] = useState('')
    const [snackType, setSnackType] = useState('')

    const [lastDay, setLastDay] = useState('');
    const [lastHour, setLastHour] = useState('');
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        const pageController = new AbortController()
        const pageSignal = pageController.signal
        async function fetchData(){
            try {
                setLoading(true)
                let response = await getRequest('/envioautomatico', pageSignal)
                if(response.s === 'OK'){
                    if(response.d===""){
                        enqueueSnackbar(response.m, {
                            variant: 'error',
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            }
                        });
                        setLoading(false)
                        return
                    }
                    let data = []
                    response.d.fechas.forEach(envio => {
                        data.push([
                            moment(envio.fecha_hora).format('DD-MM-YYYY hh:mm'),
                            envio.semana,
                            envio.status,
                            <Can I='edit' a='envioautomatico'>
                                <EditButton onClick={() => openModalEdit(envio.id)}>Modificar</EditButton>
                            </Can>
                        ])
                    })
                    setDay({
                        value: response.d.horario.dia,
                        label: response.d.horario.dia
                    })
                    let parseHour = response.d.horario.hora.split(':')
                    let hour = moment().hour(parseHour[0]).minute(parseHour[1])
                    setHora(hour)
                    setDataFinal(data)
                    setDataReal(data)
                } else {
                    enqueueSnackbar(response.m, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                }
                setLoading(false)
            } catch (error) {

            }
        }
        fetchData()
        return function cleanup(){
            pageController.abort()
        }
    }, [refresh])

    async function openModalEdit(id){
        setModalModificar(true);
        setModalLoading(true);
        let response = await getRequest(`/envioautomatico/detalle?id=${id}`);
        if(response.s === 'OK'){
            setModalLoading(false);
            setFecha(moment(response.d.fecha));
            setFechaNueva(moment(response.d.fecha));
            let horaStr = response.d.hora.split(':');
            let nuevaHora = moment().hour(horaStr[0]).minute(horaStr[1]);
            setHora(nuevaHora);
            envioEdit.semanaRef.current.value = response.d.semana;
            envioEdit.justificacionRef.current.value = response.d.justificacion;
            envioEdit.idRef.current.value = response.d.id;
            setEditStatus(!response.d.activo);
        } else {
            enqueueSnackbar(response.m, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
        }
    }

    async function saveEnvio(){
        let json = {
            dia: day.value,
            hora: moment(hora).format('HH:mm')
        }
        setLoading(true)
        setModificar(true)
        let response = await postRequest('/envioautomatico/editar/todos', json)
        if(response.s === 'OK'){
            setRefresh(refresh+1)
            setSnackType('success')
            setSnackMsg('Guardado correctamente.')
            setSnackOpen(true)
        } else {
            enqueueSnackbar(response.m, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
        }
    }

    function search(text){
        text = text.toLowerCase()
        let result = dataReal.filter(row => {
            if(row[0].includes(text)) return true
            else if(row[1].includes(text)) return true
            else if(row[2].toLowerCase().includes(text)) return true
            return false
        })
        setDataFinal(result)
        
    }

    async function sendEditEnvio(){
        if(envioEdit.justificacionRef.current.value === ''){
            envioEdit.justificacionRef.current.classList.add('error-input')
            enqueueSnackbar('Debe rellenar la justificación', {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
        } else {
            envioEdit.justificacionRef.current.classList.remove('error-input')
            let json={
                "id":parseInt(envioEdit.idRef.current.value),
                "fecha_hora":moment(fechaNueva).format('YYYY-MM-DD')+" "+moment(hora).format('HH:mm'),
                "justificacion":envioEdit.justificacionRef.current.value,
                "status":(editStatus?'inactivo':'activo')
            }
            setModalLoading(true)
            let response=await postRequest('/envioautomatico/editar',json)
            if(response.s === 'OK'){
                setRefresh(refresh+1)
                setSnackType('success')
                setSnackMsg('Guardado correctamente.')
                setSnackOpen(true)
                setModalModificar(false)
            }else{
                setModalLoading(false)
                setSnackMsg(response.m)
                setSnackType('error')
                setSnackOpen(true)
                setModalModificar(false)
            }
        }

    }

    function cancelEnvio(){
        setDay(lastDay);
        setHora(lastHour);
        setModificar(true);
    }

    return(
        <Contenedor title='Envío Automático'>
            <div className='opciones-bar'>
                <div className='tab-filtros'>
                    <Can I='create' a='envioautomatico'>
                        <BlackButton onClick={() => {
                            setLastDay(day);
                            setLastHour(hora);
                            setModificar(false);
                        }}>Modificación Masiva</BlackButton>
                    </Can>    
                        <label style={{marginLeft: '20px'}}>Día:</label>
                        <Select
                            options={days}
                            className='select-search'
                            classNamePrefix='select-search'
                            value={day} 
                            onChange={x => setDay(x)}
                            isDisabled={modificar}
                        />
                        <label>Hora de Ejecución:</label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker cancelLabel="Cancelar" disabled={modificar} value={hora} ampm={false} onChange={setHora} />
                        </MuiPickersUtilsProvider>
                    <Can I='edit' a='envioautomatico'>
                        {
                            modificar ?
                            null
                            : <><BlackButton onClick={saveEnvio} style={{marginLeft: '10px'}}>Aplicar</BlackButton>
                              <BlackButton onClick={cancelEnvio} style={{marginLeft: 10, backgroundColor: '#888'}}>Cancelar</BlackButton></>
                        }
                    </Can>
                </div>
                <div style={{paddingTop: '5px', marginLeft: 5, position: 'relative', float: 'right'}}>
                    <SearchInput hideIcon search={search} disabled={modificar} />
                </div>
            </div>
            <div style={{height: 'calc(100% - 65px)'}}>
                <DataTable 
                    headers={headers}
                    data={dataFinal}
                    loading={loading}
                    paginate
                    centrar={[2, 4]}
                />
            </div>
            <NewModal title='Modificar Horario de Ejecución' open={modalModificar} handleClose={() => setModalModificar(false)} handleSubmit={sendEditEnvio} height={350} loading={modalLoading}>
                <div className='nuevoUsuarioForm'>
                    <Grid container spacing={1}>
                        <input ref={envioEdit.idRef} style={{display: 'none'}} disabled />
                        <Grid item xs={12} md={6}>
                            <label>Fecha:</label>
                            <input value={moment(fecha).format('DD/MM/YYYY')} disabled />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <label>Semana:</label>
                            <input ref={envioEdit.semanaRef} disabled />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <label>Nueva Fecha (*):</label>
                            <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils} style={{zIndex: 999999999}}>
                                <DatePicker value={fechaNueva} onChange={(e)=>{setFechaNueva(e)}} clearLabel="Limpiar" cancelLabel="Cancelar" className='date-picker' clearable format='dd' disabled={editStatus === 'E'} />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <label>Nueva Hora (*):</label>
                            <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                                <TimePicker onChange={(e)=>{setHora(e)}} cancelLabel="Cancelar" className='date-picker' format='HH:mm' ampm={false} value={hora} disabled={editStatus === 'E'} />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel 
                                control={
                                    <Checkbox onChange={() => {setEditStatus(!editStatus)}} checked={editStatus} color='primary' style={{padding: 0}} />
                                }
                                label='Desactivar'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <label>Justificación (*):</label>
                            <textarea rows={5} className='textarea-full' ref={envioEdit.justificacionRef}></textarea>
                        </Grid>
                    </Grid>
                </div>
            </NewModal>
            <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'right'}} key='top,right' open={snackOpen} onClose={() => setSnackOpen(false)} autoHideDuration={2000}>
                <MuiAlert severity={snackType}>
                    {snackMsg}
                </MuiAlert>
            </Snackbar>
        </Contenedor>
    )
}