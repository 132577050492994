import React, { useState, useEffect, useContext } from 'react'
import './index.css'
import DataTables from '../../Utils/DataTables'
import EditButton from '../../Utils/Botones/EditButton'
import { getRequest, postRequest } from '../../Utils/Funciones/requester'
import Select from 'react-select'
import { components } from 'react-select'
import LargeModal from '../../Utils/Visuales/LargeModal'
import { useHistory } from 'react-router-dom'
import ListaVerificacionContext from '../../Utils/Providers/ListaVerificacionProvider'
import ConcentradoContext from '../../Utils/Providers/ConcentradoProvider'
import { useSnackbar } from 'notistack'
import Can from '../../Utils/Funciones/can'
import NivelesContext from '../../Utils/Providers/NivelesProvider'

const STYLES = {
  success: {
    variant: 'success',
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  },
  failure: {
    variant: 'error',
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  }
}

const Option = props => {
    return(
        <div>
            <components.Option {...props}>
                <input 
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    )
}

const SelectContainer = ({ children, ...props }) => {
    return(
        <div style={{position: 'relative'}}>
            <components.SelectContainer {...props}>
                <div className='select-alcance-abs'>
                    Seleccione Cumplimiento...
                </div>
                {children}
            </components.SelectContainer>
        </div>
    )
}

const MultiValue = props => {
    return(
        null
    )
}

export default function AlcanceDocentes(props){
    const [headers,setHeaders] = useState([
        { col: 0, name: 'Clave del Docente' },
        { col: 1, name: 'Nombre' },
        { col: 2, name: 'Nivel' },
        { col: 3, name: 'Cumplimiento Prom. Docente' },
        { col: 4, name: 'Acciones' }
    ])
    const [data, setData] = useState(props.data)
    const [dataFinal, setDataFinal] = useState(props.data)
    const [dataCompleta,setDataCompleta] = useState([])
    const [dataObjeto, setDataObjeto] = useState([])
    const [tableLoading, setTableLoading] = useState(false)
    const [week, setWeek] = useState(props.semana)
    const [docentes, setDocentes] = useState(props.docentes)
    const [docente, setDocente] = useState(props.docente)
    const [niveles, setNiveles] = useState(useContext(NivelesContext).state.levels)
    const [nivel, setNivel] = useState(props.nivel)
    const [countComparadores,setCountComparadores] = useState(0)
    const [cycle, setCycle] = useState(props.ciclo)
    const [opcionesCycles, setOpcionesCycles] = useState([])
    const cycles = []
    const loadOpcionesCycle = async () => {
        if (opcionesCycles.length > 0) return
        try {
          let url = '/calendario/ciclo'
          const response = await getRequest(url)
          if (response.s === 'OK') {
            let cyclesArray = []
            response.d.sort((x, y) => {
              if (x < y) return -1
              if (x > y) return 1
              return 0
            })
            response.d.forEach((mycycle) => {
              cyclesArray.push({
                label: mycycle,
                value: mycycle,
              })
            })
            setOpcionesCycles(cyclesArray)
            setCycle(cyclesArray[0])
          }
        } catch (error) {
          console.error(error)
        }
      };
    
    const [year,setYear] = useState(props.year)
    const [alcances, setAlcances] = useState(props.cumplimiento)
    const alcancesSet = [
        {
            label: 'Seleccionar todos',
            value: 'todos'
        },
        {
            label: '0% - 10%',
            value: '0-10'
        },
        {
            label: '11% - 20%',
            value: '11-20'
        },
        {
            label: '21% - 30%',
            value: '21-30'
        },
        {
            label: '31% - 40%',
            value: '31-40'
        },
        {
            label: '41% - 50%',
            value: '41-50'
        },
        {
            label: '51% - 60%',
            value: '51-60'
        },
        {
            label: '61% - 70%',
            value: '61-70'
        },
        {
            label: '71% - 80%',
            value: '71-80'
        },
        {
            label: '81% - 90%',
            value: '81-90'
        },
        {
            label: '91% - 100%',
            value: '91-100'
        }
    ]
    const [weeks, setWeeks] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [modalLoading, setModalLoading] = useState(false)
    const [claveModal, setClaveModal] = useState('')
    const [nombreModal, setNombreModal] = useState('')
    const [dataModal, setDataModal] = useState([])
    let history = useHistory()

    const [reRender, setReRender] = useState(props.firstTime ? true : false)

    const context = useContext(ListaVerificacionContext)
    const context2 = useContext(ConcentradoContext)

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
      if(props.firstTime){
        loadOpcionesCycle()
        if(niveles.length > 0){
          if(data.length === 0){
              setNivel(niveles[0])
              props.setNivel(niveles[0])
          }
        } else {
          setData([])
          setDataFinal([])
          setDocentes([])
          props.loadingIndicadores(false)
          setTableLoading(false)
        }
      }
    }, [])
 
    useEffect(() => {
        if(nivel !== ''){
            let nuevasSemanas = []
            for(let i = 0; i < nivel.semanas; i++){
                nuevasSemanas.push({
                    value: i+1,
                    label: i+1
                })
            }
            setWeeks(nuevasSemanas);
            if(reRender){
                setWeek(nuevasSemanas[0]);
                props.setSemana(nuevasSemanas[0])
            }
        }
    }, [nivel])

    useEffect(() =>{
        if(props.comparadoresQuantity > 1){
            fetchComparacion()
        }else{
            if(props.comparadoresQuantity == 0 && props.data.length != 0){
                fetchData()
            }
        }
    },[props.comparadoresQuantity])

    async function fetchData(){ 
      try{
        // console.log('[AlcanceDocente | fetchData]')
        setTableLoading(true)
        props.loadingIndicadores(true)
        let json = {
            year: year?.value,
            semana: week?.value,
            nivel: nivel?.value,
            ciclo: cycle?.value
        }
        if(docente.value !== 'todos'){
          json.id_docente = docente.value
        }
        let res = await postRequest('/reporte/alcance/docente', json)
        if(['ok', 'OK'].includes(res.s)){
            setHeaders([])
            setDataObjeto(res.d.resultado)
            let newData = []
            let newDataDocente = [{ label: 'Todos', value: 'todos' }]
            let totalDocentes = 0
            let totalAsignaciones = 0
            let totalCumplimiento = 0
            
            if(res.d){
                totalDocentes = res.d.resultado.length
                res.d.resultado.forEach(docente => {
                    totalAsignaciones += docente.cursos.length
                    let newAlcance = 0
                    docente.cursos.forEach(curso => {
                        newAlcance += curso.porcentaje_alcance
                    })
                    newAlcance = newAlcance/docente.cursos.length
                    docente.cumplimiento = [newAlcance]
                    totalCumplimiento += newAlcance
                    newData.push([
                        docente.clave_docente, 
                        docente.nombre_docente, 
                        docente.nivel, 
                        `${Math.round(newAlcance * 100)/100}%`,
                        <EditButton onClick={() => openDetalleModal(docente.clave_docente, docente.nombre_docente, JSON.stringify(docente.cursos))}>Detalle</EditButton>, 
                        docente.cursos.length, 
                        newAlcance
                    ])
                    newDataDocente.push({
                        value: docente.clave_docente,
                        label: docente.nombre_docente
                    })
                })
                setDataCompleta(res.d.resultado)
            }
            let newHeaders = [
              { col: 0, name: 'Clave del Docente' },
              { col: 1, name: 'Nombre' },
              { col: 2, name: 'Nivel' },
              { col: 3, name: 'Cumplimiento Prom. Docente' },
              { col: 4, name: 'Acciones' }
            ] 
            setHeaders(newHeaders)
            setCountComparadores(0)
            props.loadingIndicadores(false)
            setData(newData)
            setDataFinal(newData)
            props.setData(newData)
            setDocentes(newDataDocente)
            props.setDocentes(newDataDocente)

            if(newData.length > 0){
              let totalCursos = 0;
              let totalCumplimiento = 0;
                newData.forEach(row => {
                    totalCursos += row[5];
                    totalCumplimiento += row[6];
                })
                totalCumplimiento = `${Math.round((totalCumplimiento/newData.length)*100)/100}%`;
                props.changeIndicadores(newData.length, totalCursos, totalCumplimiento,year.value,cycle.value,week.value,nivel.value,0);
            } else {
              props.changeIndicadores(0, 0, '-',year.value,cycle.value,week.value,nivel.value,0)
            }
            
        } else enqueueSnackbar(res.m, STYLES.failure)
      } catch (err){ 
        enqueueSnackbar('Disculpe, ha ocurrido un error.', STYLES.failure)
        console.error('[AlcanceDocente | fetchData]', err) 
      } finally { setTableLoading(false) }
    }

    async function fetchComparacion(){
        setTableLoading(true)
        props.loadingIndicadores(true)
        try{
            let json = props.filtrosComparacion
            if(docente.value !== 'todos'){
                json.id_docente = docente.value
            }
            let res = await postRequest('/reporte/alcance/docente', json)
            if(res.s === 'OK'){
                setDataObjeto(res.d.resultado);
                let newData = []
                let newDataDocente = [{
                    label: 'Todos',
                    value: 'todos'
                }]
                let totalDocentes = 0
                let totalAsignaciones = 0
                let totalCumplimiento = 0
                
                if(res.d){
                    let myDataCompleta = [...dataCompleta]
                    totalDocentes = res.d.resultado.length
                    res.d.resultado.forEach((docente) => {
                        totalAsignaciones += docente.cursos.length
                        let i = -1
                        myDataCompleta.forEach((reg,ind)=>{
                            if(reg.clave_docente === docente.clave_docente){
                                i = ind
                            }
                        })
                        let newAlcance = 0
                         docente.cursos.forEach(curso => {
                            newAlcance += curso.porcentaje_alcance
                        })
                        newAlcance = newAlcance/docente.cursos.length
                        totalCumplimiento += newAlcance
                        if(i < 0){
                            myDataCompleta.push(docente)
                            i = myDataCompleta.length - 1
                            myDataCompleta[i].cumplimiento = []
                            for(let j = 0; j < countComparadores+1; j++){
                               myDataCompleta[i].cumplimiento.push('-')
                            }
                        }
                        myDataCompleta[i].cumplimiento.push(newAlcance)
                        let newReg = [
                            myDataCompleta[i].clave_docente, 
                            myDataCompleta[i].nombre_docente, 
                            myDataCompleta[i].nivel, 
                        ]
                        myDataCompleta[i].cumplimiento.forEach(cumplido=>{
                            newReg.push(cumplido === '-' ? '-' : `${Math.round(cumplido * 100)/100}%`)
                        })
                        newReg.push(
                            <EditButton 
                                onClick={() => 
                                    openDetalleModal(myDataCompleta[i].clave_docente, myDataCompleta[i].nombre_docente, JSON.stringify(myDataCompleta[i].cursos))
                                }
                            >
                                    Detalle
                            </EditButton>
                        )

                        newReg.push(docente.cursos.length)
                        newReg.push(newAlcance)
                        newData.push(newReg)
                        newDataDocente.push({
                            value: myDataCompleta[i].clave_docente,
                            label: myDataCompleta[i].nombre_docente
                        })
                    })
                    myDataCompleta.forEach((docente) => {
                        totalAsignaciones += docente.cursos.length
                        let i = -1
                        res.d.resultado.forEach((reg,ind)=>{
                            if(reg.clave_docente === docente.clave_docente){
                                i = ind
                            }
                        })

                        if(i < 0){
                            let newAlcance = 0
                            docente.cursos.forEach(curso => {
                               newAlcance += curso.porcentaje_alcance
                            })
                            newAlcance = newAlcance/docente.cursos.length
                            totalCumplimiento += newAlcance
                            docente.cumplimiento.push('-')
                            let newReg = [
                                docente.clave_docente, 
                                docente.nombre_docente, 
                                docente.nivel, 
                            ]
                            docente.cumplimiento.forEach(cumplido=>{
                                newReg.push(cumplido === '-' ? '-' : `${Math.round(cumplido * 100)/100}%`)
                            })
                            newReg.push(
                                <EditButton 
                                    onClick={() => 
                                        openDetalleModal(docente.clave_docente,docente.nombre_docente, JSON.stringify(docente.cursos))
                                    }
                                >
                                        Detalle
                                </EditButton>
                            )
                            newReg.push(docente.cursos.length)
                            newReg.push(newAlcance)
                            newData.push(newReg)
                            newDataDocente.push({
                                value: docente.clave_docente,
                                label: docente.nombre_docente
                            })
                        }
                    })
                    setDataCompleta(myDataCompleta)
                }else{
                    setTableLoading(false)

                    enqueueSnackbar(res.m, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                    props.eliminarComparador(countComparadores+1)
                    props.loadingIndicadores(false)
                    return 
                    /*
                    let myDataCompleta = [...dataCompleta]
                    myDataCompleta.forEach((docente) => {
                        docente.cumplimiento.push('-')
                    
                        let newReg = [
                            docente.clave_docente, 
                            docente.nombre_docente, 
                            docente.nivel, 
                        ]
                        
                        docente.cumplimiento.forEach(cumplido=>{
                            newReg.push(cumplido === '-' ? '-' : `${Math.round(cumplido * 100)/100}%`)
                        })
                        newReg.push(
                            <EditButton 
                                onClick={() => 
                                    openDetalleModal(docente.clave_docente,docente.nombre_docente, JSON.stringify(docente.cursos))
                                }
                            >
                                    Detalle
                            </EditButton>
                        )
                        newReg.push(0)
                        newReg.push(0)
                        newData.push(newReg)
                        totalDocentes = 0
                    })*/
                }

                setCountComparadores(countComparadores + 1)
                props.loadingIndicadores(false)
                let actualizaHeaders = [...headers]
                if(actualizaHeaders.length === 5){
                    actualizaHeaders[3].name = `Cumplimiento Prom. Docente Año:${year.value} Ciclo: ${cycle.value} Sem:${week.value}`
                }
                actualizaHeaders[headers.length-1].name = `Cumplimiento Prom. Docente Año:${props.filtrosComparacion.year} Ciclo: ${props.filtrosComparacion.ciclo} Sem:${props.filtrosComparacion.semana}`

                actualizaHeaders.push({
                    name: 'Acciones',
                    col: headers.length
                })
                setHeaders(actualizaHeaders)

                setData(newData)
                setDataFinal(newData)
                console.log(newData)
                setDocentes(newDataDocente)
                props.setDocentes(newDataDocente)
                props.setData(newData)
                if(newData.length > 0){
                    let totalCursos = 0;
                    let totalCumplimiento = 0;
                    let divisor = 0
                    newData.forEach(row => {
                        if(row[row.length-4] !== '-'){
                            totalCursos += row[row.length-2];
                            totalCumplimiento += row[row.length-1];
                            divisor += 1
                        }
                        
                    })
                    totalCumplimiento = `${Math.round((totalCumplimiento/divisor)*100)/100}%`;
                    props.changeIndicadores(totalDocentes, totalCursos, totalCumplimiento,props.filtrosComparacion.year,props.filtrosComparacion.ciclo,props.filtrosComparacion.semana,props.filtrosComparacion.nivel,countComparadores+1);
                } else {
                    props.changeIndicadores(0, 0, '-',props.filtrosComparacion.year,props.filtrosComparacion.ciclo,props.filtrosComparacion.semana,props.filtrosComparacion.nivel,countComparadores+1)
                }
                setTableLoading(false)
            } else {
                enqueueSnackbar(res.m, STYLES.failure)
            }
        } catch (error){ console.log(error) }
    }

    useEffect(() => {
        if(nivel !== '' && props.firstTime) fetchData()
    }, [])

    useEffect(() => {
        if(!props.firstTime){
            if(reRender) fetchData()
            else setReRender(true)
        } else{
            props.setFirstTime(false)
        }
    }, [year, week, cycle])

    useEffect(() => {
        /*

        */
    }, [dataFinal]);

    useEffect(() => {
        let dataNueva = data.filter(row => {
            for(let i = 0; i < alcances.length; i++){
                let range = alcances[i].value.split('-')
                if(parseFloat(range[0]) <= parseFloat(row[3].split('%')[0]) && parseFloat(row[3].split('%')[0]) <= parseFloat(range[1])){
                    return true
                }
            }
            return false
        })

        if(docente.value !== 'todos'){
            dataNueva = dataNueva.filter(row => row[0] === docente.value)
        }
        setDataFinal(dataNueva)

    }, [alcances, docente])

    async function openDetalleModal(clave, nombre, cursos){
        setModalOpen(true)
        setModalLoading(true)
        setClaveModal(clave)
        setNombreModal(nombre)
        let tableData = JSON.parse(cursos)
        let dataNew = []
        if(tableData){
            dataNew = tableData.map(row => [
                row.clave,
                row.nombre,
                row.porcentaje_alcance + '%',
                <>
                    <Can I='read' a='reporte_listaverificacion'>
                        <EditButton onClick={() => {
                            context.actions.setState(cycle, nombre, nivel, clave, row.clave)
                            history.push('/reportes/lista')
                        }}>Lista Verificación</EditButton>
                    </Can>
                    <Can I='read' a='reporte_concentrado'>
                        <EditButton onClick={() => {
                            context2.actions.setState(cycle.value, nivel.value, nombre, clave)
                            history.push('/reportes/concentrado')
                        }} style={{marginLeft: '5px'}}>Reporte Concentrado</EditButton>
                    </Can>
                </>

            ])
        }
        setDataModal(dataNew)
        setModalLoading(false)
    }
    
    return(
        <>
            <div className='tab-filtros'>
                <p style={{fontWeight: 700}}>Filtrar por:</p>                
                <p>Año:</p>
                <Select 
                  isDisabled = {props.comparadoresQuantity > 0}
                  options={props.yearsFiltro}
                  className='select-search mas-ancho'
                  classNamePrefix='select-search'
                  value={year}
                  onChange={x => {
                      setYear(x)
                      props.setYear(x)
                  }}
                />    
                <p>Ciclo:</p>
                <Select 
                  isDisabled = {props.comparadoresQuantity > 0}
                  options={opcionesCycles}
                  className='select-search'
                  classNamePrefix='select-search'
                  value={cycle}
                  onChange={x => {
                      setCycle(x)
                      props.setCiclo(x)
                  }}
                />                
                <p>Nivel:</p>
                <Select 
                  isDisabled = {props.comparadoresQuantity > 0}
                  options={niveles}
                  className='select-search'
                  classNamePrefix='select-search'
                  value={nivel}
                  onChange={x => {
                      setNivel(x)
                      props.setNivel(x)
                  }}
                />
                <p>Semana:</p>
                <Select
                  isDisabled = {props.comparadoresQuantity > 0}
                  options={weeks}
                  className='select-weeks'
                  classNamePrefix='select-search'
                  value={week}
                  onChange={x => {
                      setWeek(x)
                      props.setSemana(x)
                  }}
                />
                <p>Docente:</p>
                <Select 
                  isDisabled = {props.comparadoresQuantity > 0}
                  options={docentes}
                  className='select-docente-largo'
                  classNamePrefix='select-search'
                  value={docente}
                  onChange={x => {
                      setDocente(x)
                      props.setDocente(x)
                  }}
                />
                <p>Cumplimiento:</p>
                <Select 
                  isDisabled = {props.comparadoresQuantity > 0}
                  options={alcancesSet}
                  className='select-alcance'
                  classNamePrefix='select-search'
                  components={{ Option, SelectContainer, MultiValue }}
                  isMulti
                  closeMenuOnSelect={false}
                  allowSelectAll
                  hideSelectedOptions={false}
                  value={alcances}
                  onChange={(array, action) => {
                    if(action.option.value === 'todos'){
                      if(action.action === 'deselect-option'){
                        setAlcances([])
                        props.setCumplimiento([])
                      } else {
                        setAlcances([
                          {
                            label: 'Seleccionar todos',
                            value: 'todos'
                          },
                          {
                            label: '0% - 10%',
                            value: '0-10'
                          },
                          {
                            label: '11% - 20%',
                            value: '11-20'
                          },
                          {
                            label: '21% - 30%',
                            value: '21-30'
                          },
                          {
                            label: '31% - 40%',
                            value: '31-40'
                          },
                          {
                            label: '41% - 50%',
                            value: '41-50'
                          },
                          {
                            label: '51% - 60%',
                            value: '51-60'
                          },
                          {
                            label: '61% - 70%',
                            value: '61-70'
                          },
                          {
                            label: '71% - 80%',
                            value: '71-80'
                          },
                          {
                            label: '81% - 90%',
                            value: '81-90'
                          },
                          {
                            label: '91% - 100%',
                            value: '91-100'
                          }
                        ])
                        props.setCumplimiento([
                          {
                            label: 'Seleccionar todos',
                            value: 'todos'
                          },
                          {
                            label: '0% - 10%',
                            value: '0-10'
                          },
                          {
                            label: '11% - 20%',
                            value: '11-20'
                          },
                          {
                            label: '21% - 30%',
                            value: '21-30'
                          },
                          {
                            label: '31% - 40%',
                            value: '31-40'
                          },
                          {
                            label: '41% - 50%',
                            value: '41-50'
                          },
                          {
                            label: '51% - 60%',
                            value: '51-60'
                          },
                          {
                            label: '61% - 70%',
                            value: '61-70'
                          },
                          {
                            label: '71% - 80%',
                            value: '71-80'
                          },
                          {
                            label: '81% - 90%',
                            value: '81-90'
                          },
                          {
                            label: '91% - 100%',
                            value: '91-100'
                          }
                        ])
                      }
                    } else {
                      setAlcances(array)
                      props.setCumplimiento(array)
                    }
                  }}
                />
            </div>
            <div style={{height: 'calc(100% - 70px)', margin: '0 20px'}}>
                <DataTables 
                  headers={headers} 
                  data={dataFinal.map(elem => elem.slice(0,elem.length-2))} 
                  loading={tableLoading} 
                  centrar={[3, 4, 5, 6, 7, 8, 9, 10, 11]} 
                />
            </div>
            <LargeModal title='Detalle Docente' open={modalOpen} loading={modalLoading} handleClose={() => setModalOpen(false)} height={400}>
                <div className='tab-filtros inputs-modal' style={{display: 'flex'}}>
                    <p>Clave:</p>
                    <input value={claveModal} readonly />
                    <p>Nombre:</p>
                    <input value={nombreModal} readonly />
                    <p>Semana:</p>
                    <input value={week.value} readonly style={{marginRight: '20px'}} />
                </div>
                <div style={{height: 'calc(100% - 100px)'}}>
                    <DataTables 
                        data={dataModal}
                        centrar={[3,4]}
                        headers={[
                            { col: 0, name: 'Clave de curso' },
                            { col: 1, name: 'Asignatura' },
                            { col: 2, name: 'Cumplimiento' },
                            { col: 3, name: 'Acción' }
                        ]}
                    />
                </div>
            </LargeModal>
        </>
    )
}