import { Ability, AbilityBuilder } from '@casl/ability'

export const ability = new Ability([])

export function setPermisos(permisos){
    const { can, rules } = AbilityBuilder.extract()

    permisos.forEach(p => {
      if(p.leer) can('read', p.funcionalidad)
      if(p.modificar) can('edit', p.funcionalidad)
      if(p.escribir) can('create', p.funcionalidad)
      if(p.eliminar) can('delete', p.funcionalidad)
    })
    return rules
}