import React, { useState, useEffect, useContext } from "react";
import "./index.css";
import DataTables from "../../Utils/DataTables";
import EditButton from "../../Utils/Botones/EditButton";
import { getRequest, postRequest } from "../../Utils/Funciones/requester";
import Select from "react-select";
import { components } from "react-select";
import { useHistory } from "react-router-dom";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { useSnackbar } from "notistack";
import moment from "moment";
import CursoContext from "../../Utils/Providers/CursoProvider";
import SearchInput from "../../Utils/Visuales/SearchInput";
import NivelesContext from "../../Utils/Providers/NivelesProvider";

const STYLES = {
  success: {
    variant: 'success',
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  },
  failure: {
    variant: 'error',
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  }
}

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
}

const SelectContainer = ({ children, ...props }) => {
  return (
    <div style={{ position: "relative" }}>
      <components.SelectContainer {...props}>
        <div className="select-alcance-abs">Seleccione Cumplimeinto...</div>
        {children}
      </components.SelectContainer>
    </div>
  );
}

const MultiValue = (props) => {
  return null;
}

export default function AlcanceAsignaciones(props) {
  const [headers, setHeaders] = useState([
    {
      name: "Clave del grupo",
      col: 0,
    },
    {
      name: "Asignatura",
      col: 1,
    },
    {
      name: "Nivel",
      col: 2,
    },
    {
      name: "Cumplimiento",
      col: 3,
    },
    {
      name: "Acciones",
      col: 4,
    },
  ])
  const [data, setData] = useState(props.data)
  const [dataFinal, setDataFinal] = useState(props.data)
  const [dataCompleta, setDataCompleta] = useState({})
  const [tableLoading, setTableLoading] = useState(false)
  const [niveles, setNiveles] = useState(
    useContext(NivelesContext).state.levels
  )
  const [nivel, setNivel] = useState(props.nivel)
  const [alcances, setAlcances] = useState(props.cumplimiento)
  const [countComparadores, setCountComparadores] = useState(0)
  const alcancesSet = [
    {
      label: "Seleccionar todos",
      value: "todos",
    },
    {
      label: "0% - 10%",
      value: "0-10",
    },
    {
      label: "11% - 20%",
      value: "11-20",
    },
    {
      label: "21% - 30%",
      value: "21-30",
    },
    {
      label: "31% - 40%",
      value: "31-40",
    },
    {
      label: "41% - 50%",
      value: "41-50",
    },
    {
      label: "51% - 60%",
      value: "51-60",
    },
    {
      label: "61% - 70%",
      value: "61-70",
    },
    {
      label: "71% - 80%",
      value: "71-80",
    },
    {
      label: "81% - 90%",
      value: "81-90",
    },
    {
      label: "91% - 100%",
      value: "91-100",
    },
  ]
  const [weeks, setWeeks] = useState([])
  const [week, setWeek] = useState(props.semana)
  const [docente, setDocente] = useState(props.docente)
  let history = useHistory()
  const [opcionesCycles, setOpcionesCycles] = useState([])
  const loadOpcionesCycle = async () => {
    if (opcionesCycles.length > 0) return
    try {
      let url = '/calendario/ciclo'
      const response = await getRequest(url)
      if (response.s === 'OK') {
        let cyclesArray = []
        response.d.sort((x, y) => {
          if (x < y) return -1
          if (x > y) return 1
          return 0
        })
        response.d.forEach((mycycle) => {
          cyclesArray.push({
            label: mycycle,
            value: mycycle,
          })
        })
        setOpcionesCycles(cyclesArray)
        setCycle(cyclesArray[0])
      }
    } catch (error) {
      console.error(error)
    }
  }

  const [cycle, setCycle] = useState()
  const [year, setYear] = useState(props.year)
  const [reRender, setReRender] = useState(props.firstTime ? true : false)

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const context = useContext(CursoContext)

  useEffect(() => {
    if (props.firstTime) {
    loadOpcionesCycle()
      if (niveles.length > 0) {
        setNivel(niveles[0])
        props.setNivel(niveles[0])
      } else {
        setData([])
        setDataFinal([])
        props.loadingIndicadores(false)
        setTableLoading(false)
      }
    }
  }, [])

  useEffect(() => {
    let nuevasSemanas = [];
    for (let i = 0; i < nivel.semanas; i++) {
      nuevasSemanas.push({
        value: i + 1,
        label: i + 1,
      });
    }
    setWeeks(nuevasSemanas);
    if (reRender) {
      setWeek(nuevasSemanas[0]);
      props.setSemana(nuevasSemanas[0]);
    }
  }, [nivel]);

  async function fetchData() {
    // console.log('[AlcanceAsignaciones | fetchData]')
    try {
      if (nivel !== "") {
        setTableLoading(true);
        props.loadingIndicadores(true);
        let json = {
          year: year.value,
          semana: week.value,
          nivel: nivel.value,
          ciclo: cycle.value,
        }
        if (docente.value !== "todos") {
          json.id_docente = docente.value;
        }
        let res = await postRequest("/reporte/alcance/materia", json);
        if (['ok', 'OK'].includes(res.s)) {
          let newData = []
          let totalDocentes = 0
          let alcanceMenor = []
          let alcanceMayor = []
          let alcancePromedio = 0
          if (res.d) {
            res.d.resultado.forEach((materia) => {
              if (materia.porcentaje_alcance > 100) {
                materia.porcentaje_alcance = 100
              }
              newData.push([
                materia.clave,
                materia.nombre,
                materia.nivel,
                `${materia.porcentaje_alcance}%`,
                <EditButton
                  onClick={() => {
                    context.actions.setState(
                      year.value,
                      cycle.value,
                      nivel.value,
                      week.value,
                      materia.clave_docente,
                      materia.clave
                    )
                    history.push('/reportes/curso')
                  }}
                >
                  Detalle
                </EditButton>,
                materia.porcentaje_alcance,
                materia.porcentaje_alcance,
              ])
            })
          }
          setHeaders([
            { col: 0, name: 'Clave del grupo' },
            { col: 1, name: 'Asignatura' },
            { col: 2, name: 'Nivel' },
            { col: 3, name: 'Cumplimiento' },
            { col: 4, name: 'Acciones' },
          ])
          setData(newData)
          setDataFinal(newData)
          props.setData(newData)
          setCountComparadores(0)
          calcularIndicadores(
            newData,
            year.value,
            cycle.value,
            week.value,
            nivel.value,
            0
          )
          setDataCompleta({
            [`Cumplimiento Año ${year.value} Ciclo ${cycle.value} Sem ${week.value}`]: res
              .d.resultado,
          })
          setTableLoading(false)
          props.loadingIndicadores(false)
        } else {
          enqueueSnackbar(res.m, STYLES.failure)
        }
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (!props.firstTime) {
      if (reRender) fetchData()
      else setReRender(true)
    } else {
      props.setFirstTime(false)
    }
  }, [year, week, niveles, docente, cycle])

  async function fetchComparacion() {
    try {
      if (nivel !== "") {
        setTableLoading(true);
        props.loadingIndicadores(true);
        let json = props.filtrosComparacion;
        if (docente.value !== "todos") {
          json.id_docente = docente.value;
        }
        let response = await postRequest("/reporte/alcance/materia", json);
        let cumplimientos = [];
        if (response.s === "ok" || response.s === "OK") {
          let newData = [];
          let testData = [];
          let newDataCompleta = { ...dataCompleta };
          let dataComprimida = {};
          if (response.d) {
            response.d.resultado.forEach((materia) => {
              if (materia.porcentaje_alcance > 100) {
                materia.porcentaje_alcance = 100;
              }
              newData.push([
                materia.clave,
                materia.nombre,
                materia.nivel,
                `${materia.porcentaje_alcance}%`,
                <EditButton
                  onClick={() => {
                    context.actions.setState(
                      moment(),
                      props.filtrosComparacion.ciclo,
                      props.filtrosComparacion.nivel,
                      props.filtrosComparacion.semana,
                      materia.clave_docente,
                      materia.clave
                    );
                    history.push("/reportes/curso");
                  }}
                >
                  Detalle
                </EditButton>,
                materia.porcentaje_alcance,
                materia.porcentaje_alcance,
              ]);
            });
            newDataCompleta[
              `Cumplimiento Año ${props.filtrosComparacion.year} Ciclo ${props.filtrosComparacion.ciclo} Sem ${props.filtrosComparacion.semana}`
            ] = response.d.resultado;
            Object.keys(newDataCompleta).forEach((key) => {
              let reg = newDataCompleta[key];
              if (!cumplimientos.includes(key)) {
                cumplimientos.push(key);
              }
              Object.keys(reg).forEach((key2) => {
                let reg2 = reg[key2];
                if (dataComprimida[reg2.clave.split("-")[0]]) {
                  if (dataComprimida[reg2.clave.split("-")[0]][key]) {
                    dataComprimida[reg2.clave.split("-")[0]][key].push(reg2);
                  } else {
                    dataComprimida[reg2.clave.split("-")[0]][key] = [reg2];
                  }
                } else {
                  dataComprimida[reg2.clave.split("-")[0]] = {};
                  dataComprimida[reg2.clave.split("-")[0]][key] = [reg2];
                }
              });
            });
            Object.keys(dataComprimida).forEach((key) => {
              let reg = dataComprimida[key];
              let line = [];
              line.push(key);
              line.push(reg[Object.keys(reg)[0]][0].nombre);
              line.push(reg[Object.keys(reg)[0]][0].nivel);
              let subAlcance = 0;
              cumplimientos.forEach((cumplimiento, i) => {
                let subCumplimiento = 0;
                subAlcance = 0;
                if (reg[cumplimiento]) {
                  reg[cumplimiento].forEach((porcentaje) => {
                    subCumplimiento += porcentaje.porcentaje_alcance;
                    subAlcance += porcentaje.promedio_alcance;
                  });
                  subCumplimiento = subCumplimiento / reg[cumplimiento].length;
                  subAlcance = (subAlcance / reg[cumplimiento].length).toFixed(
                    2
                  );
                }
                line.push(`${subCumplimiento.toFixed(2)}%`);
                if (cumplimientos.length - 1 === i) {
                  line.push(subCumplimiento);
                  line.push(subCumplimiento);
                }
              });
              testData.push(line);
            });
          } else {
            setTableLoading(false);

            enqueueSnackbar(response.m, {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
            props.eliminarComparador(countComparadores + 1);
            props.loadingIndicadores(false);
            return;
          }
          let newHeaders = [
            { col: 0, name: 'Clave legal' },
            { col: 1, name: 'Asignatura' },
            { col: 2, name: 'Nivel' },
          ]
          cumplimientos.forEach((cumplimiento, i) => {
            newHeaders.push({ name: cumplimiento, col: i + 2 });
          })
          setHeaders(newHeaders)
          setData(testData)
          setDataFinal(testData)
          props.setData(testData)
          setDataCompleta(newDataCompleta)
          setCountComparadores(countComparadores + 1)
          calcularIndicadores(
            newData,
            props.filtrosComparacion.year,
            props.filtrosComparacion.ciclo,
            props.filtrosComparacion.semana,
            props.filtrosComparacion.nivel,
            countComparadores + 1
          )
          setTableLoading(false)
          props.loadingIndicadores(false)
        } else {
          enqueueSnackbar(response.m, STYLES.failure)
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  function calcularIndicadores(
    dataToCalc,
    anio,
    ciclo,
    semana,
    level,
    posicion
  ) {
    if (dataToCalc.length > 0) {
      const calculo = dataToCalc[0].length;
      let alcanceCumplimiento = 0;
      let alcanceMenor = [dataToCalc[0][calculo - 1], dataToCalc[0][0]];
      let alcanceMayor = [dataToCalc[0][calculo - 1], dataToCalc[0][0]];
      let todosMenores = 0;
      let todosMayores = 0;
      dataToCalc.forEach((row) => {
        alcanceCumplimiento += row[calculo - 2];
        if (row[calculo - 1] > alcanceMayor[0]) {
          todosMayores = 1;
          alcanceMayor = [row[calculo - 1], row[0]];
        } else if (row[calculo - 1] === alcanceMayor[0]) {
          todosMayores += 1;
        }

        if (row[calculo - 1] < alcanceMenor[0]) {
          todosMenores = 1;
          alcanceMenor = [row[calculo - 1], row[0]];
        } else if (row[calculo - 1] === alcanceMenor[0]) {
          todosMenores += 1;
        }
      });
      alcanceCumplimiento = `${Math.round(
        (alcanceCumplimiento / dataToCalc.length) * 10
      ) / 10}%`;
      let comparativaComp = (
        <div className="contenedor-comparativa">
          <p className="contenedor-materia materia-left">
            Total:{todosMayores}
          </p>
          <span>{Math.round(alcanceMayor[0] * 10) / 10}%</span>
          <ArrowUpwardIcon />
          <div>/</div>
          <ArrowDownwardIcon />
          <span>{Math.round(alcanceMenor[0] * 10) / 10}%</span>
          <p className="contenedor-materia materia-right">
            Total:{todosMenores}
          </p>
        </div>
      );
      props.changeIndicadores(
        dataToCalc.length,
        comparativaComp,
        alcanceCumplimiento,
        anio,
        ciclo,
        semana,
        level,
        posicion
      );
    } else {
      if (cycle && week && nivel) {
        props.changeIndicadores(
          0,
          "-",
          "-",
          anio,
          ciclo,
          semana,
          level,
          posicion
        );
      }
    }
  }

  useEffect(() => {
    //calcularIndicadores(dataFinal,cycle.value,week.value,nivel.value,0)
  }, [dataFinal]);

  useEffect(() => {
    if (props.comparadoresQuantity > 1) {
      fetchComparacion();
    } else {
      if (props.comparadoresQuantity == 0 && props.data.length != 0) {
        fetchData();
      }
    }
  }, [props.comparadoresQuantity]);

  useEffect(() => {
    let dataNueva = data.filter((row) => {
      for (let i = 0; i < alcances.length; i++) {
        let range = alcances[i].value.split("-");
        if (
          parseFloat(range[0]) <= parseFloat(row[3].split("%")[0]) &&
          parseFloat(row[3].split("%")[0]) <= parseFloat(range[1])
        ) {
          return true;
        }
      }
      return false;
    });

    setDataFinal(dataNueva);
  }, [alcances, data]);
  /**/
  function search(text) {
    text = String(text).toLowerCase();
    let result = data.filter((row) => {
      if (row[0].toLowerCase().includes(text)) return true;
      else if (row[1].toLowerCase().includes(text)) return true;
      else if (row[2].toLowerCase().includes(text)) return true;
      else if (row[3].toLowerCase().includes(text)) return true;
      else if (row[4].toLowerCase().includes(text)) return true;

      return false;
    });
    setDataFinal(result);
  }

  return (
    <>
      <div className="tab-filtros">
        <p style={{ fontWeight: 700 }}>Filtrar por:</p>
        <p>Año:</p>
        <Select
          isDisabled={props.comparadoresQuantity > 0}
          options={props.yearsFiltro}
          className="select-search mas-ancho"
          classNamePrefix="select-search"
          value={year}
          onChange={(x) => {
            setYear(x);
            props.setYear(x);
          }}
        />
        <p>Ciclo:</p>
        <Select
          isDisabled={props.comparadoresQuantity > 0}
          options={opcionesCycles}
          className="select-search"
          classNamePrefix="select-search"
          value={cycle}
          onChange={(x) => {
            setCycle(x);
            props.setCiclo(x);
          }}
        />
        <p>Nivel:</p>
        <Select
          isDisabled={props.comparadoresQuantity > 0}
          options={niveles}
          className="select-search"
          classNamePrefix="select-search"
          value={nivel}
          onChange={(x) => {
            setNivel(x);
            props.setNivel(x);
          }}
        />
        <p>Semana:</p>
        <Select
          isDisabled={props.comparadoresQuantity > 0}
          options={weeks}
          className="select-weeks"
          classNamePrefix="select-search"
          value={week}
          onChange={(x) => {
            setWeek(x);
            props.setSemana(x);
          }}
        />
        <p>Docente:</p>
        <Select
          isDisabled={props.comparadoresQuantity > 0}
          options={props.docentes}
          className="select-docente-largo"
          classNamePrefix="select-search"
          value={docente}
          onChange={(x) => {
            setDocente(x);
            props.setDocente(x);
          }}
        />
        <p>Cumplimiento:</p>
        <Select
          isDisabled={props.comparadoresQuantity > 0}
          options={alcancesSet}
          className="select-alcance"
          classNamePrefix="select-search"
          components={{ Option, SelectContainer, MultiValue }}
          isMulti
          closeMenuOnSelect={false}
          allowSelectAll
          hideSelectedOptions={false}
          value={alcances}
          onChange={(array, action) => {
            if (action.option.value === "todos") {
              if (action.action === "deselect-option") {
                setAlcances([]);
                props.setCumplimiento([]);
              } else {
                setAlcances([
                  {
                    label: "Seleccionar todos",
                    value: "todos",
                  },
                  {
                    label: "0% - 10%",
                    value: "0-10",
                  },
                  {
                    label: "11% - 20%",
                    value: "11-20",
                  },
                  {
                    label: "21% - 30%",
                    value: "21-30",
                  },
                  {
                    label: "31% - 40%",
                    value: "31-40",
                  },
                  {
                    label: "41% - 50%",
                    value: "41-50",
                  },
                  {
                    label: "51% - 60%",
                    value: "51-60",
                  },
                  {
                    label: "61% - 70%",
                    value: "61-70",
                  },
                  {
                    label: "71% - 80%",
                    value: "71-80",
                  },
                  {
                    label: "81% - 90%",
                    value: "81-90",
                  },
                  {
                    label: "91% - 100%",
                    value: "91-100",
                  },
                ]);
                props.setCumplimiento([
                  {
                    label: "Seleccionar todos",
                    value: "todos",
                  },
                  {
                    label: "0% - 10%",
                    value: "0-10",
                  },
                  {
                    label: "11% - 20%",
                    value: "11-20",
                  },
                  {
                    label: "21% - 30%",
                    value: "21-30",
                  },
                  {
                    label: "31% - 40%",
                    value: "31-40",
                  },
                  {
                    label: "41% - 50%",
                    value: "41-50",
                  },
                  {
                    label: "51% - 60%",
                    value: "51-60",
                  },
                  {
                    label: "61% - 70%",
                    value: "61-70",
                  },
                  {
                    label: "71% - 80%",
                    value: "71-80",
                  },
                  {
                    label: "81% - 90%",
                    value: "81-90",
                  },
                  {
                    label: "91% - 100%",
                    value: "91-100",
                  },
                ]);
              }
            } else {
              setAlcances(array);
              props.setCumplimiento(array);
            }
          }}
        />
        <div className="search-input-desempeno-grupo">
          <SearchInput hideIcon search={search} />
        </div>
      </div>
      <div style={{ height: "calc(100% - 70px)", margin: "0 20px" }}>
        <DataTables
          headers={headers}
          data={dataFinal.map((elem) => elem.slice(0, elem.length - 2))}
          loading={tableLoading}
          centrar={[3, 4, 5, 6]}
        />
      </div>
    </>
  );
}
