import styled from 'styled-components'


export const Container = styled.div`
  display: flex;
`

export const Content = styled.div`
  height: calc(100vh - 90px);
  width: 100%;
  padding: 0px 30px;
  overflow: auto;
  text-align: justify;

  section {
    display: flex;
    flex-direction: column;
    justify-content: start;
    min-height: 30vh;
    margin: 1.5rem 0;
    padding: 2rem 0;

    h2{
      border-left: 0.3rem solid ${({ theme }) => '#026eb7'} ;
      margin: 1rem 1rem 2rem;
      padding-left: 1rem;
    }
  }

  h1{
    text-align: center;
    color: ${({ theme }) => '#026eb7'};
  }

  p{
    text-indent: 1rem;

  }

  img{
    align-self: center;
  }
  
  a{
    /* text-decoration: underline; */
    color: ${({ theme }) => '#026eb7'};
    cursor: help;
  }

  &::-webkit-scrollbar { -webkit-appearance: none }

  &::-webkit-scrollbar:vertical { width:10px }

  &::-webkit-scrollbar-button:increment,&::-webkit-scrollbar-button {
    display: none;
  } 

  &::-webkit-scrollbar:horizontal { height: 10px }

  &::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
    margin: 0px 20px;
  }

  &::-webkit-scrollbar-track { 
    border-radius: 10px
  }


`

export const MenuContainer = styled.div`
  display: ${({ show }) => show ? 'flex' : 'none'};
  position: fixed;
  justify-content: center;
  min-width: 100vw;
  overflow: auto;
  height: calc(100vh - 90px);
  padding-right: 15px;
  color: ${({ theme }) => 'gray'};
  background-color: ${({ theme }) => 'white'};

  @media (min-width: 720px) {
      position: relative;
      display: flex;
      min-width: 200px;
      box-shadow: 0 0 10px -2px;
  }

  &::-webkit-scrollbar { -webkit-appearance: none }

`

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => 'gray'};
  width: 100%;
  max-width: 350px;
  padding-bottom: 50px;
  
  ul{
    list-style-type: none;
      font-weight: 400;
      padding-left: 15px;
      margin-top: 0;
  }

  image{
    width: 90%;
    margin: 1rem auto;
  }

`

export const OptionDiv = styled.div`
  font-weight: 500;
  font-size: 18px;
  padding-top: 20px;
  
  a:link, a:visited, a:active {
    text-decoration:none;
    color: gray;
  }
`

export const OptionLi = styled.li`
  cursor: pointer;
  padding-top: 5px;
  color: ${({ selected, theme }) => selected && '#026eb7'};
  font-weight: ${({ selected }) => selected && '500'};
  /* font-weight: 500; */
  &:hover{
      color: ${({ theme }) => '#026eb7'};
  }
`

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  h4{
      margin: 0;
  }

  :hover{
      color: ${({ theme }) => '#026eb7'};
  }
`

export const MenuToogle = styled.div`
  position: fixed;
  bottom: 70px;
  right: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  z-index: 2000;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => 'gray'};
  box-shadow: 2px 2px 5px ${({ theme }) => 'gray'};
  font-size: 30px;
  background: white;
  cursor: pointer;   

  @media (min-width: 720px) {
      display: none;
  }
`