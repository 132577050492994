let URL_BASE =
  "https://onk7znm0gg.execute-api.us-east-1.amazonaws.com/test";
let URL_DUMMY = "https://onk7znm0gg.execute-api.us-east-1.amazonaws.com/test";
let URL_SESSION_OPENID =
  "https://yshmuj18vg.execute-api.us-east-1.amazonaws.com/test/sesion";
export const version = "1.20240818";

const setUrl = (url, dummy, session) => {
  // console.log({url})
  if(url.startsWith('http')) return url
  
  let host
  if (dummy) host = URL_DUMMY
  else if (session) host = URL_SESSION_OPENID
  else host = URL_BASE

  url =  host + url
  return url
}

export async function getRequest(
  ws,
  signal = new AbortController().signal,
  dummy = false,
  session = false
) {
  let token = localStorage.getItem("token_tv");
  
  let response = await fetch(setUrl(ws, dummy, session), {
    // mode: 'cors',
    headers: {
      tk: token,
    },
    signal: signal,
  });
  let data = await response.json();
  if (data.m === "token no existe o expirado") {
    localStorage.removeItem("token_tv");
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  } else {
    return data;
  }
}

export async function postRequest(
  ws,
  json = {},
  signal = new AbortController().signal,
  dummy = false
) {
  let token = localStorage.getItem("token_tv");
  let url = dummy ? URL_DUMMY : URL_BASE;
  let response = await fetch(url + ws, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      tk: token,
    },
    body: JSON.stringify(json),
    signal: signal,
  });
  let data = await response.json();
  if (data.m === "token no existe o expirado") {
    localStorage.removeItem("token_tv");
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  } else {
    return data;
  }
}

export async function postLogin(ws, json = {}) {
  let response = await fetch(URL_BASE + ws, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(json),
  });
  let data = await response.json();
  if (data.m === "token no existe o expirado") {
    localStorage.removeItem("token_tv");
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  } else {
    return data;
  }
}

export async function putRequest(url, file) {
  let token = localStorage.getItem("token_tv");
  await fetch(url, {
    method: "PUT",
    body: file,
    headers: {
      "Content-Type": "image/png",
      "Content-Disposition": "inline",
      tk: token,
    },
  });
  return { status: "OK" };
}
