import React from "react";

const AsignacionesIcon = (props) => (
  <svg viewBox='0 0 46.62 51.42' {...props}>
    <defs>
      <style>{".AsignacionesIcon_svg__cls-1{fill:#27215f}"}</style>
    </defs>
    <g id='AsignacionesIcon_svg__Layer_2' data-name='Layer 2'>
      <g id='AsignacionesIcon_svg__Capa_1' data-name='Capa 1'>
        <path
          className='AsignacionesIcon_svg__cls-1'
          d='M38.09 33.51l-3 3.05a.76.76 0 01-.32.22l-7.56 2.5h4.65a1 1 0 110 1.92H6.26a1 1 0 110-1.92h20c-.08 0-.16-.09-.23-.16a.94.94 0 01-.23-1l.58-1.75H6.26a1 1 0 110-1.92H27l1.36-4.07a.81.81 0 01.19-.35l9.52-9.55V9.73h-8.64a.91.91 0 01-1-.91V0H0v51.42h38.09zM6.26 29.68h13.58a1 1 0 010 1.92H6.26a1 1 0 110-1.92zM31.85 46H6.26a1 1 0 110-1.92h25.59a1 1 0 110 1.92z'
        />
        <path
          className='AsignacionesIcon_svg__cls-1'
          d='M30.4 1.42v6.39h6.43zM28.23 37l4.45-1.49-3-3zM34.45 34.53l12.17-12.2-3.77-3.77-12.19 12.18z'
        />
      </g>
    </g>
  </svg>
);

export default AsignacionesIcon;
