import React from "react";

const DocentesDashIcon = (props) => (
  <svg viewBox='0 0 47.02 47.02' {...props}>
    <g data-name='Layer 2'>
      <path
        d='M0 5.22v36.57A5.24 5.24 0 005.22 47h36.57A5.24 5.24 0 0047 41.79V5.22A5.24 5.24 0 0041.79 0H5.22A5.24 5.24 0 000 5.22zm31.34 10.45a7.7 7.7 0 01-7.84 7.84 7.7 7.7 0 01-7.84-7.84 7.7 7.7 0 017.84-7.84 7.7 7.7 0 017.84 7.84zm-23.5 20.9c0-5.22 10.45-8.1 15.67-8.1s15.67 2.87 15.67 8.1v2.61H7.84z'
        fill='#27215f'
        data-name='Capa 1'
      />
    </g>
  </svg>
);

export default DocentesDashIcon;
