import React, { useState } from "react";
import Logo from "../../../images/unitec-en-linea.jpeg";
import "./index.css";
import pnd from "../../../images/pnd.png";
import NewModal from "../NewModal";
import { postRequest } from "../../Funciones/requester";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function Header(props) {
  const [modalSesion, setModalSesion] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  async function logout() {
    setModalLoading(true);
    let response = await postRequest("/logout");
    localStorage.removeItem("token_tv");
    window.location.reload();
  }

  return (
    <header>
      <div className='logo'>
        <img src={Logo} alt='Logo UVM' />
      </div>
      <div className='page-title'>
        <h1>Sistema de Lista de Verificación</h1>
      </div>
      <div className='user-info'>
        <div>
          <p className='user-name'>{props.username}</p>
          <p className='user-rol'>{props.rol}</p>
        </div>
        {props.image ? (
          <img onClick={() => setModalSesion(true)} src={props.image} />
        ) : (
          <img src={pnd} onClick={() => setModalSesion(true)} />
        )}
      </div>
      <NewModal
        title='Cerrar sesión'
        open={modalSesion}
        handleClose={() => setModalSesion(false)}
        handleSubmit={logout}
      >
        <div
          style={{ height: "80px", textAlign: "center", paddingTop: "20px" }}
        >
          {modalLoading ? (
            <CircularProgress />
          ) : (
            <p style={{ textAlign: "center" }}>
              ¿Está seguro de que desea cerrar sesión?
            </p>
          )}
        </div>
      </NewModal>
    </header>
  );
}
