const general = {
  description: `
    El Sistema de Lista de Verificacion es un Checklist de Criterios, estos criterios equivalen a determinadas actividades que tiene que realizar el docente en el periodo en que imparte un curso, estás actividades se verifican consultando la Base de Datos de la aplicacion donde impartan las clases (Blackboard).

    <h4> ALGUNAS CARACTERÍSTICAS</h4>
    <ul>
      <li> Calendario por semanas </li>
      <li> Cada criterio se ejecuta dependiendo de unas fechas </li>
      <li> Los Criterios se aplican sobre Cursos </li>
      <li> Un docente puede estar en varios Cursos </li>
      <li> La confifuracion del Curso tiene una caracteristica que pueden ser Ultra o Tradicionales </li>
      <li> Los Ultra se identifica por medio de una propiedad "is_ultra" </li>
    </ul>
    
    
    Muchos de los Criterios comparten consultas en comun, que solo varian en la la palabra que están buscando, este es el caso de aquellos criterios que intentan identificar acciones relacionadas con anuncios o archivos: 

    <ul>
      <li> Criterios con Búsqueda de Anuncio: 002 - 003 - 006 - 007 - 009 - 011 - 012 - 013 - 018 - 019 </li>
      <li> Criterios con Búsqueda de Archivo: 004 - 005 - 010 </li>
    </ul>

    <h4>BASE DE DATOS</h4>
    <ul>
      <li> cursorMsdb: Donde se encuentra la configuracion de los criterios con su calendario SQLSERVER
        <ul> <li> CRITERIA, CRITERIA_GUIDE, CRITERIA_RESULT, LEVEL, LEVEL_SCHEDULE, TEACHER, TEACHER_ASSIGNMENT </li> </ul>
      </li>

      <li> cursorOracle: La Base de Datos de Blackboard POSTGRE
        <ul> <li> conference_main, forum_main, msg_main, users, course_main, course_msg... </li> </ul>
      </li>
    </ul>

  `,
  busquedaAnuncio: {
    key: 'busquedaAnuncio',
    name: 'Búsqueda de Anuncio',
    description: 'Busca los anuncios realizados por un docente en un curso que dentro de un rango de tiempo coincidan en su titulo con palabras claves de las búsquedas',
    versions: [
      {
        name: 'ultra',
        fields: [
          { id:1, name: 'subject', table: 'announcements', alias: 'sql_like_pr (LOWER(subject) LIKE LOWER("")' },
          { id:2, name: 'announcement_type', table: 'announcements', alias: 'Anuncios tipo "C"' },
          { id:3, name: 'user_id', table: 'users', alias: 'docente' },
          { id:4, name: 'course_id', table: 'course_main', alias: 'curso' },
          { id:5, name: 'start_date', table: 'announcements', alias: 'evaluacion_fecha_inicio' },
          { id:6, name: 'start_date', table: 'announcements', alias: 'evaluacion_fecha_fin' },
        ],
        where: [
          { name: '', var: 'sql_like_pr' },
          { name: 'docente', var: 'docente' },
          { name: 'curso', var: 'curso' },
          { name: 'Fecha Inicio', var: 'evaluacion_fecha_inicio' },
          { name: 'Fecha Fin', var: 'evaluacion_fecha_fin' },
        ],
        query: ({ 
          sql_like_pr = '{sql_like_pr}', 
          docente = '{docente}', 
          curso = '{curso}', 
          evaluacion_fecha_inicio = '{evaluacion_fecha_inicio}', 
          evaluacion_fecha_fin = '{evaluacion_fecha_fin}'
        }) => `
          SELECT ann.announcement, ann.subject, users.pk1, ann.start_date, ann.end_date, user_id
          FROM announcements ann 
          JOIN course_main cm ON (ann.crsmain_pk1=cm.pk1) 
          JOIN users ON (users.pk1=ann.users_pk1)
          WHERE ann.announcement_type='C' 
            AND (${sql_like_pr}) 
            AND user_id='${docente}'  
            AND cm.course_id='${curso}'
            AND CAST(ann.start_date AS timestamp) BETWEEN CAST('${evaluacion_fecha_inicio}' AS timestamp) 
                AND CAST('${evaluacion_fecha_fin}' AS timestamp)
        `,
        observation: `
          Busca los anuncios de un docente en un curso realizados dentro de un rango de fecha donde su announcements.subject (titulo) coincida con las palabras reservadas, o palabras a buscar para determinado Criterio.
          Luego se hace una búsqueda de los anuncios sin evaluar el rango de fecha y el subject para ser mostrado por consola y por el Log a modo de que se puede observar cuales es su total anuncios y validar el veredicto dado.`
      },
      {
        name: 'tradicional',
        fields: [
          { id:1, name: 'subject', table: 'announcements', alias: 'sql_like_pr (LOWER(subject) LIKE LOWER("")' },
          { id:2, name: 'announcement_type', table: 'announcements', alias: 'Anuncios tipo "C"' },
          { id:3, name: 'user_id', table: 'users', alias: 'docente' },
          { id:4, name: 'course_id', table: 'course_main', alias: 'curso' },
          { id:5, name: 'start_date', table: 'announcements', alias: 'evaluacion_fecha_inicio' },
          { id:6, name: 'start_date', table: 'announcements', alias: 'evaluacion_fecha_fin' },
        ],
        where: [
          { name: '', var: 'sql_like_pr' },
          { name: 'docente', var: 'docente' },
          { name: 'curso', var: 'curso' },
          { name: 'Fecha Inicio', var: 'evaluacion_fecha_inicio' },
          { name: 'Fecha Fin', var: 'evaluacion_fecha_fin' },
        ],
        query: (
          sql_like_pr = '{sql_like_pr}', 
          docente = '{docente}', 
          curso = '{curso}', 
          evaluacion_fecha_inicio = '{evaluacion_fecha_inicio}', 
          evaluacion_fecha_fin = '{evaluacion_fecha_fin}'
        ) => `
          SELECT ann.announcement,ann.subject, users.pk1, ann.start_date, ann.end_date, user_id
          FROM announcements ann 
          JOIN course_main cm ON (ann.crsmain_pk1=cm.pk1) 
          JOIN users on (users.pk1=ann.users_pk1)
          WHERE ann.announcement_type='C' 
            AND '${sql_like_pr}'
            AND USER_ID='${docente}'  
            AND cm.course_id='${curso}'
            AND CAST(ann.start_date AS timestamp) BETWEEN CAST('${evaluacion_fecha_inicio}' AS timestamp) 
              AND CAST('${evaluacion_fecha_fin}' AS timestamp)
        `,
        observation: `
          Busca los anuncios de un docente en un curso realizados dentro de un rango de fecha donde su announcements.subject (titulo) coincida con las palabras reservadas, o palabras a buscar para determinado Criterio.
          Luego se hace una búsqueda de los anuncios sin evaluar el rango de fecha y el subject para ser mostrado por consola y por el Log a modo de que se puede observar cuales es su total anuncios y validar el veredicto dado.`
      }
    ],
    observation: `Funciona del mismo modo para ambas versiones`
  },
  busquedaArchivo: {
    key: 'busquedaArchivo',
    name: 'Búsqueda de Archivo',
    description: '',
    versions: [
      {
        name: 'ultra',
        fields: [
          { id:1, name: 'title', table: 'course_contents', alias: 'sql_like_pr_title (LIKE)' },
          { id:2, name: 'title', table: 'course_contents', alias: 'sql_like_pr_main_data (LIKE)' },
          { id:3, name: 'cnthndlr_handle', table: 'course_contents', alias: "'resource/x-bb-file', 'resource/x-bb-document'" },
          { id:4, name: 'user_id', table: 'users', alias: 'docente' },
          { id:5, name: 'course_id', table: 'course_main', alias: 'curso' },
          { id:6, name: 'dtcreated', table: 'course_contents', alias: 'evaluacion_fecha_inicio' },
          { id:7, name: 'dtcreated', table: 'course_contents', alias: 'evaluacion_fecha_fin' },
        ],
        where: [
          { name: '', var: 'sql_like_pr_title' },
          { name: '', var: 'sql_like_pr_main_data' },
          { name: 'docente', var: 'docente' },
          { name: 'curso', var: 'curso' },
          { name: 'Fecha Inicio', var: 'evaluacion_fecha_inicio' },
          { name: 'Fecha Fin', var: 'evaluacion_fecha_fin' },
        ],
        query: (
          sql_like_pr_title = '{sql_like_pr_title}', 
          sql_like_pr_main_data = '{sql_like_pr_main_data}', 
          docente = '{docente}', 
          curso = '{curso}', 
          evaluacion_fecha_inicio = '{evaluacion_fecha_inicio}', 
          evaluacion_fecha_fin = '{evaluacion_fecha_fin}'
        ) => `
          SELECT cc.pk1 
          FROM course_contents AS cc
          JOIN course_main AS cm ON cc.crsmain_pk1=cm.pk1 
          JOIN course_users AS cu ON (cm.pk1=cu.crsmain_pk1 AND cu.role='UNI_PROF')  
          JOIN users AS u ON cu.users_pk1=u.pk1
          WHERE cc.cnthndlr_handle IN ('resource/x-bb-file', 'resource/x-bb-document')
            AND ( ${sql_like_pr_title} OR ${sql_like_pr_main_data} ) 
            AND u.user_id='${docente}'  
            AND cm.course_id='${curso}' 
            AND CAST(cc.dtcreated AS timestamp) BETWEEN CAST('${evaluacion_fecha_inicio}' AS timestamp) 
              AND CAST('${evaluacion_fecha_fin}' AS timestamp)
        `,
        observation: `Busca los archivos de un docente en un curso realizados dentro de un rango de fecha donde su course_contents.title coincida con las palabras reservadas, o palabras a buscar para determinado Criterio.
        Luego se hace una búsqueda de los archivos sin evaluar el rango de fecha y el title para ser mostrado por consola y por el Log a modo de que se puede observar cuales es su total de archivos y validar el veredicto dado.`
      },
      {
        name: 'tradicional',
        fields: [
          { id:1, name: 'title', table: 'course_contents', alias: 'sql_like_pr_title (LIKE)' },
          { id:2, name: 'title', table: 'course_contents', alias: 'sql_like_pr_main_data (LIKE)' },
          { id:3, name: 'cnthndlr_handle', table: 'course_contents', alias: "'resource/x-bb-file'" },
          { id:4, name: 'user_id', table: 'users', alias: 'docente' },
          { id:5, name: 'course_id', table: 'course_main', alias: 'curso' },
          { id:6, name: 'dtcreated', table: 'course_contents', alias: 'evaluacion_fecha_inicio' },
          { id:7, name: 'dtcreated', table: 'course_contents', alias: 'evaluacion_fecha_fin' },
        ],
        where: [
          { name: '', var: 'sql_like_pr_title' },
          { name: '', var: 'sql_like_pr_main_data' },
          { name: 'docente', var: 'docente' },
          { name: 'curso', var: 'curso' },
          { name: 'Fecha Inicio', var: 'evaluacion_fecha_inicio' },
          { name: 'Fecha Fin', var: 'evaluacion_fecha_fin' },
        ],
        query: (
          sql_like_pr_title = '{sql_like_pr_title}', 
          sql_like_pr_main_data = '{sql_like_pr_main_data}', 
          docente = '{docente}', 
          curso = '{curso}', 
          evaluacion_fecha_inicio = '{evaluacion_fecha_inicio}', 
          evaluacion_fecha_fin = '{evaluacion_fecha_fin}'
        ) => `
          SELECT cc.pk1 
          FROM course_contents AS cc
          JOIN course_main AS cm ON cc.crsmain_pk1=cm.pk1 
          JOIN course_users AS cu ON (cm.pk1=cu.crsmain_pk1 AND cu.role='UNI_PROF')  
          JOIN users AS u ON cu.users_pk1=u.pk1
          WHERE cc.cnthndlr_handle IN ('resource/x-bb-folder')
            AND ( ${sql_like_pr_title} OR ${sql_like_pr_main_data} ) 
            AND u.user_id='${docente}'  
            AND cm.course_id='${curso}' 
            AND CAST(cc.dtcreated AS timestamp) BETWEEN CAST('${evaluacion_fecha_inicio}' AS timestamp) 
              AND CAST('${evaluacion_fecha_fin}' AS timestamp)
        `,
        observation: `Busca los archivos de un docente en un curso realizados dentro de un rango de fecha donde su course_contents.title coincida con las palabras reservadas, o palabras a buscar para determinado Criterio.
        Luego se hace una búsqueda de los archivos sin evaluar el rango de fecha y el title para ser mostrado por consola y por el Log a modo de que se puede observar cuales es su total de archivos y validar el veredicto dado.`
      }
    ],
    observation: `Funcionan muy parecido, solo que el tradicional no incluye en su filtro buscar tambien el valor "resource/x-bb-document" en el campo course_contents.title sino que solo filtra "resource/x-bb-folder" `
  },
}


const criterios = [
  { key: '000'},
  { key: '001',
    name: 'Configuró su perfil, colocó una fotografía de su rostro y su nombre (completo o con grado)',
    description: 'Verifica que el docente tenga nombre e imagen de perfil',
    versions: [
      {
        name: 'ultra',
        fields: [
          { id:1, name: 'firstname', table: 'users', alias: 'full_name (junto a lastname)' },
          { id:2, name: 'lastname', table: 'users', alias: 'full_name (junto a firstname)' },
          { id:3, name: 'cld_avatar_url', table: 'users', alias: 'url_avatar' },
          { id:4, name: 'avatar_type', table: 'users', alias: 'avatar_type' }
        ],
        where: [{ name: 'USER_ID', var: 'docente' }],
        query: ({ docente = '{docente}' }) => `
          SELECT PK1, USER_ID, STUDENT_ID, 
              firstname || ' ' || lastname as full_name,
              cld_avatar_url , avatar_type 
          FROM users 
          WHERE USER_ID='${docente}'
        `,
        observation: `Que [firstname + lastname] y cld_avatar_url tengan valor, que avatar_type sea distinto de 'U'`
      },
      {
        name: 'tradicional',
        fields: [
          { id:1, name: 'firstname', table: 'users', alias: 'full_name (junto a lastname)' },
          { id:2, name: 'lastname', table: 'users', alias: 'full_name (junto a firstname)' },
          { id:3, name: 'cld_avatar_url', table: 'users', alias: 'url_avatar' },
        ],
        where: [{ name: 'USER_ID', var: 'docente' }],
        query: ({ docente = '{docente}' }) => `
          SELECT PK1, USER_ID, STUDENT_ID,
            firstname || ' ' || lastname as full_name,
            cld_avatar_url 
          FROM users 
          WHERE USER_ID='${docente}'
        `,
        observation: `Que [firstname + lastname] y cld_avatar_url tengan valor, NO verifica el avatar_type`
      }
    ],
    observation: `PARECIDOS - Se valida que los campos del nombre y foto de perfil no esten vacios pero la version Tradicional no verifica el avatar_type, las validaciones que tienen en comun solo varian en que se hacen con distinto algoritmo. criterio_tradicional solo valida que existe el docente no evalua los campos`
  },
  { key: '002',
    name: 'Publicó el anuncio de bienvenida escrito',
    description: 'Este Criterio se basa en "Búsqueda de Anuncio" con el valor de: <b>%bienvenida%docente%</b>',
    isAnnouncement: true,
    isArchive: false,
    observation: ``
  },
  { key: '003',
    name: 'Publicó el anuncio de bienvenida video',
    description: 'Este Criterio se basa en "Búsqueda de Anuncio" con el valor de: <b> %video% </b>',
    isAnnouncement: true,
    isArchive: false,
    observation: ``
  },
  { key: '004',
    name: 'Publicó carta descriptiva',
    description: 'Este Criterio se basa en "Búsqueda de Archivo" con el valor de: <b> %carta%descriptiva% </b>',
    isAnnouncement: false,
    isArchive: true,
    observation: ``
  },
  { key: '005',
    name: 'Publicó el podcast semanal	',
    description: 'Este Criterio se basa en "Búsqueda de Archivo" con el valor de: <b> %podcast%semana% </b>',
    isAnnouncement: false,
    isArchive: true,
    observation: ``
  },
  { key: '006',
    name: 'Publicó las indicaciones para trabajar el Examen Diagnóstico a más tardar el día lunes	',
    description: 'Este Criterio se basa en "Búsqueda de Anuncio" con el valor de: <b> %examen%Diagn% </b>',
    isAnnouncement: true,
    isArchive: false,
    observation: ``
  },
  { key: '007',
    name: 'Publicó anuncio invitando a sus estudiantes a la sesión síncrona',
    description: 'Este Criterio se basa en "Búsqueda de Anuncio" con el valor de: <b> %sesi%ncrona% </b>',
    isAnnouncement: true,
    isArchive: false,
    observation: ``
  },
  { key: '008',
    name: 'Atendió los mensajes en máximo 24 hrs.',
    description: 'Revisa si los mensajes de Foro y los mensajes de Curso han tenido respuesta y ademas si fue en menos de 2 dias',
    versions: [
      {
        name: 'ultra',
        fields: [
          { id:1,  name: 'user_id', table: 'users', alias: 'docente' },
          { id:2,  name: 'course_id', table: '', alias: 'curso' },
          { id:3,  name: 'name', table: 'forum_main', alias: 'palabra_reservada' },
          { id:4,  name: 'posted_date', table: 'msg_main', alias: 'fechas de Incio y Fin' },
          { id:5,  name: 'sent_date', table: 'course_msg_participant', alias: 'fechas de Incio y Fin' },  
          { id:6,  name: 'pk1', table: 'forum_main', alias: 'id_foro' },  
          { id:7,  name: 'name', table: 'forum_main', alias: 'nombre_foro' },  
          { id:8,  name: 'subject', table: 'msg_main', alias: 'asunto' },  
          { id:9,  name: 'thread_pk1', table: 'msg_main', alias: 'id_hilo' },  
          { id:10, name: 'pk1', table: 'msg_main', alias: 'id_msg_alumno' },  
          { id:11, name: 'posted_date', table: 'msg_main', alias: 'fecha_mensaje_alumno' },  
          { id:12, name: 'user_id', table: 'users', alias: 'id_alumno' },  
          { id:13, name: 'firstname', table: 'users', alias: 'nombre_alumno' },  
          { id:14, name: 'lastname', table: 'users', alias: 'apellido_alumno' },  
          { id:15, name: 'msg_text', table: 'msg_main', alias: 'msg_alumno' },  
          { id:16, name: 'pk1', table: 'doc_msg_main', alias: 'id_msg_docente' },  
          { id:17, name: 'posted_date', table: 'doc_msg_main', alias: 'fecha_msg_docente' },
          { id:18, name: 'msg_text', table: 'doc_msg_main', alias: 'msg_doc' },  
          { id:19, name: 'msg_main.posted_date', table: 'doc_msg_main.posted_date', alias: 'diferencia_en_dias (docente-alumno)' },  
          { id:20, name: 'subject', table: 'course_msg', alias: 'asunto' },
        ],
        where: [
          { name: 'user_id', var: 'docente' },
          { name: 'course_id', var: 'curso' },
          { name: 'Palabra Reservada', var: 'palabra_reservada' },
          { name: 'Fecha Inicio Evaluacion', var: 'evaluacion_fecha_inicio' },
          { name: 'Fecha Fin Evaluacion', var: 'evaluacion_fecha_fin' },
        ],
        query: ({
          docente = '{docente}',
          curso = '{curso}',
          palabra_reservada = '{palabra_reservada}',
          evaluacion_fecha_inicio = '{evaluacion_fecha_inicio}',
          evaluacion_fecha_fin = '{evaluacion_fecha_fin}'
        }) => `
          SELECT f_main.pk1 foro_pk1, f_main.name nombre_foro, msg_main.subject subject, 
            msg_main.thread_pk1 hilo, msg_main.pk1 id_msg_alumno , msg_main.posted_date fecha_mensaje_alumno, 
            users.user_id id_alumno, users.firstname nombre_alumno, users.lastname apellido_alumno, 
            msg_main.msg_text msg_alumno, doc_msg_main.pk1 id_msg_docente, msg_main.posted_date fecha_mensaje_docente, 
            doc.user_id as id_docente, doc.firstname nombre_docente, doc.lastname apellido_docente, 
            doc_msg_main.msg_text msg_docente, doc_msg_main.posted_date-msg_main.posted_date dif_fechas
          FROM conference_main conf_main 
          JOIN course_main crs_main ON (conf_main.crsmain_pk1=crs_main.pk1)
          JOIN forum_main f_main ON (conf_main.pk1=f_main.confmain_pk1)
          JOIN msg_main msg_main  
            ON (msg_main.forummain_pk1=f_main.pk1 
                AND msg_main.users_pk1!=(SELECT pk1 FROM users u WHERE u.user_id='${docente}') )
          JOIN users users ON (msg_main.users_pk1=users.pk1)
          LEFT JOIN msg_main doc_msg_main 
            ON (doc_msg_main.forummain_pk1=f_main.pk1  
                AND doc_msg_main.users_pk1=(SELECT pk1 FROM users u WHERE u.user_id='${docente}') 
                AND doc_msg_main.thread_pk1=msg_main.thread_pk1 
                AND doc_msg_main.msgmain_pk1=msg_main.pk1 )
          LEFT JOIN users doc ON (doc_msg_main.users_pk1=doc.pk1)
          WHERE course_id='${curso})' 
            AND LOWER(f_main.name) LIKE LOWER('${palabra_reservada}') 
            AND CAST(msg_main.posted_date AS timestamp) BETWEEN CAST('${evaluacion_fecha_inicio}' AS timestamp) 
            AND CAST('${evaluacion_fecha_fin}' AS timestamp);


          SELECT course_msg.pk1 pk1, to_char(sent_date,'yyyy-mm-dd HH24:MI')  
            fecha_enviado,u.user_id sender,course_msg.subject asunto, 
            CAST(course_msg.receivers as varchar(100000)) recibidos  
          FROM course_msg_participant crs_msg_part 
          JOIN course_msg course_msg 
            ON (crs_msg_part.course_msg_pk1=course_msg.pk1 
                AND crs_msg_part.users_pk1=( SELECT pk1 FROM users WHERE user_id='${docente}')) 
          JOIN course_main crs_main on (course_msg.crsmain_pk1=crs_main.pk1)
          JOIN users u on (u.pk1= course_msg.sender_users_pk1)
          WHERE course_id='${curso}' 
            AND cast(sent_date as timestamp) between cast('${evaluacion_fecha_inicio}' as timestamp) 
              AND cast('${evaluacion_fecha_fin}' as timestamp)
            AND u.user_id!='${docente}'
          UNION
          SELECT  course_msg.pk1 pk1,to_char(sent_date,'yyyy-mm-dd HH24:MI')  
            fecha_enviado,u.user_id sender,course_msg.subject asunto, 
            CAST(course_msg.receivers as varchar(100000)) recibidos 
          FROM course_msg_participant crs_msg_part 
          JOIN course_msg course_msg on (crs_msg_part.course_msg_pk1=course_msg.pk1 )
          JOIN course_main crs_main on (course_msg.crsmain_pk1=crs_main.pk1)
          JOIN users u on (u.pk1= course_msg.sender_users_pk1)
          WHERE course_id='${curso}' 
            AND cast(sent_date as timestamp) between cast('${evaluacion_fecha_inicio}' as timestamp) 
            AND cast('${evaluacion_fecha_fin}' as timestamp)
            AND u.user_id='${docente})'
          order by fecha_enviado ASC 
        `,
        observation: `<ul>
          <li> Busca en la tabla CRITERIA cuál es la Palabra Reservada. En este caso es "duda" </li>
          <li> "dif_fechas" es la diferencia entre el msg_main.posted_date del alumno y el docente</li>
          <li> Si el mensaje no tiene valor en "dif_fechas" se marca como no respondido </li>
          <li> Si el mensaje tiene valor en "dif_fechas" entonces se valida que la diferencia sea menor a 2 </li>
          <li> Evalua tanto los mensajes de Foro (1ra Query) como los de Curso (2da Query) </li>
          <li> Calcula el total de Mensajes menos los mensajes sin responder para obtener los Respondidos </li>
          <li> El Criterio es válido si fueron respondidos mas del 70% de los mensajes  </li>
        </ul>`
      },
      {
        name: 'tradicional',
        fields: [
          { id:1,  name: 'user_id', table: 'users', alias: 'docente' },
          { id:2,  name: 'course_id', table: '', alias: 'curso' },
          { id:3,  name: 'name', table: 'forum_main', alias: 'palabra_reservada' },
          { id:4,  name: 'posted_date', table: 'msg_main', alias: 'fechas de Incio y Fin' },
          { id:5,  name: 'sent_date', table: 'course_msg_participant', alias: 'fechas de Incio y Fin' },  
          { id:6,  name: 'pk1', table: 'forum_main', alias: 'id_foro' },  
          { id:7,  name: 'name', table: 'forum_main', alias: 'nombre_foro' },  
          { id:8,  name: 'subject', table: 'msg_main', alias: 'asunto' },  
          { id:9,  name: 'thread_pk1', table: 'msg_main', alias: 'id_hilo' },  
          { id:10, name: 'pk1', table: 'msg_main', alias: 'id_msg_alumno' },  
          { id:11, name: 'posted_date', table: 'msg_main', alias: 'fecha_mensaje_alumno' },  
          { id:12, name: 'user_id', table: 'users', alias: 'id_alumno' },  
          { id:13, name: 'firstname', table: 'users', alias: 'nombre_alumno' },  
          { id:14, name: 'lastname', table: 'users', alias: 'apellido_alumno' },  
          { id:15, name: 'msg_text', table: 'msg_main', alias: 'msg_alumno' },  
          { id:16, name: 'pk1', table: 'doc_msg_main', alias: 'id_msg_docente' },  
          { id:17, name: 'posted_date', table: 'doc_msg_main', alias: 'fecha_msg_docente' },
          { id:18, name: 'msg_text', table: 'doc_msg_main', alias: 'msg_doc' },  
          { id:19, name: 'msg_main.posted_date', table: 'doc_msg_main.posted_date', alias: 'diferencia_en_dias (docente-alumno)' },  
          { id:20, name: 'subject', table: 'course_msg', alias: 'asunto' },
        ],
        where: [
          { name: 'user_id', var: 'docente' },
          { name: 'course_id', var: 'curso' },
          { name: 'Palabra Reservada', var: 'palabra_reservada' },
          { name: 'Fecha Inicio Evaluacion', var: 'evaluacion_fecha_inicio' },
          { name: 'Fecha Fin Evaluacion', var: 'evaluacion_fecha_fin' },
        ],
        query: (
          docente = '{docente}',
          curso = '{curso}',
          palabra_reservada = '{palabra_reservada}',
          evaluacion_fecha_inicio = '{evaluacion_fecha_inicio}',
          evaluacion_fecha_fin = '{evaluacion_fecha_fin}'
        ) => `
          SELECT f_main.pk1 foro_pk1,f_main.name nombre_foro, msg_main.subject subject,
            msg_main.thread_pk1 hilo, msg_main.pk1 id_msg_alumno , msg_main.posted_date fecha_mensaje_alumno,
            users.user_id id_alumno, users.firstname nombre_alumno, users.lastname apellido_alumno, 
            msg_main.msg_text msg_alumno, doc_msg_main.pk1 id_msg_docente, msg_main.posted_date fecha_mensaje_docente, 
            doc.user_id AS id_docente, doc.firstname nombre_docente, doc.lastname apellido_docente, 
            doc_msg_main.msg_text msg_docente, doc_msg_main.posted_date-msg_main.posted_date dif_fechas
          FROM conference_main conf_main 
          JOIN course_main crs_main ON (conf_main.crsmain_pk1=crs_main.pk1)
          JOIN forum_main f_main ON (conf_main.pk1=f_main.confmain_pk1)
          JOIN msg_main msg_main  
            ON ( msg_main.forummain_pk1=f_main.pk1 
                AND msg_main.users_pk1!=(SELECT pk1 FROM users u WHERE u.user_id='${docente}') )
          JOIN users users ON (msg_main.users_pk1=users.pk1)
          LEFT JOIN msg_main doc_msg_main 
            ON ( doc_msg_main.forummain_pk1=f_main.pk1  
                AND doc_msg_main.users_pk1=(SELECT pk1 FROM users u WHERE u.user_id='${docente})') 
                AND doc_msg_main.thread_pk1=msg_main.thread_pk1 
                AND doc_msg_main.msgmain_pk1=msg_main.pk1 )
          LEFT JOIN users doc ON (doc_msg_main.users_pk1=doc.pk1)
          WHERE course_id='${curso})' 
            AND lower(f_main.name) like lower('%(${palabra_reservada})%') 
            AND CAST(msg_main.posted_date AS timestamp) BETWEEN CAST('${evaluacion_fecha_inicio}' AS timestamp) 
            AND CAST('${evaluacion_fecha_fin}' AS timestamp);


          SELECT course_msg.pk1 pk1,to_char(sent_date,'yyyy-mm-dd HH24:MI')  
            fecha_enviado,u.user_id sender, course_msg.subject asunto, 
            CAST(course_msg.receivers AS varchar(100000)) recibidos  
          FROM course_msg_participant crs_msg_part 
          JOIN course_msg course_msg 
            ON (crs_msg_part.course_msg_pk1=course_msg.pk1 
                AND crs_msg_part.users_pk1=( SELECT pk1 FROM users WHERE user_id='${docente}')) 
          JOIN course_main crs_main ON (course_msg.crsmain_pk1=crs_main.pk1)
          JOIN users u ON (u.pk1= course_msg.sender_users_pk1)
          WHERE course_id='${curso}' 
            AND CAST(sent_date AS timestamp) BETWEEN CAST('${evaluacion_fecha_inicio}' AS timestamp) 
            AND CAST('${evaluacion_fecha_fin}' AS timestamp)
            AND u.user_id!='${docente})'
          UNION
          SELECT  course_msg.pk1 pk1,to_char(sent_date,'yyyy-mm-dd HH24:MI')  
            fecha_enviado,u.user_id sender, course_msg.subject asunto, 
            CAST(course_msg.receivers AS varchar(100000)) recibidos 
          FROM course_msg_participant crs_msg_part 
          JOIN course_msg course_msg ON (crs_msg_part.course_msg_pk1=course_msg.pk1 )
          JOIN course_main crs_main ON (course_msg.crsmain_pk1=crs_main.pk1)
          JOIN users u ON (u.pk1= course_msg.sender_users_pk1)
          WHERE course_id='${curso}' 
            AND CAST(sent_date AS timestamp) BETWEEN CAST('${evaluacion_fecha_inicio}' AS timestamp) 
            AND CAST('${evaluacion_fecha_fin}' AS timestamp)
            AND u.user_id='${docente})'
          ORDER BY fecha_enviado ASC
        `,
        observation: `<ul>
          <li> Busca en la tabla CRITERIA cuál es la Palabra Reservada. En este caso es "duda" </li>
          <li> "dif_fechas" es la diferencia entre el msg_main.posted_date del alumno y el docente</li>
          <li> Si el mensaje no tiene valor en "dif_fechas" se marca como no respondido </li>
          <li> Si el mensaje tiene valor en "dif_fechas" entonces se valida que la diferencia sea menor a 2 </li>
          <li> Evalua tanto los mensajes de Foro (1ra Query) como los de Curso (2da Query) </li>
          <li> Calcula el total de Mensajes menos los mensajes sin responder para obtener los Respondidos </li>
          <li> El Criterio es válido si fueron respondidos mas del 70% de los mensajes  </li>
        </ul>`
      }
    ],
    observation: `Funciona del mismo modo para ambas versiones.  La diferencia es que en algunas funciones del código para la diferencia de las 48h se usan dias, horas o segundos`
  },
  { key: '009',
    name: 'Publicó el anuncio semanal a más tardar el día lunes',
    description: 'Este Criterio se basa en "Búsqueda de Anuncio" con el valor de: <b> %anuncio%semana% </b>',
    isAnnouncement: true,
    isArchive: false,
    observation: ``
  },
  { key: '010',
    name: 'Publico el anuncio del podcast semanal',
    description: 'Este Criterio se basa en "Búsqueda de Archivo" con el valor de: <b> %podcast%semana% </b>',
    isAnnouncement: false,
    isArchive: true,
    observation: ``
  },
  { key: '011',
    name: 'Publicó las indicaciones para trabajar el Foro de Discusión o Actividad a más tardar el día lunes',
    description: 'Este Criterio se basa en "Búsqueda de Anuncio" con el valor de: <p><b> %indicaciones%foro%discusi%||%indicaciones%entregable% </b></p>',
    isAnnouncement: true,
    isArchive: false,
    observation: ``
  },
  { key: '012',
    name: 'Publicó las indicaciones para invitar a sus alumnos a visualizar el vídeo resumen de la materia a más tardar el día lunes.',
    description: 'Este Criterio se basa en "Búsqueda de Anuncio" con el valor de: <b> %graba%sesi%sincro% </b>',
    isAnnouncement: true,
    isArchive: false,
    observation: ``
  },
  { key: '013',
    name: 'Publicó las indicaciones para realizar el test automatizado a más tardar el día lunes',
    description: 'Este Criterio se basa en "Búsqueda de Anuncio" con el valor de: <b> %indicaciones%test%automatizado% </b>',
    isAnnouncement: true,
    isArchive: false,
    observation: ``
  },
  { key: '014',
    name: 'Realizó actividad de seguimiento desde el centro de retención (en martes, miércoles o jueves antes de las 23:59 horas)',
    description: '',
    versions: [
      {
        name: 'ultra',
        fields: [
          { id:1, name: 'notification_date', table: 'ews_notif', alias: 'fecha mensaje' },
          { id:2, name: 'notification_subject', table: 'ews_notif', alias: 'asunto' },
          { id:3, name: 'users_pk1', table: 'course_users', alias: 'alumno_pk1' },
          { id:4, name: 'firstname, lastname', table: 'users', alias: 'nombre (firtsname+lastname)' },
        ],
        where: [{ name: '', var: '' }],
        query: ({
          curso = '{curso}',
          evaluacion_fecha_inicio = '{evaluacion_fecha_inicio}',
          evaluacion_fecha_fin = '{evaluacion_fecha_fin}'
        }) => `
        SELECT to_char(notification_date,'YYYY-mm-dd HH24:MM'), ews_notif.notification_subject, cu.users_pk1, 
          CONCAT(CONCAT(u.firstname,' '),u.lastname) AS Nombre
        FROM ews_notif_recipient ews_recip 
        JOIN ews_notification ews_notif ON (ews_notif.pk1=ews_recip.ews_notification_pk1)
        JOIN course_users cu ON (cu.pk1=ews_recip.course_users_pk1) 
        JOIN course_main cm ON (cm.pk1=cu.crsmain_pk1)
        JOIN users u ON (u.pk1=cu.users_pk1)
        WHERE cm.course_id='${curso}' 
          AND CAST(notification_date as timestamp) BETWEEN CAST('${evaluacion_fecha_inicio}' AS timestamp) 
          AND CAST('${evaluacion_fecha_fin}' AS timestamp)
        `,
        observation: `Es Válido si encuentra mensajes enviados por el Docente`
      },
      {
        name: 'tradicional',
        fields: [
          { id:1, name: 'notification_date', table: 'ews_notif', alias: 'fecha mensaje' },
          { id:2, name: 'notification_subject', table: 'ews_notif', alias: 'asunto' },
          { id:3, name: 'users_pk1', table: 'course_users', alias: 'alumno_pk1' },
          { id:4, name: 'firstname, lastname', table: 'users', alias: 'nombre (firtsname+lastname)' },
        ],
        where: [{ name: '', var: '' }],
        query: (
          curso = '{curso}',
          evaluacion_fecha_inicio = '{evaluacion_fecha_inicio}',
          evaluacion_fecha_fin = '{evaluacion_fecha_fin}'
        ) => `
        SELECT TO_CHAR(notification_date,'YYYY-mm-dd HH24:MM') , ews_notif.notification_subject, cu.users_pk1, 
          CONCAT(CONCAT(u.firstname,' '),u.lastname) AS Nombre
        FROM ews_notif_recipient ews_recip 
        JOIN ews_notification ews_notif ON (ews_notif.pk1=ews_recip.ews_notification_pk1)
        JOIN course_users cu ON (cu.pk1=ews_recip.course_users_pk1) 
        JOIN course_main cm ON (cm.pk1=cu.crsmain_pk1)
        JOIN users u ON (u.pk1=cu.users_pk1)
        WHERE cm.course_id='${curso}' 
          AND CAST(notification_date AS timestamp) BETWEEN CAST('${evaluacion_fecha_inicio}' AS timestamp) 
          AND CAST('${evaluacion_fecha_fin}' as timestamp)
        `,
        observation: `Es Válido si encuentra mensajes enviados por el Docente`
      }
    ],
    observation: `Funciona del mismo modo para ambas versiones.`
  },
  { key: '015',
    name: 'Calificó las actividades ordinarias y extraordinarias en máximo 72 hrs',
    description: '',
    versions: [
      {
        name: 'ultra',
        fields: [
          { id:1, name: 'attempt_date', table: 'attempt', alias: 'fecha intento' },
          { id:2, name: 'last_graded_date', table: 'attempt', alias: 'fecha ultima calificacion' },
          { id:3, name: 'status', table: 'attempt', alias: 'status attempt' },
          { id:4, name: 'última calificacion - intento', table: 'attempt', alias: 'diferencia entre fechas' },
          { id:5, name: 'pk1', table: 'attempt', alias: 'id intento' },
          { id:6, name: 'user_id', table: 'users', alias: 'id alumno' },
          { id:7, name: 'firstname', table: 'users', alias: 'nombre_alumnos' },
          { id:8, name: 'lastname', table: 'users', alias: 'apellido alumno' },
          { id:9, name: 'title', table: 'gradebook_main', alias: 'titulo actividad' },
          { id:10, name: 'manual_grade', table: 'gradebook_grade', alias: 'calificacion' },
          { id:11, name: 'due_date', table: 'gradebook_main', alias: 'fecha_vencimiento' },
          { id:12, name: 'start_date', table: 'titulo_actividad (subQ)', alias: 'fecha_inicio_actividad' },
          { id:13, name: 'end_date', table: 'titulo_actividad (subQ)', alias: 'fecha_fin_actividad' },
          { id:14, name: 'user_id', table: 'titulo_actividad (subQ)', alias: 'id Docente' },
          { id:15, name: 'firstname', table: 'titulo_actividad (subQ)', alias: 'nombre Docente' },
          { id:16, name: 'lastname', table: 'titulo_actividad (subQ)', alias: 'apellido Docente' },
        ],
        where: [{ name: '', var: '' }],
        query: (
          curso = '{curso}',
          docente = '{docente}',
          evaluacion_fecha_inicio = '{evaluacion_fecha_inicio}',
          evaluacion_fecha_fin = '{evaluacion_fecha_fin}'
        ) => `
          SELECT 
            to_date(to_char(attempt.attempt_date,'dd-mm-yy HH24:MI'),'dd-mm-yy HH24:MI'),
            to_date(to_char(attempt.last_graded_date,'dd-mm-yy HH24:MI'),'dd-mm-yy HH24:MI'), 
            attempt.status,
            extract(epoch FROM (CAST(attempt.last_graded_date as timestamp) - CAST(attempt.attempt_date as timestamp)))/3600, -- evaluacion_fecha_fin
            attempt.pk1,
            alum.user_id,
            alum.firstname,
            alum.lastname,
            gdb_m.title,
            gdb_g.manual_grade,
            to_char(gdb_m.due_date,'yyyy-mm-dd HH24:MI'),
            to_date(to_char(titulo_actividad.start_date,'dd-mm-yy HH24:MI'),'dd-mm-yy HH24:MI'),
            to_date(to_char(titulo_actividad.end_date,'dd-mm-yy HH24:MI'),'dd-mm-yy HH24:MI'),
            titulo_actividad.user_id,
            titulo_actividad.firstname,
            titulo_actividad.lastname
          FROM gradebook_grade gdb_g 
          JOIN gradebook_main gdb_m ON (gdb_m.pk1=gdb_g.gradebook_main_pk1) 
          JOIN course_main crs_m ON (gdb_m.crsmain_pk1=crs_m.pk1 AND crs_m.course_id='{curso}') -- curso
          JOIN ATTEMPT attempt ON (gdb_g.last_attempt_pk1  = attempt.pk1 AND gdb_g.pk1 = attempt.gradebook_grade_pk1 AND attempt.status!=3)
          JOIN course_users cu ON gdb_g.course_users_pk1 = cu.pk1
          JOIN users alum ON cu.users_pk1 = alum.pk1
          LEFT JOIN (
            SELECT cc.title, cc.start_date, cc.end_date, 'foro' tipo,user_id,firstname,lastname, gm.pk1 gm_pk1
            FROM course_main crs_main
            LEFT JOIN course_assessment crs_ass ON (crs_main.pk1=crs_ass.crsmain_pk1)  
            JOIN course_users cu ON (crs_main.pk1=cu.crsmain_pk1)
            JOIN course_contents cc ON (cc.crsmain_pk1=crs_main.pk1) 
            JOIN users u ON (u.pk1=cu.users_pk1 AND USER_ID = '${docente}') -- docente
            LEFT JOIN gradebook_main gm ON gm.course_contents_pk1 = crs_main.pk1
            WHERE   -- cc.cnthndlr_handle='resource/x-bb-forumlink' AND 
                crs_main.course_id = '{curso}' -- curso 
                AND cc.title !='Foro de Dudas' 
                AND cc.title NOT LIKE '%Diagnóstico%' 
                AND cc.title !='Foro de presentación' 
                AND cc.title != 'Foro de discusión' 
                AND cc.title !='Foro para equipos' 
                AND cc.start_date is NOT null 
                AND cu.row_status= 0 AND cu.available_IND = 'Y'
          ) titulo_actividad  ON titulo_actividad.gm_pk1 = gdb_m.pk1
          WHERE crs_m.course_id = '{curso}' -- curso
            AND gdb_m.title NOT LIKE '%automatizada%' 
            AND gdb_m.title NOT LIKE '%Automatizada%'  
            AND gdb_m.title NOT LIKE '%EPE%' 
            AND gdb_m.title != 'Diario de reflexiones'
            --AND (attempt.status!=7 AND gdb_g.manual_grade is null)
            AND gdb_m.title NOT IN (
                SELECT cc.title FROM course_contents cc 
                JOIN course_main cm ON (cm.pk1=cc.crsmain_pk1 AND cm.course_id='${curso}') -- curso  
                WHERE cc.cnthndlr_handle IN ('resource/x-plugin-scormengine','resource/x-bb-forumlink') 
            )
            AND (
                CAST(attempt_date as timestamp) BETWEEN 
                CAST('${evaluacion_fecha_inicio}' as timestamp) AND -- evaluacion_fecha_inicio 
                CAST('${evaluacion_fecha_fin}' as timestamp) -- evaluacion_fecha_fin 
            )
          ORDER BY attempt.attempt_date
        `,
        observation: `<ul>
          <li> Si no tiene calificacion manual ni fecha de evaluacion es Inválido </li>
          <li> Si tiene calificacion manual es Válido </li>
          <li> Si la fecha de Evaluacion es mayor a 72h es Inválido </li>
        </ul>`
      },
      {
        name: 'tradicional',
        fields: [
          { id:1, name: 'attempt_date', table: 'attempt', alias: 'fecha intento' },
          { id:2, name: 'last_graded_date', table: 'attempt', alias: 'fecha ultima calificacion' },
          { id:3, name: 'status', table: 'attempt', alias: 'status attempt' },
          { id:4, name: 'última calificacion - intento', table: 'attempt', alias: 'diferencia entre fechas' },
          { id:5, name: 'pk1', table: 'attempt', alias: 'id intento' },
          { id:6, name: 'user_id', table: 'users', alias: 'id alumno' },
          { id:7, name: 'firstname', table: 'users', alias: 'nombre_alumnos' },
          { id:8, name: 'lastname', table: 'users', alias: 'apellido alumno' },
          { id:9, name: 'title', table: 'gradebook_main', alias: 'titulo actividad' },
          { id:10, name: 'manual_grade', table: 'gradebook_grade', alias: 'calificacion' },
          { id:11, name: 'due_date', table: 'gradebook_main', alias: 'fecha_vencimiento' },
          { id:12, name: 'start_date', table: 'titulo_actividad (subQ)', alias: 'fecha_inicio_actividad' },
          { id:13, name: 'end_date', table: 'titulo_actividad (subQ)', alias: 'fecha_fin_actividad' }
        ],
        where: [{ name: 'USER_ID', var: 'docente' }],
        query: (
          curso = '{curso}',
          docente = '{docente}',
          evaluacion_fecha_fin = '{evaluacion_fecha_fin}',
          evaluacion_fecha_inicio = '{evaluacion_fecha_inicio}'
        ) => `
          SELECT 
            TO_DATE(TO_CHAR(attempt.attempt_date,'dd-mm-yy HH24:MI'),'dd-mm-yy HH24:MI'),
            TO_DATE(TO_CHAR(attempt.last_graded_date,'dd-mm-yy HH24:MI'),'dd-mm-yy HH24:MI') , 
            attempt.status, 
            extract(epoch FROM (CAST(attempt.last_graded_date AS timestamp) - CAST('${evaluacion_fecha_fin}' AS timestamp)))/3600,
            attempt.pk1,
            user_id,
            firstname,
            lastname,
            gdb_m.title,
            gdb_g.manual_grade,
            TO_CHAR(gdb_m.due_date,'yyyy-mm-dd HH24:MI'),
            TO_DATE(TO_CHAR(titulo_actividad.start_date,'dd-mm-yy HH24:MI'),'dd-mm-yy HH24:MI'),
            TO_DATE(TO_CHAR(titulo_actividad.end_date,'dd-mm-yy HH24:MI'),'dd-mm-yy HH24:MI')
          FROM gradebook_grade gdb_g 
          JOIN gradebook_main gdb_m ON (gdb_m.pk1=gdb_g.gradebook_main_pk1) 
          JOIN course_main crs_m ON (gdb_m.crsmain_pk1=crs_m.pk1 AND crs_m.course_id='${curso}') 
          JOIN ATTEMPT attempt ON (gdb_g.last_attempt_pk1  = attempt.pk1 AND gdb_g.pk1 = attempt.gradebook_grade_pk1 AND attempt.status!=3)
          LEFT JOIN (
            SELECT cc.title, cc.start_date, cc.end_date, 'foro' tipo,user_id,firstname,lastname
            FROM course_main crs_main
            LEFT JOIN course_assessment crs_ass ON (crs_main.pk1=crs_ass.crsmain_pk1)  
            JOIN course_users cu ON (crs_main.pk1=cu.crsmain_pk1)
            JOIN course_contents cc ON (cc.crsmain_pk1=crs_main.pk1) 
            JOIN users u ON (u.pk1=cu.users_pk1 AND USER_ID='${docente}')
            LEFT JOIN gradebook_main gm ON gm.course_contents_pk1 = crs_main.pk1
            WHERE --cc.cnthndlr_handle='resource/x-bb-forumlink' AND 
                crs_main.course_id='${curso}' 
                AND cc.title !='Foro de Dudas' 
                AND cc.title NOT LIKE '%Diagnóstico%' 
                AND cc.title !='Foro de presentación' 
                AND cc.title != 'Foro de discusión' 
                AND cc.title !='Foro para equipos' 
                AND cc.start_date is not null 
                AND cu.row_status= 0 
                AND cu.available_IND ='Y'
          ) titulo_actividad  ON titulo_actividad.title = gdb_m.title
          WHERE crs_m.course_id='${curso}'  
            AND gdb_m.title NOT LIKE '%automatizada%'   
            AND gdb_m.title NOT LIKE '%Automatizada%'
            AND gdb_m.title NOT LIKE '%EPE%' 
            AND gdb_m.title!='Diario de reflexiones'
            --AND (attempt.status!=7 AND gdb_g.manual_grade is null)
            AND gdb_m.title NOT IN (
              SELECT cc.title FROM course_contents cc 
              JOIN course_main cm ON (cm.pk1=cc.crsmain_pk1 AND cm.course_id='${curso}') 
              WHERE cc.cnthndlr_handle IN ('resource/x-plugin-scormengine', 'resource/x-bb-forumlink') )
            AND (
              CAST(attempt_date AS timestamp) 
              BETWEEN CAST('${evaluacion_fecha_inicio}' AS timestamp) AND CAST('${evaluacion_fecha_fin}' AS timestamp) 
              AND (
                (CAST(gdb_m.due_date AS timestamp)
                  BETWEEN CAST('${evaluacion_fecha_inicio}' AS timestamp) AND CAST('${evaluacion_fecha_fin}' AS timestamp) 
                  AND  gdb_m.title not like '%foro%' )
                OR ( CAST(attempt_date AS timestamp)
                  BETWEEN CAST(titulo_actividad.start_date AS timestamp) AND CAST(titulo_actividad.end_date AS timestamp))
              )
            )
          ORDER BY attempt.attempt_date
        `,
        observation: `<ul>
          <li> Si la fecha de vencimiento está fuera de la semana que se está evaluando se descarta ese intento</li>
        </ul>`
      }
    ],
    observation: `<ul>
      <li> La version ultra no verifica si la fecha de vencimiento se encuentra dentro de la semana evaluada, cosa que si se verifica en la version tradicional </li>
      <li> Si tiene Calificacion manual (calificación) o fecha Última Calificación entonces el intento es Válido </li>
    </ul>`
  }, 
  { key: '016',
    name: 'Asignó los 0, en las actividades que no entregaron los estudiantes.',
    description: '',
    versions: [
      {
        name: 'ultra',
        fields: [
          { id:1, name: 'user_id', table: 'users', alias: 'cuenta' },
          { id:2, name: 'firstname', table: 'users', alias: 'Nombres' },
          { id:3, name: 'lastname', table: 'users', alias: 'Apellidos' },
          { id:4, name: 'course_id', table: 'course_main', alias: 'grupo' },
          { id:5, name: 'last_login_date', table: 'users', alias: 'ultimo_acceso' },
          { id:6, name: 'course_name', table: 'course_main', alias: 'nombre_materia' },
          { id:7, name: 'status', table: 'attempt', alias: 'Calificacion' },
          { id:8, name: 'title', table: 'gradebook_main', alias: 'last_attempt_pk1' },
          { id:9, name: 'attempt_date', table: 'attempt', alias: 'Fecha_Entrega' },
          { id:10, name: 'available_ind', table: 'users', alias: 'usuario_disponible' },
          { id:11, name: 'course_id', table: 'course_main', alias: 'Nivel' },
          { id:12, name: 'course_id', table: 'course_main', alias: 'Actividad' },
          { id:13, name: 'enrollment_date', table: 'course_users', alias: 'fecha_inscripcion' },
          { id:14, name: 'available_ind', table: 'course_main', alias: 'curso_disponible' },
          { id:15, name: 'dtcreated', table: 'course_main', alias: 'fecha_creacion' },
        ],
        where: [{ name: '', var: '' }],
        query: (
          docente = '{docente}', 
          curso = '{curso}', 
          nivel = '{nivel}', 
          year = '{year}', 
          ciclo = '{ciclo}', 
          evaluacion_fecha_fin = '{evaluacion_fecha_fin}', 
          evaluacion_fecha_inicio = '{evaluacion_fecha_inicio}', 
        ) => `
          SELECT u.user_id as cuenta,  u.firstname as Nombres, u.lastname as Apellidos, cm.course_id as grupo,
            u.last_login_date ultimo_acceso, cm.course_name as nombre_materia,
            CASE WHEN at.status!=7 OR at.status IS NULL THEN 'Necesita CalificaciÃ³n' 
              ELSE CAST(ROUND(at.grade::numeric,2) AS text) END AS Calificacion, 
            gg.last_attempt_pk1, at.attempt_date AS Fecha_Entrega, u.available_ind AS usuario_disponible,
            (CASE WHEN LENGTH(cm.course_id)=22 THEN SUBSTR(cm.course_id,14,2)  
              WHEN LENGTH(cm.course_id)=19 THEN SUBSTR(cm.course_id,11,2)  
              WHEN LENGTH(cm.course_id)=21 THEN SUBSTR(cm.course_id,13,2)
              WHEN LENGTH(cm.course_id)=18 THEN SUBSTR(cm.course_id,10,2) ELSE '--' END) AS Nivel,  
            gm.title AS Actividad, cu.enrollment_date AS fecha_inscripcion, gm.course_contents_pk1 AS actividad_pk1,
            (SELECT available_ind FROM course_contents where pk1=course_contents_pk1 ), 
            cm.available_ind as curso_disponible, cm.dtcreated as fecha_creacion, u.email, cc.end_date
          FROM users u, course_users cu, course_main cm, gradebook_main gm, 
          gradebook_grade gg LEFT JOIN attempt AT ON (gg.pk1=at.gradebook_grade_pk1 AND gg.last_attempt_pk1=at.pk1),
          course_contents cc
          WHERE u.pk1=cu.users_pk1
            AND cu.crsmain_pk1=cm.pk1
            AND cm.pk1=gm.crsmain_pk1
            AND gg.course_users_pk1=cu.pk1
            AND cu.role='S'
            AND gm.pk1=gg.gradebook_main_pk1
            AND gm.course_contents_pk1=cc.pk1
            AND u.user_id!='${docente})'
            AND cm.course_id='${curso})'
            AND cu.row_status= 0 
            AND cu.available_IND ='Y'
            AND (
              CASE WHEN LENGTH(cm.course_id)=22 THEN SUBSTR(cm.course_id,14,2)
              WHEN LENGTH(cm.course_id)=19 THEN SUBSTR(cm.course_id,11,2)
              WHEN LENGTH(cm.course_id)=21 THEN SUBSTR(cm.course_id,13,2)
              WHEN LENGTH(cm.course_id)=18 THEN SUBSTR(cm.course_id,10,2)
              ELSE '--' END ) = '${nivel}'
            AND SUBSTR(cm.course_id,-4,2)='${year}'
            AND SUBSTR(cm.course_id,-2,2)='${ciclo}' 
            AND last_attempt_pk1 IS NULL 
            AND (gg.manual_score IS NULL)
            AND cc.end_date IS NOT NULL 
            AND cc.title !='Foro de Dudas' 
            AND cc.title NOT LIKE '%Diagnóstico%' 
            AND cc.title !='Foro de presentación' 
            AND gm.title NOT LIKE '%EPE%'
            AND gm.title!='Diario de reflexiones'
            AND cc.title !='Foro para equipos' 
            AND CAST(cc.end_date as timestamp) 
              BETWEEN CAST('${evaluacion_fecha_inicio}' AS timestamp) AND CAST('${evaluacion_fecha_fin}' AS timestamp)
          ORDER BY cm.course_id
        `,
        observation: ``
      },
      {
        name: 'tradicional',
        fields: [
          { id:1, name: 'user_id', table: 'users', alias: 'cuenta' },
          { id:2, name: 'firstname', table: 'users', alias: 'Nombres' },
          { id:3, name: 'lastname', table: 'users', alias: 'Apellidos' },
          { id:4, name: 'course_id', table: 'course_main', alias: 'grupo' },
          { id:5, name: 'last_login_date', table: 'users', alias: 'ultimo_acceso' },
          { id:6, name: 'course_name', table: 'course_main', alias: 'nombre_materia' },
          { id:7, name: 'status', table: 'attempt', alias: 'Calificacion' },
          { id:8, name: 'title', table: 'gradebook_main', alias: 'last_attempt_pk1' },
          { id:9, name: 'attempt_date', table: 'attempt', alias: 'Fecha_Entrega' },
          { id:10, name: 'available_ind', table: 'users', alias: 'usuario_disponible' },
          { id:11, name: 'course_id', table: 'course_main', alias: 'Nivel' },
          { id:12, name: 'course_id', table: 'course_main', alias: 'Actividad' },
          { id:13, name: 'enrollment_date', table: 'course_users', alias: 'fecha_inscripcion' },
          { id:14, name: 'available_ind', table: 'course_main', alias: 'curso_disponible' },
          { id:15, name: 'dtcreated', table: 'course_main', alias: 'fecha_creacion' },
        ],
        where: [{ name: '', var: '' }],
        query: (
          docente = '{docente}', 
          curso = '{curso}', 
          nivel = '{nivel}', 
          year = '{year}', 
          ciclo = '{ciclo}', 
          evaluacion_fecha_fin = '{evaluacion_fecha_fin}', 
          evaluacion_fecha_inicio = '{evaluacion_fecha_inicio}', 
        ) => `
          SELECT u.user_id AS cuenta,  u.firstname AS Nombres, u.lastname AS Apellidos, cm.course_id AS grupo,
            u.last_login_date ultimo_acceso, cm.course_name AS nombre_materia,
            CASE WHEN at.status!=7 OR at.status IS NULL THEN 'Necesita Calificación' 
              ELSE CAST(ROUND(at.grade::numeric,2) AS text) END AS Calificacion, 
            gg.last_attempt_pk1, at.attempt_date AS Fecha_Entrega, u.available_ind AS usuario_disponible,
            (CASE WHEN LENGTH(cm.course_id)=22 THEN SUBSTR(cm.course_id,14,2)  
              WHEN LENGTH(cm.course_id)=19 THEN SUBSTR(cm.course_id,11,2)  
              WHEN LENGTH(cm.course_id)=21 THEN SUBSTR(cm.course_id,13,2)
              WHEN LENGTH(cm.course_id)=18 THEN SUBSTR(cm.course_id,10,2) ELSE '--' END) AS Nivel,  
            gm.title AS Actividad, cu.enrollment_date AS fecha_inscripcion, gm.course_contents_pk1 AS actividad_pk1,
            (SELECT available_ind FROM course_contents where pk1=course_contents_pk1 ), 
            cm.available_ind AS curso_disponible, cm.dtcreated AS fecha_creacion, u.email, cc.end_date
          FROM users u, course_users cu, course_main cm, gradebook_main gm,
          gradebook_grade gg LEFT JOIN attempt at ON (gg.pk1=at.gradebook_grade_pk1 AND gg.last_attempt_pk1=at.pk1),
          course_contents cc
          WHERE u.pk1=cu.users_pk1
            AND cu.crsmain_pk1=cm.pk1
            AND cm.pk1=gm.crsmain_pk1
            AND gg.course_users_pk1=cu.pk1
            AND cu.role='S'
            AND gm.pk1=gg.gradebook_main_pk1
            AND gm.course_contents_pk1=cc.pk1
            AND u.user_id!='${docente}'
            AND cm.course_id='${curso}'
            AND cu.row_status= 0 
            AND cu.available_IND ='Y'
            AND ( CASE WHEN LENGTH(cm.course_id)=22 THEN SUBSTR(cm.course_id,14,2)
              WHEN LENGTH(cm.course_id)=19 THEN SUBSTR(cm.course_id,11,2)
              WHEN LENGTH(cm.course_id)=21 THEN SUBSTR(cm.course_id,13,2)
              WHEN LENGTH(cm.course_id)=18 THEN SUBSTR(cm.course_id,10,2)
              ELSE '--' END ) = '${nivel}'
            AND SUBSTR(cm.course_id,-4,2)='${year}'
            AND SUBSTR(cm.course_id,-2,2)='${ciclo}' 
            AND last_attempt_pk1 is null 
            AND (gg.manual_score is null )
            AND cc.end_date is not null 
            AND cc.title !='Foro de Dudas' 
            AND cc.title not like '%Diagnóstico%' 
            AND cc.title !='Foro de presentación' 
            AND gm.title NOT LIKE '%EPE%' 
            AND gm.title!='Diario de reflexiones'
            AND cc.title !='Foro para equipos' 
            AND CAST(cc.end_date as timestamp) 
              BETWEEN CAST('${evaluacion_fecha_inicio}' AS timestamp) AND CAST('${evaluacion_fecha_fin}' AS timestamp)
          ORDER BY cm.course_id
        `,
        observation: `Si el Query arroja resultado entonces es INVÁLIDO`
      }
    ],
    observation: `Funciona del mismo modo para ambas versiones.`
  },
  { key: '017',
    name: 'Atendió los mensajes en máximo 24 hrs.',
    description: '',
    versions: [
      {
        name: 'ultra',
        fields: [
          { id:1, name: 'name', table: 'forum_main', alias: 'nombre_foro' },
          { id:2, name: 'thread_pk1', table: 'msg_main', alias: 'id_hilo_mensaje_alumno/docente' },
          { id:3, name: 'subject', table: '', alias: 'nombre_hilo' },
          { id:4, name: 'pk1', table: 'msg_main', alias: 'id_mensaje_alumno/docente' },
          { id:5, name: 'posted_date', table: 'msg_main', alias: 'fecha_mensaje_alumno/docente' },
          { id:6, name: 'posted_date', table: 'msg_main', alias: 'hora_mensaje_alumno/docente' },
          { id:7, name: 'firstname + lastname', table: 'users', alias: 'nombre_alumno/docente' },
          { id:8, name: 'subject', table: 'msg_main', alias: 'mensaje_alumno/docente' },
          { id:9, name: 'user_id', table: 'users', alias: 'id_alumno/docente' },
          { id:10, name: 'sent_date', table: 'course_msg_participant', alias: 'fecha_enviado|curso' },
        ],
        where: [{ name: '', var: '' }],
        query: (
          docente = '{docente}', 
          curso = '{curso}', 
          palabra_reservada = '{palabra_reservada}', 
          evaluacion_fecha_inicio = '{evaluacion_fecha_inicio}', 
          evaluacion_fecha_fin = '{evaluacion_fecha_fin}', 
          evaluacion_fecha_fin__mas_2_dias = '{evaluacion_fecha_fin__mas_2_dias}',
        ) => `
          SELECT f_main.name,
            msg_main.thread_pk1,
            hilo.subject hilo,
            min(msg_main.pk1),
            min(msg_main.posted_date) posted_date,
            to_char(min(msg_main.posted_date),'HH24:MI') hora_mensaje,
            concat(concat(firstname,' '),lastname) nombre_alumno,
            min(msg_main.subject),
            u.user_id
          FROM conference_main conf_main 
          JOIN course_main crs_main ON (conf_main.crsmain_pk1=crs_main.pk1)
          JOIN forum_main f_main ON (conf_main.pk1=f_main.confmain_pk1)
          JOIN msg_main msg_main  ON (msg_main.forummain_pk1=f_main.pk1 )
          JOIN msg_main hilo ON (hilo.pk1=msg_main.thread_pk1)
          JOIN users u ON (u.pk1=msg_main.users_pk1 AND u.user_id!='${docente}')
          WHERE course_id='${curso}' 
            AND LOWER(f_main.name) LIKE LOWER('${palabra_reservada}')
            AND CAST( msg_main.posted_date AS timestamp) 
              BETWEEN CAST('${evaluacion_fecha_inicio}' AS timestamp) AND CAST('${evaluacion_fecha_fin}' AS timestamp)
          GROUP BY msg_main.thread_pk1, u.user_id, hilo.subject, CONCAT(CONCAT(firstname,' '),lastname), f_main.name
          UNION
          SELECT f_main.name,
            msg_main.thread_pk1,
            hilo.subject hilo,
            msg_main.pk1,
            msg_main.posted_date posted_date,
            to_char(msg_main.posted_date,'HH24:MI') hora_mensaje,
            concat(concat(firstname,' '),lastname) nombre_alumno,
            msg_main.subject,
            u.user_id
          FROM conference_main conf_main 
          JOIN course_main crs_main ON (conf_main.crsmain_pk1=crs_main.pk1)
          JOIN forum_main f_main ON (conf_main.pk1=f_main.confmain_pk1)
          JOIN msg_main msg_main  ON (msg_main.forummain_pk1=f_main.pk1 )
          JOIN msg_main hilo ON (hilo.pk1=msg_main.thread_pk1)
          JOIN users u ON (u.pk1=msg_main.users_pk1 AND u.user_id='${docente}' )
          WHERE course_id='${curso}' AND LOWER(f_main.name) LIKE LOWER('${palabra_reservada}')
          ORDER BY posted_date,hora_mensaje;

          SELECT  
            course_msg.pk1 pk1,
            to_char(sent_date,'yyyy-mm-dd hh24:mi')  fecha_enviado,
            u.user_id sender,
            course_msg.subject asunto, 
            concat(concat(firstname,' '),lastname) nombre_alumno,
            course_msg.receivers,
            (SELECT pk1 FROM users WHERE user_id='${docente}') pk1_docente
          FROM course_msg_participant crs_msg_part 
          JOIN course_msg course_msg 
            ON (crs_msg_part.course_msg_pk1=course_msg.pk1 AND crs_msg_part.users_pk1=(SELECT pk1 FROM users WHERE user_id='${docente}')) 
          JOIN course_main crs_main ON (course_msg.crsmain_pk1=crs_main.pk1)
          JOIN users u ON (u.pk1= course_msg.sender_users_pk1)
          WHERE course_id='${curso}' 
            AND CAST(sent_date as timestamp) 
              BETWEEN CAST('${evaluacion_fecha_inicio}' AS timestamp) AND CAST('${evaluacion_fecha_fin__mas_2_dias}' AS timestamp)
            AND course_msg.subject NOT LIKE '%Re:Re:%' 
            AND course_msg.subject NOT LIKE '%Re: Re:%'
          ORDER BY fecha_enviado ASC
        `,
        observation: `<ul>
          <li> Cuenta la cantidad de mensajes que se respondieron luego de 48h pero no lo toma en cuenta para la Validez del criterio </li>
          <li> Evalua tanto los mensajes de Foro (1ra Query) como los de Curso (2da Query) </li>
          <li> Calcula el total de Mensajes menos los mensajes sin responder para obtener los Respondidos </li>
          <li> El Criterio es válido si fueron respondidos mas del 70% de los mensajes  </li>
        </ul>`
      },
      {
        name: 'tradicional',
        fields: [
          { id:1, name: 'name', table: 'forum_main', alias: 'nombre_foro' },
          { id:2, name: 'thread_pk1', table: 'msg_main', alias: 'id_hilo_mensaje_alumno/docente' },
          { id:3, name: 'subject', table: '', alias: 'nombre_hilo' },
          { id:4, name: 'pk1', table: 'msg_main', alias: 'id_mensaje_alumno/docente' },
          { id:5, name: 'posted_date', table: 'msg_main', alias: 'fecha_mensaje_alumno/docente' },
          { id:6, name: 'posted_date', table: 'msg_main', alias: 'hora_mensaje_alumno/docente' },
          { id:7, name: 'firstname + lastname', table: 'users', alias: 'nombre_alumno/docente' },
          { id:8, name: 'subject', table: 'msg_main', alias: 'mensaje_alumno/docente' },
          { id:9, name: 'user_id', table: 'users', alias: 'id_alumno/docente' },
          { id:10, name: 'sent_date', table: 'course_msg_participant', alias: 'fecha_enviado|curso' },
        ],
        where: [{ name: '', var: '' }],
        query: (
          docente = '{docente}', 
          curso = '{curso}', 
          palabra_reservada = '{palabra_reservada}', 
          evaluacion_fecha_inicio = '{evaluacion_fecha_inicio}', 
          evaluacion_fecha_fin = '{evaluacion_fecha_fin}', 
          evaluacion_fecha_fin__mas_2_dias = '{evaluacion_fecha_fin__mas_2_dias}',
        ) => `
          SELECT f_main.pk1 foro_pk1, 
            f_main.name nombre_foro, 
            msg_main.subject subject,
            msg_main.thread_pk1 hilo, 
            msg_main.pk1 id_msg_alumno, 
            msg_main.posted_date fecha_mensaje_alumno, 
            users.user_id id_alumno, 
            users.firstname nombre_alumno, 
            users.lastname apellido_alumno, 
            msg_main.msg_text msg_alumno, 
            doc_msg_main.pk1 id_msg_docente, 
            msg_main.posted_date fecha_mensaje_docente, 
            doc.user_id as id_docente, 
            doc.firstname nombre_docente, 
            doc.lastname apellido_docente, 
            doc_msg_main.msg_text msg_docente, 
            doc_msg_main.posted_date - msg_main.posted_date dif_fechas
          FROM conference_main conf_main 
          JOIN course_main crs_main ON (conf_main.crsmain_pk1=crs_main.pk1)
          JOIN forum_main f_main ON (conf_main.pk1=f_main.confmain_pk1)
          JOIN msg_main msg_main  
            ON (msg_main.forummain_pk1=f_main.pk1 AND msg_main.users_pk1!=(SELECT pk1 FROM users u WHERE u.user_id='${docente}') )
          JOIN users users ON (msg_main.users_pk1=users.pk1)
          LEFT JOIN msg_main doc_msg_main 
            ON (doc_msg_main.forummain_pk1=f_main.pk1  AND doc_msg_main.users_pk1=(SELECT pk1 FROM users u WHERE u.user_id='${docente}') 
          AND doc_msg_main.thread_pk1=msg_main.thread_pk1 AND doc_msg_main.msgmain_pk1=msg_main.pk1 )
          LEFT JOIN users doc ON (doc_msg_main.users_pk1=doc.pk1)
          WHERE course_id='${curso}'
            AND LOWER(f_main.name) LIKE LOWER('%${palabra_reservada}%') 
            AND CAST(msg_main.posted_date as timestamp) 
              BETWEEN CAST('${evaluacion_fecha_inicio}' AS timestamp) AND CAST('${evaluacion_fecha_fin}' AS timestamp);


          SELECT  course_msg.pk1 pk1,to_char(sent_date,'yyyy-mm-dd HH24:MI') fecha_enviado, u.user_id sender, course_msg.subject asunto, 
            CAST(course_msg.receivers as varchar(100000)) recibidos
          FROM course_msg_participant crs_msg_part
          JOIN course_msg course_msg 
            ON (crs_msg_part.course_msg_pk1=course_msg.pk1 AND crs_msg_part.users_pk1=( SELECT pk1 FROM users WHERE user_id='${docente}')) 
          JOIN course_main crs_main ON (course_msg.crsmain_pk1=crs_main.pk1)
          JOIN users u ON (u.pk1= course_msg.sender_users_pk1)
          WHERE course_id='${curso}' 
            AND CAST(sent_date as timestamp) BETWEEN CAST('${evaluacion_fecha_inicio}' AS timestamp) AND CAST('${evaluacion_fecha_fin}' as timestamp)
            AND u.user_id!='${docente}'
          UNION
          SELECT  course_msg.pk1 pk1,to_char(sent_date,'yyyy-mm-dd HH24:MI') fecha_enviado, u.user_id sender, course_msg.subject asunto, 
            CAST(course_msg.receivers as varchar(100000)) recibidos 
          FROM course_msg_participant crs_msg_part 
          JOIN course_msg course_msg ON (crs_msg_part.course_msg_pk1=course_msg.pk1)
          JOIN course_main crs_main ON (course_msg.crsmain_pk1=crs_main.pk1)
          JOIN users u ON (u.pk1= course_msg.sender_users_pk1)
          WHERE course_id='${curso}' 
            AND CAST(sent_date as timestamp) BETWEEN CAST('${evaluacion_fecha_inicio}' AS timestamp) AND CAST('${evaluacion_fecha_fin}' AS timestamp)
            AND u.user_id='${docente}'
          ORDER BY fecha_enviado ASC 
        `,
        observation: `<ul>
          <li> Cuenta la cantidad de mensajes que se respondieron luego de 48h pero no lo toma en cuenta para la Validez del criterio </li>
          <li> Evalua tanto los mensajes de Foro (1ra Query) como los de Curso (2da Query) </li>
          <li> Calcula el total de Mensajes menos los mensajes sin responder para obtener los Respondidos </li>
          <li> El Criterio es válido si fueron respondidos mas del 70% de los mensajes  </li>
        </ul>`
      }
    ],
    observation: `<ul>
      <li> El Query de los Mensajes en Foro en Ultra se realiza utilizando UNION pero en tradicional con subquery </li>
      <li> El Query de los Mensajes en Curso en Tradicional se realiza utilizando UNION pero en Ultra con subquery </li>
    </ul>`
  },
  { key: '018',
    name: 'Publicó anuncio invitando a sus estudiantes a la realizar la evaluación docente.',
    description: 'Este Criterio se basa en "Búsqueda de Anuncio" con el valor de: <b> %evaluaci%docente% </b>',
    isAnnouncement: true,
    isArchive: false,
    observation: ``
  },
  { key: '019',
    name: 'Publicó el anuncio de cierre',
    description: 'Este Criterio se basa en "Búsqueda de Anuncio" con el valor de: <b> %cierre%materia% </b>',
    isAnnouncement: true,
    isArchive: false,
    observation: ``
  },
  

]

export {
  general,
  criterios
}
