import React from "react";

const CumplimientoIcon = (props) => (
  <svg viewBox='0 0 46.58 34.68' {...props}>
    <g data-name='Layer 2'>
      <path
        d='M45.85 17.17c.41.41.74.27.74-.3V2a2.13 2.13 0 00-2.08-2H29.72c-.57 0-.71.33-.3.74L34.68 6l-2.15 2.13-9.95 10-5.26-5.26a1 1 0 00-1.47 0L1.22 27.47a4.16 4.16 0 000 5.89l.1.1a4.16 4.16 0 005.89 0l9.38-9.38 5.26 5.26a1 1 0 001.47 0L40.66 12z'
        fill='#27215f'
        data-name='Capa 1'
      />
    </g>
  </svg>
);

export default CumplimientoIcon;
